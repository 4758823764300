import axios from "axios";
import Borc from "../entities/Borc";
import { TAHSILAT_HAZIRLA_URL } from "./constants";

class TahsilatService {
  askidaTahsilatHazirla(
    id: string,
    genelToplam: number,
    askidaYardimMi: boolean,
    fatura?: Borc
  ) {
    const body = askidaYardimMi
      ? { genelToplam, askidaYardimDetay: { id, tutar: genelToplam } }
      : { genelToplam, askidaFatura: true, selectedBorcs: [fatura] };
    return axios
      .post(`${TAHSILAT_HAZIRLA_URL}/${askidaYardimMi}`, body)
      .then((res) => res.data);
  }
}

const tahsilatService = new TahsilatService();
export default tahsilatService;
