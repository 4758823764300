import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBack,
  arrowForward,
  card,
  downloadOutline,
  pin,
} from "ionicons/icons";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Header from "../../components/Header";
import { KullaniciTip } from "../../enums/KullaniciTip";
import { AuthContext } from "../../providers/authContext";
import {
  CARD_ENROLLMENT_URL,
  HASH_URL,
  TAHSILAT_BASARILI,
  TAHSILAT_BASARISIZ,
} from "../../services/constants";
import raporService from "../../services/RaporService";
import tahsilatService from "../../services/TahsilatService";
import BorcMukellef from "../entities/BorcMukellef";
import OdenecekBorc from "../entities/OdenecekBorc";
import TahsilatHazirla from "../entities/TahsilatHazirla";
import "./BorcOdeme.css";

const BorcOdemeUye: React.FC = () => {
  const { authInfo, kurumBilgileri, setTitle } = React.useContext(AuthContext);

  const [refreshIFrame, setRefreshIFrame] = useState<number>(0);
  const [sonuc, setSonuc] = useState<BorcMukellef>();
  const [tahsilat, setTahsilat] = useState<TahsilatHazirla>();
  const [odenecekToplamTutar, setOdenecekToplamTutar] = useState<number>(0);
  const [odenecekToplamGecikme, setOdenecekToplamGecikme] = useState<number>(0);
  const [odenecekToplamAzaltan, setOdenecekToplamAzaltan] = useState<number>(0);
  const [odenecekGenelToplam, setOdenecekGenelToplam] = useState<number>(0);
  const [expandedRows, setExpandedRows] = useState<OdenecekBorc[]>([]);
  const [expandedSelectedRows, setExpandedSelectedRows] = useState<
    OdenecekBorc[]
  >([]);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [selectAllIndeterminate, setSelectAllIndeterminate] =
    useState<boolean>(false);
  const [selectedBorcs, setSelectedBorcs] = useState<OdenecekBorc[]>([]);
  const [groupedSelectedBorcs, setGroupedSelectedBorcs] = useState<
    OdenecekBorc[]
  >([]);

  const [paymentSlidesMainIndex, setPaymentSlidesMainIndex] = useState(0);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  useState<boolean>(false);
  const [tcNo, setTCNo] = useState<string>("");

  const [globalFilter, setGlobalFilter] = useState<any>(null);

  React.useEffect(() => {
    setTitle(
      kurumBilgileri?.eBelediyeClientId
        ? "Borç Sorgulama / Ödeme"
        : "Borç Sorgulama"
    );
    setTCNo((prev) => authInfo?.user?.username);
    setPaymentSlidesMainIndex(0);
    borcGetir();
  }, []);

  const borcGetir = () => {
    setShowLoading(true);
    const tip =
      authInfo?.user?.detail.tip.toString() ===
      KullaniciTip[KullaniciTip.BIREYSEL]
        ? "0"
        : "1";
    tahsilatService
      .getBorclar(authInfo?.user?.username, tip)
      .then(async (data: BorcMukellef) => {
        setSonuc(data);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const vazgec = () => {
    const i = refreshIFrame + 1;
    setRefreshIFrame(i);
    goBack();
  };

  const formatCurrency = (value: number | undefined) => {
    if (!value) {
      value = 0;
    }
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        suffix="₺"
        decimalScale={2}
        fixedDecimalScale
      />
    );
  };

  const formatDate = (value: any) => {
    if (!value) {
      return value;
    }
    return new Date(Date.parse(value)).toLocaleDateString();
  };

  const donemBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{rowData.yil}</IonText>;
  };

  const tipBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{rowData.tip}</IonText>;
  };

  const turBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{rowData.tur}</IonText>;
  };

  const aciklamaBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{rowData.aciklama}</IonText>;
  };

  const tahakkukTarihiBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{formatDate(rowData.tahakkukTarihi)}</IonText>;
  };

  const vadeTarihiBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{formatDate(rowData.vadeTarihi)}</IonText>;
  };

  const tutarBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{formatCurrency(rowData.tutar)}</IonText>;
  };

  const gecikmeBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{formatCurrency(rowData.gecikme)}</IonText>;
  };

  const azaltanBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{formatCurrency(rowData.azaltan)}</IonText>;
  };

  const toplamBodyTemplate = (rowData: OdenecekBorc) => {
    return <IonText>{formatCurrency(rowData.toplam)}</IonText>;
  };

  const rowExpansionTemplate = (rowData: OdenecekBorc) => {
    return (
      <IonCard>
        <IonCardContent>
          <DataTable value={rowData.detay} stripedRows size="small">
            <Column
              field="yil"
              style={{ width: "3.6em" }}
              header="Dönem"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding ion-tet-center"
            ></Column>
            <Column
              field="tur"
              style={{ width: "5.9em" }}
              header="Tür"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="tahakkukTarihi"
              style={{ width: "5.6em" }}
              header="Tahak. Tar."
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="ion-text-nowrap no-padding"
              body={(rowData: OdenecekBorc) =>
                formatDate(rowData.tahakkukTarihi)
              }
            ></Column>
            <Column
              field="aciklama"
              header="Açıklama"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="vadeTarihi"
              style={{ width: "4.6em" }}
              header="Vade Tarihi"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="ion-text-nowrap no-padding"
              body={(rowData: OdenecekBorc) => formatDate(rowData.vadeTarihi)}
            ></Column>
            <Column
              field="tutar"
              style={{ width: "4.1em" }}
              header="Tutar"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.tutar)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
            <Column
              field="gecikme"
              style={{ width: "4.1em" }}
              header="Gecikme"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.gecikme)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
            <Column
              field="azaltan"
              style={{ width: "4.1em" }}
              header="Azaltan"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.azaltan)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
            <Column
              field="toplam"
              style={{ width: "4.1em" }}
              header="Toplam"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.toplam)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
          </DataTable>
        </IonCardContent>
      </IonCard>
    );
  };

  const selectedRowExpansionTemplate = (rowData: OdenecekBorc) => {
    return (
      <IonCard>
        <IonCardContent>
          <DataTable value={rowData.detay} stripedRows size="small">
            <Column
              field="aciklama"
              header="Açıklama"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="vadeTarihi"
              style={{ width: "4.6em" }}
              header="Vade Tarihi"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="ion-text-nowrap no-padding"
              body={(rowData: OdenecekBorc) => formatDate(rowData.vadeTarihi)}
            ></Column>
            <Column
              field="tutar"
              style={{ width: "4.1em" }}
              header="Tutar"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.tutar)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
            <Column
              field="gecikme"
              style={{ width: "4.1em" }}
              header="Gecikme"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.gecikme)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
            <Column
              field="azaltan"
              style={{ width: "4.1em" }}
              header="Azaltan"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.azaltan)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
            <Column
              field="toplam"
              style={{ width: "4.1em" }}
              header="Toplam"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: OdenecekBorc) => formatCurrency(rowData.toplam)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
          </DataTable>
        </IonCardContent>
      </IonCard>
    );
  };

  const header = (
    <IonToolbar>
      <IonGrid className="no-padding">
        <IonRow className="ion-align-items-end">
          <IonCol size="12" sizeSm="4">
            <IonSearchbar
              value={globalFilter}
              onIonChange={(e) => setGlobalFilter(e.detail.value)}
              animated
              showCancelButton="focus"
              placeholder="Tabloda Ara..."
            ></IonSearchbar>
          </IonCol>
          <IonCol size="12" sizeSm="8" className="ion-no-padding">
            <IonRow className="ion-align-items-end ion-no-padding ion-no-margin">
              {kurumBilgileri?.eBelediyeClientId && (
                <IonCol
                  size="3"
                  className="ion-no-margin ion-hide-sm-up"
                  style={{ fontWeight: "normal" }}
                >
                  Tümünü Seç
                </IonCol>
              )}
              <IonCol className="ion-text-end">BORÇ TOPLAMI</IonCol>
              <IonCol size="2" className="ion-no-padding ion-hide-sm-down">
                <IonItem className="ion-no-padding">
                  <IonLabel position="stacked">Tutar Toplam</IonLabel>
                  <NumericFormat
                    value={sonuc?.toplamTutar}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₺"
                    className="ion-text-end"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </IonItem>
              </IonCol>
              <IonCol size="2" className="ion-no-padding ion-hide-sm-down">
                <IonItem className="ion-no-padding">
                  <IonLabel position="stacked">Gecikme Toplam</IonLabel>
                  <NumericFormat
                    value={sonuc?.toplamGecikme}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₺"
                    className="ion-text-end"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </IonItem>
              </IonCol>
              <IonCol size="2" className="ion-no-padding ion-hide-sm-down">
                <IonItem className="ion-no-padding">
                  <IonLabel position="stacked">Azaltan Toplam</IonLabel>
                  <NumericFormat
                    value={sonuc?.toplamAzaltan}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₺"
                    className="ion-text-end"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4" sizeSm="2" className="ion-no-padding">
                <div className="ion-hide-sm-up">
                  <NumericFormat
                    value={sonuc?.genelToplam}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₺"
                    style={{ paddingBottom: "0.3em" }}
                    className="ion-no-padding ion-padding-end ion-text-end"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </div>
                <IonItem className="ion-no-padding ion-hide-sm-down">
                  <IonLabel position="stacked">Genel Toplam</IonLabel>
                  <NumericFormat
                    value={sonuc?.genelToplam}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₺"
                    className="ion-text-end"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            {kurumBilgileri?.eBelediyeClientId && (
              <IonRow className="ion-no-margin ion-no-padding ion-hide-sm-up">
                <IonCol size="2" className="ion-no-margin ion-no-padding">
                  <IonCheckbox
                    className="ion-no-margin"
                    checked={selectAllChecked}
                    indeterminate={selectAllIndeterminate}
                    onIonChange={(e) => {
                      setSelectAllChecked(e.detail.checked);
                      e.detail.checked
                        ? setOdenecekInfo(sonuc!.borcListesi)
                        : setOdenecekInfo([]);
                    }}
                  />
                </IonCol>
                <IonCol className="ion-no-margin ion-no-padding ion-text-end ion-text-nowrap">
                  ÖDENECEK MİKTAR
                </IonCol>
                <IonCol size="4" className="ion-no-padding">
                  <div className="ion-hide-sm-up">
                    <NumericFormat
                      value={odenecekGenelToplam}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      suffix="₺"
                      style={{ paddingBottom: "0.3em" }}
                      className="ion-no-padding ion-padding-end ion-text-end"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );

  const header2 = (
    <IonToolbar>
      <IonGrid className="no-padding">
        <IonRow className="ion-align-items-center ion-justify-content-end">
          <IonCol size="4" sizeSm="3" className="ion-text-end ion-text-sm-wrap">
            ÖDENECEK BORÇ TOPLAMI
          </IonCol>
          <IonCol size="2" className="ion-no-padding ion-hide-sm-down">
            <IonItem className="ion-no-padding">
              <IonLabel position="stacked">Tutar Toplam</IonLabel>
              <NumericFormat
                value={odenecekToplamTutar}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="₺"
                className="ion-text-end"
                decimalScale={2}
                fixedDecimalScale
              />
            </IonItem>
          </IonCol>
          <IonCol size="2" className="ion-no-padding ion-hide-sm-down">
            <IonItem className="ion-no-padding">
              <IonLabel position="stacked">Gecikme Toplam</IonLabel>
              <NumericFormat
                value={odenecekToplamGecikme}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="₺"
                className="ion-text-end"
                decimalScale={2}
                fixedDecimalScale
              />
            </IonItem>
          </IonCol>
          <IonCol size="2" className="ion-no-padding ion-hide-sm-down">
            <IonItem className="ion-no-padding">
              <IonLabel position="stacked">Azaltan Toplam</IonLabel>
              <NumericFormat
                value={odenecekToplamAzaltan}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="₺"
                className="ion-text-end"
                decimalScale={2}
                fixedDecimalScale
              />
            </IonItem>
          </IonCol>
          <IonCol size="4" sizeSm="2" className="ion-no-padding">
            <div className="ion-hide-sm-up">
              <NumericFormat
                value={odenecekGenelToplam}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="₺"
                style={{ paddingBottom: "0.3em" }}
                className="ion-no-padding ion-padding-end ion-text-end"
                decimalScale={2}
                fixedDecimalScale
              />
            </div>
            <IonItem className="ion-no-padding ion-hide-sm-down">
              <IonLabel position="stacked">Genel Toplam</IonLabel>
              <NumericFormat
                value={odenecekGenelToplam}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                suffix="₺"
                className="ion-text-end"
                decimalScale={2}
                fixedDecimalScale
              />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );

  const goBack = async () => {
    setShowPaymentModal(false);
    setSelectAllIndeterminate(false);
    setSelectAllChecked(false);
    setSelectedBorcs([]);
    setOdenecekToplamTutar(0);
    setOdenecekToplamGecikme(0);
    setOdenecekToplamAzaltan(0);
    setOdenecekGenelToplam(0);
    const prevSlideIndex = paymentSlidesMainIndex - 1;
    await setPaymentSlidesMainIndex(prevSlideIndex);
    await contentRef.current?.scrollToTop();
  };

  const goForward = async () => {
    if (selectedBorcs.length > 0) {
      const nextSlideIndex = paymentSlidesMainIndex + 1;
      await setPaymentSlidesMainIndex(nextSlideIndex);
      await contentRef.current?.scrollToTop();
    } else {
      setShowToastMessage(
        "Sayın Mükellefimiz, lütfen ödemek için borç seçiniz."
      );
    }
  };

  const goForward2 = () => {
    setShowLoading(true);
    const tip =
      authInfo?.user?.detail.tip.toString() ===
      KullaniciTip[KullaniciTip.BIREYSEL]
        ? 0
        : 1;
    tahsilatService
      .tahsilatHazirla(tcNo, tip, odenecekGenelToplam, selectedBorcs)
      .then(async (data: TahsilatHazirla) => {
        setTahsilat(data);
        localStorage.setItem("oid", data.oid);
        setShowPaymentModal(true);
      })
      .catch((err) => {
        console.error(err);
        setShowToastMessage(err.response ? err.response.data.message : err);
      })
      .finally(() => setShowLoading(false));
  };

  const footer = (
    <IonToolbar>
      <IonItem>
        {kurumBilgileri?.eBelediyeClientId && (
          <>
            <IonLabel className="ion-text-end ion-padding-end">
              <IonText className="ion-hide-sm-down">
                TOPLAM ÖDENECEK MİKTAR:{" "}
              </IonText>
              {formatCurrency(odenecekGenelToplam)}
            </IonLabel>
            <IonButton
              slot="end"
              size="default"
              onClick={async () => goForward()}
            >
              <IonIcon slot="start" icon={card} />
              <IonText className="ion-hide-sm-up">Öde</IonText>
              <IonText className="ion-hide-sm-down">Borç Ödeme</IonText>
            </IonButton>
          </>
        )}
      </IonItem>
    </IonToolbar>
  );

  const footer2 = (
    <IonToolbar>
      <IonItem>
        <IonButton slot="start" size="default" onClick={() => goBack()}>
          <IonIcon slot="start" icon={arrowBack} />
          <IonText className="ion-hide-sm-down">Geri</IonText>
        </IonButton>
        <IonLabel className="ion-text-end ion-padding-end">
          <IonText className="ion-hide-sm-down">ÖDENECEK MİKTAR: </IonText>
          {formatCurrency(odenecekGenelToplam)}
        </IonLabel>
        <IonButton slot="end" size="default" onClick={async () => goForward2()}>
          <IonIcon slot="end" icon={arrowForward} />
          <IonText className="ion-hide-sm-down">İleri</IonText>
        </IonButton>
      </IonItem>
    </IonToolbar>
  );

  const setOdenecekInfo = (value: OdenecekBorc[]) => {
    setSelectedBorcs(value);
    setOdenecekToplamTutar(value.reduce((a, b) => a + b.tutar, 0));
    setOdenecekToplamGecikme(value.reduce((a, b) => a + b.gecikme, 0));
    setOdenecekToplamAzaltan(value.reduce((a, b) => a + b.azaltan, 0));
    setOdenecekGenelToplam(value.reduce((a, b) => a + b.toplam, 0));
  };

  const exportPDF = () => {
    setShowLoading(true);
    raporService
      .borcRaporuYazdir(tcNo, "0")
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "borcraporu.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
        setShowToastMessage(err.response ? err.response.data.message : err);
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <IonPage>
      <Header />
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={5000}
          position="top"
        />
        <Carousel
          selectedItem={paymentSlidesMainIndex}
          infiniteLoop={false}
          showStatus={false}
          showArrows={false}
          swipeable={false}
          showIndicators={false}
          showThumbs={false}
        >
          <div>
            <IonCard>
              <IonCardHeader>
                <IonItem color="light">
                  <IonIcon icon={pin} slot="start" />
                  {sonuc?.borcListesi.length === 0 ? (
                    <IonLabel className="ion-text-wrap">
                      Sayın Mükellefimiz, Kayıtlı Borcunuz Bulunmamaktadır.
                    </IonLabel>
                  ) : (
                    sonuc?.adiSoyadiUnvan && (
                      <React.Fragment>
                        <IonLabel className="ion-text-wrap">
                          Sayın {sonuc?.adiSoyadiUnvan}, borç dökümünüz
                          aşağıdaki gibidir.{" "}
                          {kurumBilgileri?.eBelediyeClientId &&
                            "Lütfen ödemek istediklerinizi listeden seçiniz."}
                        </IonLabel>
                        <IonButton
                          type="button"
                          color="success"
                          onClick={exportPDF}
                        >
                          <IonIcon slot="end" icon={downloadOutline} />
                          Yazdır
                        </IonButton>
                      </React.Fragment>
                    )
                  )}
                </IonItem>
              </IonCardHeader>
              {sonuc && sonuc.borcListesi.length > 0 && (
                <IonCardContent className="datatable-responsive">
                  <DataTable
                    value={sonuc?.borcListesi}
                    selection={selectedBorcs}
                    onSelectionChange={(e) => {
                      const selectedLength = e.value.length;
                      const borcLength = sonuc!.borcListesi.length;
                      setOdenecekInfo(e.value);
                      if (selectedLength > 0 && selectedLength < borcLength) {
                        setSelectAllIndeterminate(true);
                      } else {
                        setSelectAllIndeterminate(false);
                        setSelectAllChecked(selectedLength === borcLength);
                      }
                    }}
                    paginator
                    rows={10}
                    removableSort
                    expandedRows={expandedRows}
                    onRowToggle={(e) =>
                      setExpandedRows(e.data as OdenecekBorc[])
                    }
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}
                    footer={footer}
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    {kurumBilgileri?.eBelediyeClientId && (
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "1.5em" }}
                      ></Column>
                    )}
                    <Column
                      expander
                      style={{ width: "2.4em" }}
                      bodyClassName="sm-invisible"
                    />
                    <Column
                      field="yil"
                      header="Dönem"
                      sortable
                      headerClassName="ion-text-start ion-text-nowrap"
                      body={donemBodyTemplate}
                      bodyClassName="no-padding"
                    ></Column>
                    <Column
                      field="tip"
                      header="Tür"
                      sortable
                      headerClassName="ion-text-start"
                      body={tipBodyTemplate}
                      bodyClassName="no-padding"
                    ></Column>
                    <Column
                      field="vadeTarihi"
                      header="Vade Tarihi"
                      sortable
                      headerClassName="ion-text-start ion-text-nowrap"
                      body={vadeTarihiBodyTemplate}
                      bodyClassName="no-padding ion-text-nowrap"
                    ></Column>
                    <Column
                      field="tutar"
                      header="Tutar"
                      sortable
                      body={tutarBodyTemplate}
                      bodyClassName="p-column-content-currency"
                      headerClassName="ion-text-end"
                    ></Column>
                    <Column
                      field="gecikme"
                      header="Gecikme"
                      sortable
                      body={gecikmeBodyTemplate}
                      bodyClassName="p-column-content-currency"
                      headerClassName="ion-text-end"
                    ></Column>
                    <Column
                      field="azaltan"
                      header="Azaltan"
                      sortable
                      body={azaltanBodyTemplate}
                      bodyClassName="p-column-content-currency"
                      headerClassName="ion-text-end"
                    ></Column>
                    <Column
                      field="toplam"
                      header="Toplam Tutar"
                      sortable
                      body={toplamBodyTemplate}
                      bodyClassName="p-column-content-currency"
                      headerClassName="ion-text-end ion-text-nowrap"
                    ></Column>
                  </DataTable>
                </IonCardContent>
              )}
            </IonCard>
          </div>
          <div>
            <IonCard>
              <IonCardHeader>
                <IonItem color="light">
                  <IonIcon icon={pin} slot="start" />
                  <IonLabel className="ion-text-wrap">
                    Sayın Mükellefimiz, ödemek için seçtiğiniz borçlar
                    aşağıdadır. Kredi Kartı Bilgilerinizi girip ödemeyi
                    tamamlayabilmeniz için İleri butonuna basınız.
                  </IonLabel>
                </IonItem>
              </IonCardHeader>
              <IonCardContent className="datatable-responsive">
                <DataTable
                  value={selectedBorcs}
                  paginator
                  rows={5}
                  removableSort
                  expandedRows={expandedSelectedRows}
                  onRowToggle={(e) =>
                    setExpandedSelectedRows(e.data as OdenecekBorc[])
                  }
                  rowExpansionTemplate={selectedRowExpansionTemplate}
                  dataKey="id"
                  header={header2}
                  footer={footer2}
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  <Column
                    expander
                    style={{ width: "2.4em" }}
                    bodyClassName="sm-invisible"
                  />
                  <Column
                    field="yil"
                    header="Dönem"
                    sortable
                    headerClassName="ion-text-start ion-text-nowrap"
                    body={donemBodyTemplate}
                    bodyClassName="no-padding"
                  ></Column>
                  <Column
                    field="tip"
                    header="Tür"
                    sortable
                    headerClassName="ion-text-start"
                    body={tipBodyTemplate}
                    bodyClassName="no-padding"
                  ></Column>
                  <Column
                    field="vadeTarihi"
                    header="Vade Tarihi"
                    sortable
                    headerClassName="ion-text-start ion-text-nowrap"
                    body={vadeTarihiBodyTemplate}
                    bodyClassName="no-padding ion-text-nowrap"
                  ></Column>
                  <Column
                    field="tutar"
                    header="Tutar"
                    sortable
                    body={tutarBodyTemplate}
                    bodyClassName="p-column-content-currency"
                    headerClassName="ion-text-end"
                  ></Column>
                  <Column
                    field="gecikme"
                    header="Gecikme"
                    sortable
                    body={gecikmeBodyTemplate}
                    bodyClassName="p-column-content-currency"
                    headerClassName="ion-text-end"
                  ></Column>
                  <Column
                    field="azaltan"
                    header="Azaltan"
                    sortable
                    body={azaltanBodyTemplate}
                    bodyClassName="p-column-content-currency"
                    headerClassName="ion-text-end"
                  ></Column>
                  <Column
                    field="toplam"
                    header="Toplam Tutar"
                    sortable
                    body={toplamBodyTemplate}
                    bodyClassName="p-column-content-currency"
                    headerClassName="ion-text-end ion-text-nowrap"
                  ></Column>
                </DataTable>
                <IonModal
                  isOpen={showPaymentModal}
                  onDidDismiss={() => {
                    setShowPaymentModal(false);
                  }}
                >
                  <IonCard>
                    <IonCardHeader>
                      <IonLabel className="ion-text-end ion-padding-end">
                        TOPLAM ÖDENECEK MİKTAR:{" "}
                        {formatCurrency(odenecekGenelToplam)}
                      </IonLabel>
                    </IonCardHeader>
                    <IonCardContent>
                      <iframe
                        id="iframe3dpayment"
                        key={refreshIFrame}
                        onLoad={(e: any) => {
                          // e.preventDefault();
                          const freym: any =
                            document.getElementById("iframe3dpayment");
                          const msg =
                            kurumBilgileri?.banka === "ipay3d"
                              ? {
                                  selectedBorcs: selectedBorcs
                                    ?.map((e) =>
                                      e.detay.map((ed) => ed.id).join(" ")
                                    )
                                    .join(" "),
                                  odenecekGenelToplam:
                                    odenecekGenelToplam.toFixed(2),
                                  EnrollmentUrl: CARD_ENROLLMENT_URL,
                                }
                              : {
                                  sanalPosUrl: kurumBilgileri?.sanalPosUrl,
                                  eBelediyeClientId:
                                    kurumBilgileri?.eBelediyeClientId,
                                  kurumTanim: kurumBilgileri?.kisaTanim,
                                  rnd: tahsilat?.rnd,
                                  oid: tahsilat?.oid,
                                  selectedBorcs: selectedBorcs
                                    ?.map((e) =>
                                      e.detay.map((ed) => ed.id).join(" ")
                                    )
                                    .join(" "),
                                  hash: tahsilat?.hash,
                                  sanalPosTip: kurumBilgileri?.sanalPosTip,
                                  odenecekGenelToplam:
                                    odenecekGenelToplam.toFixed(2),
                                  TAHSILAT_BASARILI: TAHSILAT_BASARILI,
                                  TAHSILAT_BASARISIZ: TAHSILAT_BASARISIZ,
                                  HASH_URL: HASH_URL,
                                };
                          freym.contentWindow.postMessage(msg, "*");
                        }}
                        name="iframe3dpayment"
                        width="100%"
                        height="450em"
                        src={
                          kurumBilgileri?.banka === "ipay3d"
                            ? "/payment_ipay.html"
                            : "/payment.html"
                        }
                        frameBorder="0"
                      ></iframe>
                      <IonToolbar>
                        <IonButton
                          slot="primary"
                          color="success"
                          onClick={() => {
                            setShowPaymentModal(false);
                          }}
                        >
                          Vazgeç
                        </IonButton>
                      </IonToolbar>
                    </IonCardContent>
                  </IonCard>
                </IonModal>
              </IonCardContent>
            </IonCard>
          </div>
        </Carousel>
      </IonContent>
    </IonPage>
  );
};

export default BorcOdemeUye;
