import BinaryData from "./BinaryData";

export default class IstekSikayet {
  public id: string = "";
  public basvuruTarihi: Date = new Date();
  public istekSikayetNo: string = "";
  public tcNo: string = "";
  public adi: string = "";
  public soyadi: string = "";
  public adiSoyadi: string = "";
  public eposta: string = "";
  public basvuruNedeni: string = "";
  public telefon: string = "";
  public cepTelefonu: string = "";
  public konuBasligi: string = "";
  public konuAciklamasi: string = "";
  public gizliSikayet: boolean = false;
  public ikametAdresiAyni: boolean = false;
  public sikayetMahalleId: string = "";
  public sikayetSokakId: string = "";
  public sikayetBlokNo: string = "";
  public sikayetKatNo: string = "";
  public sikayetKapiNo: string = "";
  public sikayetDaireNo: string = "";
  public ikametMahalleId: string = "";
  public ikametSokakId: string = "";
  public ikametBlokNo: string = "";
  public ikametKatNo: string = "";
  public ikametKapiNo: string = "";
  public ikametDaireNo: string = "";
  public belge: BinaryData = new BinaryData();
  public kaptchaId: string = "";
  public kaptchaCode: string = "";
  public sonucTarihi: Date = new Date();
  public sonucZamani: string = "";
  public istekSikayetDetay: [] = [];

  constructor() {
    this.id = "";
    this.basvuruTarihi = new Date();
    this.istekSikayetNo = "";
    this.tcNo = "";
    this.adi = "";
    this.soyadi = "";
    this.adiSoyadi = "";
    this.eposta = "";
    this.basvuruNedeni = "";
    this.telefon = "";
    this.cepTelefonu = "";
    this.konuBasligi = "";
    this.konuAciklamasi = "";
    this.gizliSikayet = false;
    this.ikametAdresiAyni = false;
    this.sikayetMahalleId = "";
    this.sikayetSokakId = "";
    this.sikayetBlokNo = "";
    this.sikayetKatNo = "";
    this.sikayetKapiNo = "";
    this.sikayetDaireNo = "";
    this.ikametMahalleId = "";
    this.ikametSokakId = "";
    this.ikametBlokNo = "";
    this.ikametKatNo = "";
    this.ikametKapiNo = "";
    this.ikametDaireNo = "";
    this.belge = new BinaryData();
    this.kaptchaId = "";
    this.kaptchaCode = "";
    this.sonucTarihi = new Date();
    this.sonucZamani = "";
    this.istekSikayetDetay = [];
  }
}
