import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRange,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { AuthContext } from "../../providers/authContext";
import Header from "../components/Header";
import { AskidaFaturaIstatistik } from "../entities/AskidaFaturaIstatistik";
import { AskidaYardimDetay } from "../entities/AskidaYardimDetay";
import { KurumBilgileri } from "../entities/KurumBilgileri";
import Tahsilat from "../entities/tahsilat";
import {
  TAHSILAT_BASARILI,
  TAHSILAT_BASARISIZ,
  HASH_URL,
} from "../services/constants";
import sorgulamaService from "../services/SorgulamaService";
import tahsilatService from "../services/TahsilatService";

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0,
  effect: "flip",
  speed: 400,
};

const GidaPaketi: React.FC = () => {
  const { logo, kurumBilgileri } = React.useContext(AuthContext);
  const slides = useRef<HTMLIonSlidesElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [refreshIFrame, setRefreshIFrame] = useState<number>(0);
  const [tahsilat, setTahsilat] = useState<Tahsilat>();
  const [rangeValue, setRangeValue] = useState<{
    lower: number;
    upper: number;
  }>({ lower: 0, upper: 2000 });
  const [faturalar, setFaturalar] = useState<AskidaYardimDetay[]>([]);
  const [ilkFaturalar, setIlkFaturalar] = useState<AskidaYardimDetay[]>([]);
  const [fatura, setFatura] = useState<AskidaYardimDetay>();
  const [istatistik, setIstatistik] = useState<AskidaFaturaIstatistik>(
    new AskidaFaturaIstatistik(0, 0, 0)
  );

  React.useEffect(() => {
    slides.current?.lockSwipes(true);
    sorgulamaService
      .getRamazanPaketleri()
      .then((data) => {
        const sorted = data.sort(
          (p: AskidaYardimDetay, v: AskidaYardimDetay) => p.tutar - v.tutar
        );
        setIlkFaturalar(sorted);
        setFaturalar(sorted);
        /*
            let min = data.reduce((p: AskidaYardimDetay, v: AskidaYardimDetay) => (p.tutar < v.tutar ? p : v)).tutar;
            if (!min) {
                min = 0;
            }
            let max = data.reduce((p: AskidaYardimDetay, v: AskidaYardimDetay) => (p.tutar > v.tutar ? p : v)).tutar;
            if (!max) {
                max = 0;
            }
            setRangeValue({ lower: min, upper: max });
            */
      })
      .catch((err) => {
        console.error(err);
        setShowToastMessage(err);
      })
      .finally(() => setShowLoading(false));
    sorgulamaService
      .getAskidaYardimIstatistik(1)
      .then((data) => setIstatistik(data))
      .catch((err) => {
        console.error(err.response);
        setShowToastMessage(err.message);
      });
  }, []);

  useEffect(() => {
    setFaturalar(
      ilkFaturalar.filter(
        (a) => a.tutar > rangeValue.lower && a.tutar < rangeValue.upper
      )
    );
  }, [rangeValue]);

  const formatCurrency = (value: number | undefined) => {
    if (!value) {
      value = 0;
    }
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
    }).format(value);
  };

  const goBack = async () => {
    setFatura(undefined);
    await slides.current?.lockSwipes(false);
    await slides.current?.slidePrev();
    await slides.current?.lockSwipes(true);
  };

  const goForward = async (a: AskidaYardimDetay) => {
    setShowLoading(true);
    setFatura(a);
    tahsilatService
      .askidaTahsilatHazirla(a.id, a.tutar, true)
      .then(async (data: Tahsilat) => {
        setTahsilat(data);
        await contentRef.current?.scrollToTop();
        await slides.current?.lockSwipes(false);
        await slides.current?.slideNext();
        await slides.current?.lockSwipes(true);

        const freym: any = document.getElementById("iframe3dpayment");
        const msg = {
          sanalPosUrl: kurumBilgileri?.sanalPosUrl,
          eBelediyeClientId: kurumBilgileri?.eBelediyeClientId,
          kurumTanim: kurumBilgileri?.kisaTanim,
          rnd: data?.rnd,
          oid: data?.oid,
          hash: data?.hash,
          sanalPosTip: kurumBilgileri?.sanalPosTip,
          odenecekGenelToplam: a.tutar.toFixed(2),
          TAHSILAT_BASARILI: `${TAHSILAT_BASARILI}/true`,
          TAHSILAT_BASARISIZ: TAHSILAT_BASARISIZ,
          selectedBorcs: a.id,
        };
        freym.contentWindow.postMessage(msg, "*");
      })
      .catch((err) => {
        console.log(err);
        setShowToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <IonPage>
      <Header
        title={kurumBilgileri?.kisaTanim}
        subtitle="Derman Ol Kampanyası"
        logo={logo}
      />
      <IonContent ref={contentRef} fullscreen className="ion-text-center">
        <IonToolbar>
          <IonTitle>ASKIDAN GIDA PAKETİ AL</IonTitle>
        </IonToolbar>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={2000}
          position="top"
        />
        <IonSlides
          pager={false}
          options={slideOpts}
          ref={slides}
          scrollbar={true}
        >
          <IonSlide>
            <IonList>
              <IonItem>
                <IonGrid className="center">
                  <IonRow style={{ width: "80%" }}>
                    <IonCol size="12" sizeSm="6">
                      <IonCard>
                        <IonCardHeader>
                          <IonRow>
                            <IonCol size="4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={60}
                                height={60}
                                viewBox="0 0 32 32"
                              >
                                <path
                                  d="M 16 3 C 8.832 3 3 8.832 3 16 C 3 23.168 8.832 29 16 29 C 23.168 29 29 23.168 29 16 C 29 8.832 23.168 3 16 3 z M 16 5 C 22.065 5 27 9.935 27 16 C 27 22.065 22.065 27 16 27 C 9.935 27 5 22.065 5 16 C 5 9.935 9.935 5 16 5 z M 11 12 C 8.906 12 7.390625 13.207031 7.390625 13.207031 L 8.609375 14.792969 C 8.609375 14.792969 9.6929531 14 11.001953 14 C 12.310953 14 13.392578 14.792969 13.392578 14.792969 L 14.611328 13.207031 C 14.609328 13.207031 13.094 12 11 12 z M 21 12 C 18.906 12 17.390625 13.207031 17.390625 13.207031 L 18.609375 14.792969 C 18.609375 14.792969 19.692953 14 21.001953 14 C 22.310953 14 23.392578 14.792969 23.392578 14.792969 L 24.611328 13.207031 C 24.609328 13.207031 23.094 12 21 12 z M 10.810547 19 L 9.0898438 20 C 9.2635938 20.29875 9.455 20.584668 9.6640625 20.857422 C 9.873125 21.130176 10.100547 21.389297 10.341797 21.632812 C 10.824297 22.119844 11.368438 22.545859 11.960938 22.896484 C 12.257188 23.071797 12.56375 23.228887 12.882812 23.365234 C 13.201875 23.501582 13.532344 23.616797 13.871094 23.710938 C 14.209844 23.805078 14.55875 23.87709 14.914062 23.925781 C 15.269375 23.974473 15.63125 24 16 24 C 16.36875 24 16.730625 23.974473 17.085938 23.925781 C 18.8625 23.682324 20.451953 22.850391 21.658203 21.632812 C 21.899453 21.389297 22.126875 21.130176 22.335938 20.857422 C 22.545 20.584668 22.736406 20.29875 22.910156 20 L 21.189453 19 C 20.279453 20.56625 18.695957 21.689238 16.820312 21.945312 C 16.552363 21.981895 16.27875 22 16 22 C 15.72125 22 15.447637 21.981895 15.179688 21.945312 C 14.911738 21.90873 14.649453 21.853906 14.394531 21.783203 C 13.884688 21.641797 13.403359 21.435 12.958984 21.171875 C 12.514609 20.90875 12.107188 20.589766 11.746094 20.224609 C 11.565547 20.042031 11.396621 19.846973 11.240234 19.642578 C 11.083848 19.438184 10.940547 19.22375 10.810547 19 z"
                                  fill="blue"
                                />
                              </svg>
                            </IonCol>
                            <IonCol>
                              <IonGrid>
                                <IonRow>
                                  <IonCardTitle>
                                    {istatistik.askidanAlinan}
                                  </IonCardTitle>
                                </IonRow>
                                <IonRow>
                                  <IonCardSubtitle>
                                    Adet Alınan Paket
                                  </IonCardSubtitle>
                                </IonRow>
                              </IonGrid>
                            </IonCol>
                          </IonRow>
                        </IonCardHeader>
                      </IonCard>
                    </IonCol>
                    <IonCol size="12" sizeSm="6">
                      <IonCard>
                        <IonCardHeader>
                          <IonRow>
                            <IonCol size="4">
                              <svg
                                _ngcontent-dyy-c3=""
                                aria-hidden="true"
                                className="svg-inline--fa display-4"
                                data-fa-i2svg=""
                                data-icon="li-ra-sign"
                                data-prefix="fas"
                                focusable="false"
                                role="img"
                                viewBox="0 0 384 512"
                                width={60}
                                height={60}
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  _ngcontent-dyy-c3=""
                                  d="M371.994 256h-48.019C317.64 256 312 260.912 312 267.246 312 368 230.179 416 144 416V256.781l134.603-29.912A12 12 0 0 0 288 215.155v-40.976c0-7.677-7.109-13.38-14.603-11.714L144 191.219V160.78l134.603-29.912A12 12 0 0 0 288 119.154V78.179c0-7.677-7.109-13.38-14.603-11.714L144 95.219V44c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v68.997L9.397 125.131A12 12 0 0 0 0 136.845v40.976c0 7.677 7.109 13.38 14.603 11.714L64 178.558v30.439L9.397 221.131A12 12 0 0 0 0 232.845v40.976c0 7.677 7.109 13.38 14.603 11.714L64 274.558V468c0 6.627 5.373 12 12 12h79.583c134.091 0 223.255-77.834 228.408-211.592.261-6.782-5.211-12.408-11.997-12.408z"
                                  fill="blue"
                                />
                              </svg>
                            </IonCol>
                            <IonCol>
                              <IonGrid>
                                <IonRow>
                                  <IonCardTitle>
                                    <CountUp
                                      end={istatistik.toplamOdenenTutar}
                                      decimals={2}
                                      decimal=","
                                    />
                                  </IonCardTitle>
                                </IonRow>
                                <IonRow>
                                  <IonCardSubtitle>
                                    Toplam Ödenen Tutar
                                  </IonCardSubtitle>
                                </IonRow>
                              </IonGrid>
                            </IonCol>
                          </IonRow>
                        </IonCardHeader>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItemDivider>
                Başlangıç & Bitiş Filtre Değerleri
              </IonItemDivider>
              <IonItem>
                <IonRange
                  pin
                  dualKnobs
                  value={rangeValue}
                  min={0}
                  max={2000}
                  step={50}
                  snaps={true}
                  onIonChange={(e) => setRangeValue(e.detail.value as any)}
                >
                  <IonLabel slot="start">
                    Başlangıç: {rangeValue.lower}
                  </IonLabel>
                  <IonLabel slot="end">Bitiş: {rangeValue.upper}</IonLabel>
                </IonRange>
              </IonItem>
              <IonCard>
                <IonGrid>
                  <IonRow>
                    {faturalar.map((a, index: any) => {
                      return (
                        <IonCol key={index} size="6" sizeSm="4">
                          <IonCard
                            className="card-hover"
                            button
                            onClick={async () => goForward(a)}
                          >
                            <IonCardHeader>
                              <IonCardTitle>
                                {formatCurrency(a.tutar)}
                              </IonCardTitle>
                              <IonCardSubtitle>{a.tanim}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <IonButton>Askıdan Al</IonButton>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonList>
          </IonSlide>
          <IonSlide>
            <IonList>
              <IonItem>
                <IonButton
                  slot="start"
                  color="success"
                  onClick={() => {
                    const i = refreshIFrame + 1;
                    setRefreshIFrame(i);
                    goBack();
                  }}
                >
                  Vazgeç
                </IonButton>
                <IonText>
                  TOPLAM ÖDENECEK MİKTAR: {formatCurrency(fatura?.tutar)}
                </IonText>
              </IonItem>
              <IonItem>
                <iframe
                  id="iframe3dpayment"
                  key={refreshIFrame}
                  onLoad={(e: any) => {
                    // e.preventDefault();
                    const freym: any =
                      document.getElementById("iframe3dpayment");
                    const msg = {
                      sanalPosUrl: kurumBilgileri?.sanalPosUrl,
                      eBelediyeClientId: kurumBilgileri?.eBelediyeClientId,
                      kurumTanim: kurumBilgileri?.kisaTanim,
                      rnd: tahsilat?.rnd,
                      oid: tahsilat?.oid,
                      hash: tahsilat?.hash,
                      sanalPosTip: kurumBilgileri?.sanalPosTip,
                      odenecekGenelToplam: fatura?.tutar.toFixed(2),
                      TAHSILAT_BASARILI: `${TAHSILAT_BASARILI}/true`,
                      TAHSILAT_BASARISIZ: TAHSILAT_BASARISIZ,
                      HASH_URL: HASH_URL,
                      selectedBorcs: fatura?.id,
                    };
                    freym.contentWindow.postMessage(msg, "*");
                  }}
                  name="iframe3dpayment"
                  width="100%"
                  height="500em"
                  src="/payment.html"
                  frameBorder="0"
                ></iframe>
              </IonItem>
            </IonList>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default GidaPaketi;
