export class Kisi {
    constructor(
        public tcKimlikNo: string,
        public cuzdanSeri: string,
        public cuzdanNo: string,
        public cuzdanSeriNo: string,
        public eposta: string,
        public eposta2: string,
        public cepTel: string,
    ) {
    }
}