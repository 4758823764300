import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonToast,
} from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { downloadOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import raporService from "../../services/RaporService";
import sorgulamaService from "../../services/SorgulamaService";
import CevreGrupDereceDegerleri from "../entities/CevreGrupDereceDegerleri";
import "./Page.css";

const CevreGrupDereceSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [sonuc, setSonuc] = useState<CevreGrupDereceDegerleri[]>([]);
  const dt = useRef<any>(null);
  const loadingData = [
    { grup: "", derece: "", deger: 0 },
    { grup: "", derece: "", deger: 0 },
    { grup: "", derece: "", deger: 0 },
    { grup: "", derece: "", deger: 0 },
    { grup: "", derece: "", deger: 0 },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [yillar, setYillar] = useState<number[]>([]);
  const [yil, setYil] = useState<number>(0);
  const [yilMessage, setYilMessage] = useState<string>();
  const [showToastMessage, setShowToastMessage] = useState<string>();

  React.useEffect(() => {
    setTitle("Çevre Temizlik Tarifeleri Sorgulama");
    setShowLoading(true);
    sorgulamaService
      .getCevreDonemler()
      .then((data: number[]) => setYillar(data))
      .finally(() => setShowLoading(false));
  }, []);

  const getCevreGrupDerece = (event: any) => {
    event.preventDefault();
    let errorFound = false;
    setYilMessage(undefined);
    if (yil <= 0) {
      setYilMessage("* Yıl alanını doldurunuz");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }

    setLoading(true);
    sorgulamaService
      .getCevreGrupDereceDegerler(yil)
      .then((data: CevreGrupDereceDegerleri[]) => setSonuc(data))
      .finally(() => setLoading(false));
  };

  const formatCurrency = (value: number) => {
    if (!value) {
      value = 0;
    }
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        suffix="₺"
        decimalScale={2}
        fixedDecimalScale
      />
    );
  };

  const loadingBodyTemplate = () => {
    return <IonSkeletonText animated />;
  };
  const grupBodyTemplate = (rowData: any) => {
    return <IonText>{rowData.grup}</IonText>;
  };

  const dereceBodyTemplate = (rowData: any) => {
    return <IonText>{rowData.derece}</IonText>;
  };

  const degerBodyTemplate = (rowData: any) => {
    return <IonText>{formatCurrency(rowData.deger)}</IonText>;
  };

  const exportPDF = () => {
    setShowLoading(true);
    raporService
      .cevreGrupDereceYazdir(yil)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "cevregrupderecedegerleri.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        setShowToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  };

  const footer = (
    <div
      style={{ textAlign: "end", display: sonuc.length > 0 ? "block" : "none" }}
    >
      <IonButton type="button" color="success" onClick={exportPDF}>
        <IonIcon slot="start" icon={downloadOutline} />
        Yazdır
      </IonButton>
    </div>
  );

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonContent>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonToast
            isOpen={showToastMessage !== undefined}
            onDidDismiss={() => setShowToastMessage(undefined)}
            message={showToastMessage}
            duration={2000}
            position="top"
          />
          <form
            onSubmit={(e) => {
              getCevreGrupDerece(e);
            }}
          >
            <IonGrid>
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>Yıl</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">Yıl</IonLabel>
                    <IonSelect
                      name="yilInput"
                      interface="popover"
                      value={yil}
                      onIonChange={(e) => setYil(e.detail.value)}
                    >
                      {yillar &&
                        yillar.map((val, i) => (
                          <IonSelectOption key={i} value={val}>
                            {val}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonSelect
                      name="yilInput"
                      interface="popover"
                      value={yil}
                      placeholder="Seçiniz..."
                      onIonChange={(e) => setYil(e.detail.value)}
                    >
                      {yillar &&
                        yillar.map((val, i) => (
                          <IonSelectOption key={i} value={val}>
                            {val}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {yilMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {yilMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <IonButton type="submit">Sorgula</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonCard>
              <IonCardContent className="datatable-responsive">
                {loading && (
                  <DataTable
                    value={loadingData}
                    paginator
                    rows={10}
                    removableSort
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="grup"
                      header="Grup"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="derece"
                      header="Derece"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="deger"
                      header="Yıllık Tutarı"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                      bodyClassName="p-column-content-currency"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                )}
                {!loading && (
                  <DataTable
                    value={sonuc}
                    paginator
                    rows={10}
                    removableSort
                    footer={footer}
                    ref={dt}
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="grup"
                      header="Grup"
                      sortable
                      headerClassName="ion-text-center"
                      body={grupBodyTemplate}
                    ></Column>
                    <Column
                      field="derece"
                      header="Derece"
                      sortable
                      headerClassName="ion-text-center"
                      body={dereceBodyTemplate}
                    ></Column>
                    <Column
                      field="deger"
                      header="Yıllık Tutarı"
                      sortable
                      body={degerBodyTemplate}
                      headerClassName="ion-text-center"
                      bodyClassName="p-column-content-currency"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                )}
              </IonCardContent>
            </IonCard>
          </form>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default CevreGrupDereceSorgu;
