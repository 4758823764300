import { KullaniciTip } from "../../enums/KullaniciTip";
import { ROLE } from "../../enums/ROLE";
import { Menu } from "./menu/Menu";

export class User {
  constructor(
    public detail: {
      sicilNo: number;
      tip: KullaniciTip;
      adSoyad: string | undefined;
      role: ROLE | undefined;
      menus: Array<Menu>;
    },
    public username: string,
    public password: string
  ) {}
}
