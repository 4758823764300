import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import './ExploreContainer.css';

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container">
      <strong>{name}</strong>
      {/* <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p> */}

      <IonItem>
        <IonGrid>
          <IonRow className="ion-text-center">
            <IonCol>
              <img src="assets/underconstruct.png" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </div>
  );
};

export default ExploreContainer;
