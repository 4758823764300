import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import "./Page.css";

const BinaBildirim: React.FC = () => {
  const { authInfo, setTitle } = React.useContext(AuthContext);
  const [oncekiSifre, setOncekiSifre] = useState<string>();
  const [yeniSifre, setYeniSifre] = useState<string>();
  const [sifreTekrar, setSifreTekrar] = useState<string>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();

  React.useEffect(() => {
    setTitle("Bina Bildirim İşlemi");
  }, []);

  const kaydet = (event: any) => {
    event.preventDefault();
    if (!oncekiSifre) {
      setToastMessage("Eski şifreyi giriniz!");
      return;
    }
    if (!yeniSifre) {
      setToastMessage("Şifre boş geçilemez!");
      return;
    }
    if (yeniSifre.length < 5) {
      setToastMessage("Şifre en az 5 karakterli olmalıdır!");
      return;
    }
    if (sifreTekrar && sifreTekrar !== yeniSifre) {
      setToastMessage("Şifre doğrulaması hatalı!");
      return;
    }
    if (oncekiSifre !== authInfo?.user?.password) {
      setToastMessage("Eski şifre hatalı!");
      return;
    }
    /*
    setShowLoading(true);
    userService
      .bildirimKaydet(oncekiSifre, yeniSifre, sifreTekrar!)
      .then(() => setToastMessage("Şifreniz Değiştirilmiştir."))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setToastMessage(error.response ? error.response.data.message : error);
      })
      .finally(() => setShowLoading(false));
      */
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={toastMessage !== undefined}
          onDidDismiss={() => setToastMessage(undefined)}
          message={toastMessage}
          duration={2000}
          position="top"
        />
        <form
          onSubmit={(e) => {
            kaydet(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Önceki Şifre</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Önceki Şifre</IonLabel>
                  <IonInput
                    name="oncekiSifreInput"
                    value={oncekiSifre}
                    type="password"
                    onIonChange={(e) => setOncekiSifre(e.detail.value!)}
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="oncekiSifreInput"
                    value={oncekiSifre}
                    type="password"
                    onIonChange={(e) => setOncekiSifre(e.detail.value!)}
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Yeni Şifre</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Yeni Şifre</IonLabel>
                  <IonInput
                    name="yeniSifreInput"
                    value={yeniSifre}
                    type="password"
                    onIonChange={(e) => setYeniSifre(e.detail.value!)}
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="yeniSifreInput"
                    value={yeniSifre}
                    type="password"
                    onIonChange={(e) => setYeniSifre(e.detail.value!)}
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Yeni Şifre (Tekrar)</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Yeni Şifre (Tekrar)</IonLabel>
                  <IonInput
                    name="sifreTekrarInput"
                    value={sifreTekrar}
                    type="password"
                    onIonChange={(e) => setSifreTekrar(e.detail.value!)}
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="sifreTekrarInput"
                    value={sifreTekrar}
                    type="password"
                    onIonChange={(e) => setSifreTekrar(e.detail.value!)}
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <IonButton type="submit">Kaydet</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default BinaBildirim;
