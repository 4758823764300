import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { AuthContext } from "../../providers/authContext";
import Header from "../components/Header";
import { AskidaYardim } from "../entities/AskidaYardim";
import { KurumBilgileri } from "../entities/KurumBilgileri";
import sorgulamaService from "../services/SorgulamaService";
import "./Home.css";

const Home: React.FC = () => {
  const { logo, kurumBilgileri } = React.useContext(AuthContext);
  const [askidaYardim, setAskidaYardim] = useState<AskidaYardim[]>([]);

  React.useEffect(() => {
    sorgulamaService
      .getYardimBilgileri()
      .then((data) => setAskidaYardim(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <IonPage>
      <Header
        title={kurumBilgileri?.kisaTanim}
        subtitle="Derman Ol Kampanyası"
        logo={logo}
      />
      <IonContent fullscreen className="ion-text-center">
        <h1 className="h1-padding">
          <b>#BirlikteDermanOlacağız</b>
        </h1>
        <hr color="secondary" />
        <IonItem>
          <IonText className="ion-text-center">
            <div className="div-padding">
              Tüm dünyada olduğu gibi ülkemizde de salgınla mücadelede zorlu
              süreçten geçiyoruz.{" "}
            </div>
            <div className="div-padding">
              Bu süreçte işini kaybeden komşularımız,
              <br />
              Aşını yitiren dostlarımız,
              <br />
              Çok zor durumda hemşerilerimiz var.
            </div>
            <div className="div-padding">
              Üstelik ekonomik zorluklar ve geçim sıkıntısı çeken
              vatandaşlarımızın sayısı her geçen gün artmaktadır.
            </div>
            <div className="div-padding">
              <span className="ion-text-capitalize">
                {kurumBilgileri?.kisaTanim}
              </span>{" "}
              olarak şimdi de “Derman Ol Kampanyası” başlatıyoruz.
            </div>
            <div className="div-padding">
              Derman Belediyecilik anlayışıyla başlattığımız “Birlikte Derman
              Olacağız” kampanyamıza vereceğiniz destekle fatura ödeme güçlüğü
              çeken veya temel gıda ihtiyaçlarına ulaşamayan vatandaşlarımıza
              bir nebzede olsa derman olmuş; eğitim, bebek ve yakacak
              ihtiyaçlarını karşılayamayan vatandaşlarımıza çok kıymetli bir
              destek vermiş olacaksınız.
            </div>
            <div className="div-padding">
              Gelin belirlediğimiz ihtiyaç sahiplerinin Fatura borçlarını
              ödeyelim.
            </div>
            <div className="div-padding">
              Gerçek ihtiyaç sahiplerini bir sıkıntıdan daha kurtaralım.
            </div>
            <div className="div-padding">
              Gelin Derde ortak olalım, derman olalım.
            </div>
            <div className="div-padding">
              Belediyemizin “Askıda Fatura” uygulamasına destek için sizleri
              belediyemizin sitesine davet ediyor.
            </div>
            <div className="div-padding">
              İhtiyaç sahiplerine derman olacak tüm hayırseverlere şimdiden çok
              teşekkür ediyorum.
            </div>
            <div className="div-padding">
              <img src="assets/baskan_imza.png" />
            </div>
          </IonText>
        </IonItem>

        <IonItem>
          <IonGrid className="ion-grid-width">
            <IonRow>
              <IonCard
                className="askida-fatura"
                button
                routerLink="/askidayardim/askidafatura"
              >
                <img src="assets/askida_Fatura1.png" />
              </IonCard>
            </IonRow>
            <IonRow>
              {askidaYardim &&
                askidaYardim.map((a) => (
                  <IonCol
                    size="6"
                    sizeSm="4"
                    className="ion-col-nospace"
                    key={a.id}
                  >
                    {a.aktif ? (
                      <IonCard
                        className="ion-padding home-card-hover"
                        button
                        routerLink={a.url}
                      >
                        <img src={a.icon} alt={a.tanim} title={a.tanim} />
                      </IonCard>
                    ) : (
                      <IonCard className="ion-padding home-card-hover disabled-card">
                        <img src={a.icon} alt={a.tanim} title={a.tanim} />
                      </IonCard>
                    )}
                  </IonCol>
                ))}
            </IonRow>
          </IonGrid>
        </IonItem>
        {/* <ExploreContainer /> */}
      </IonContent>
    </IonPage>
  );
};

export default Home;
