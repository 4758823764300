import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonToast,
} from "@ionic/react";
import { printSharp } from "ionicons/icons";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import tahsilatService from "../../services/TahsilatService";
import userService from "../../services/UserService";
import Makbuz from "../entities/Makbuz";
import "./Page.css";

const MakbuzSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [yillar, setYillar] = useState<number[]>([]);
  const [donem, setDonem] = useState<number>();
  const [makbuzListesi, setMakbuzListesi] = useState<Makbuz[]>([]);
  const [expandedRows, setExpandedRows] = useState<Makbuz[]>([]);
  const loadingData = [
    { seri: "", makbuzNo: "", tutar: "" },
    { seri: "", makbuzNo: "", tutar: "" },
    { seri: "", makbuzNo: "", tutar: "" },
    { seri: "", makbuzNo: "", tutar: "" },
    { seri: "", makbuzNo: "", tutar: "" },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const loadingColumnArray = [
    { field: "seri", header: "seri" },
    { field: "makbuzNo", header: "Makbuz No" },
    { field: "tutar", header: "Tutar" },
  ];

  React.useEffect(() => {
    setTitle("Tahsilat Makbuz Sorgulama");
    setShowLoading(true);
    userService
      .getTahsilatYillar()
      .then((data: number[]) => setYillar(data))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  }, []);

  const getMakbuz = (event: any) => {
    event.preventDefault();
    if (!donem) {
      setShowToastMessage("Tahsilat Dönemi Seçiniz");
      return;
    }
    setLoading(true);
    userService
      .getMakbuz(donem)
      .then((data: Makbuz[]) => setMakbuzListesi(data))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setLoading(false));
  };

  const raporGoster = (tahsilatId: string, makbuzNo: number) => {
    setShowLoading(true);
    tahsilatService
      .makbuzYaz(tahsilatId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "tahsilat.makbuz.rapor.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const loadingBodyTemplate = (header: string) => {
    return <IonSkeletonText animated />;
  };

  const bodyTemplate = (
    header: string,
    field: string,
    rowData: any,
    func?: any
  ) => {
    return <IonText>{func ? func(rowData[field]) : rowData[field]}</IonText>;
  };

  const makbuzNoBodyTemplate = (tahsilatId: string, makbuzNo: number) => {
    return (
      <IonItem>
        {makbuzNo}
        <IonButton
          slot="end"
          fill="outline"
          title="Makbuz Yazdır"
          onClick={() => raporGoster(tahsilatId, makbuzNo)}
        >
          <IonIcon icon={printSharp}></IonIcon>
        </IonButton>
      </IonItem>
    );
  };

  const formatCurrency = (value: number | undefined) => {
    if (!value) {
      value = 0;
    }
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        suffix="₺"
        decimalScale={2}
        fixedDecimalScale
      />
    );
  };

  const formatDate = (value: any) => {
    if (!value) {
      return value;
    }
    return new Date(Date.parse(value)).toLocaleDateString();
  };

  const rowExpansionTemplate = (rowData: Makbuz) => {
    return (
      <IonCard>
        <IonCardContent>
          <DataTable
            value={rowData.detay}
            stripedRows
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
          >
            <Column
              field="seri"
              style={{ width: "3.6em" }}
              header="Seri No"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding ion-tet-center"
            ></Column>
            <Column
              field="makbuzNo"
              style={{ width: "5.9em" }}
              header="Makbuz No"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="tarih"
              style={{ width: "5.6em" }}
              header="Tarih"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="ion-text-nowrap no-padding"
              body={(rowData: Makbuz) => formatDate(rowData.tarih)}
            ></Column>
            <Column
              field="aciklama"
              header="Açıklama"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="tutar"
              style={{ width: "4.1em" }}
              header="Tutar"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: Makbuz) => formatCurrency(rowData.tutar)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
          </DataTable>
        </IonCardContent>
      </IonCard>
    );
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={2000}
          position="top"
        />
        <form
          onSubmit={(e) => {
            getMakbuz(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Tahsilat Dönemi</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="stacked">Tahsilat Dönemi</IonLabel>
                  <IonSelect
                    name="yilInput"
                    interface="popover"
                    value={donem}
                    placeholder="Seçiniz..."
                    onIonChange={(e) => setDonem(e.detail.value)}
                  >
                    <IonSelectOption value="">Seçiniz</IonSelectOption>
                    {yillar &&
                      yillar.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="yilInput"
                    interface="popover"
                    value={donem}
                    placeholder="Seçiniz..."
                    onIonChange={(e) => setDonem(e.detail.value)}
                  >
                    <IonSelectOption value="">Seçiniz</IonSelectOption>
                    {yillar &&
                      yillar.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <IonButton type="submit">Sorgula</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonCard>
            <IonCardContent className="datatable-responsive">
              {loading && (
                <DataTable
                  value={loadingData}
                  paginator
                  rows={10}
                  removableSort
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  {loadingColumnArray.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      body={() => loadingBodyTemplate(col.header)}
                      headerClassName="ion-text-center"
                    />
                  ))}
                </DataTable>
              )}
              {!loading && (
                <DataTable
                  value={makbuzListesi}
                  paginator
                  rows={10}
                  emptyMessage=""
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data as Makbuz[])}
                  rowExpansionTemplate={rowExpansionTemplate}
                  dataKey="id"
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  <Column
                    expander
                    style={{ width: "2.4em" }}
                    bodyClassName="sm-invisible"
                  />

                  <Column
                    field="seri"
                    header="Seri No"
                    sortable
                    headerClassName="ion-text-start ion-text-nowrap"
                    body={(rowData) => bodyTemplate("Seri No", "seri", rowData)}
                    bodyClassName="no-padding"
                  ></Column>
                  <Column
                    field="makbuzNo"
                    header="Makbuz No"
                    sortable
                    headerClassName="ion-text-start"
                    body={(rowData: Makbuz) =>
                      makbuzNoBodyTemplate(rowData.tahsilatId, rowData.makbuzNo)
                    }
                    bodyClassName="no-padding"
                  ></Column>
                  <Column
                    field="tutar"
                    header="Tutar"
                    sortable
                    body={(rowData) =>
                      bodyTemplate("Tutar", "tutar", rowData, formatCurrency)
                    }
                    bodyClassName="no-padding ion-text-end"
                    headerClassName="ion-text-end"
                  ></Column>
                </DataTable>
              )}
            </IonCardContent>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default MakbuzSorgu;
