import React, { useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ROLE } from "../enums/ROLE";

export interface IPrivateRouteProps extends RouteProps {
  redirectPath: string; // redirect path if don't authenticate route
  roles: Array<ROLE>;
  children: React.ReactNode;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  roles,
  children,
  path,
  redirectPath,
  ...rest
}) => {
  const user = sessionStorage.getItem("user");
  const isAuth = !!user && roles.includes(JSON.parse(user)?.detail?.role);

  useEffect(() => {
    console.log(`loading protected route '${path}' with component: `, children);
  }, [children, path]);

  return (
    <>
      {isAuth && <Route {...rest}>{children}</Route>}
      {!isAuth && <Redirect to={{ pathname: redirectPath }} />}
    </>
  );
};

export default PrivateRoute;
