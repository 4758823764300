import { KullaniciBilgiAdres } from "./KullaniciBilgiAdres";
import { KullaniciBilgiTel } from "./KullaniciBilgiTel";

export class KullaniciBilgiKurum {
  constructor(
    public id?: string,
    public sicilNo?: number,
    public kurumTuru?: string,
    public unvani?: string,
    public yetkiliAdiSoyadi?: string,
    public gorevi?: string,
    public kurulusYeri?: string,
    public kurulusTarihi?: string,
    public vergiDairesi?: string,
    public vergiNo?: string,
    public ticaretSicilNo?: string,
    public eposta?: string,
    public web?: string,
    public telBilgi?: KullaniciBilgiTel[],
    public adresBilgi?: KullaniciBilgiAdres[]
  ) {}
}
