export class KullaniciBilgiTel {
  constructor(
    public id: string,
    public tur: string,
    public alanKodu: string,
    public telNo: string,
    public dahiliNo: string,
    public ulkeKodu: string
  ) {}
}
