export class KullaniciBilgiAdres {
  constructor(
    public id: string,
    public ulke: string,
    public tur: string,
    public il: string,
    public ilce: string,
    public bucak: string,
    public koy: string,
    public mahalle: string,
    public caddeSokak: string,
    public kapiNo: string,
    public daireNo: string
  ) {}
}
