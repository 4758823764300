import {
  IonAvatar,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
} from "@ionic/react";
import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { KurumBilgileri } from "../ebelediye/entities/KurumBilgileri";
import "./Menu.css";
import Icon from "./Icon";
import { ROLE } from "../enums/ROLE";
import { Menu } from "../ebelediye/entities/menu/Menu";
import { AuthContext } from "../providers/authContext";

const MenuComponent: React.FC = () => {
  const { logo, kurumBilgileri } = React.useContext(AuthContext);

  const userStr = sessionStorage.getItem("user");
  const user = userStr && JSON.parse(userStr);
  const userHasRequiredRole =
    user && ROLE.User === user.detail?.role ? true : false;
  const isAuth = !!user && userHasRequiredRole;
  const menus: Array<Menu> = isAuth ? user.detail.menus : kurumBilgileri?.menus;
  const location = useLocation();
  const [aktifIndeks, setAktifIndeks] = useState(0);

  return (
    <IonMenu contentId="main" type="overlay" swipeGesture={false}>
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <IonGrid>
              <IonRow>
                <IonCol size="4">
                  {/* <img src={`data:image/jpeg;base64,${props.kurumLogo}`} */}
                  <img src={logo} alt="Logo" />
                </IonCol>
                <IonCol className="ion-padding-start">
                  <IonRow>
                    <i>{kurumBilgileri?.kisaTanim}</i>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-no-padding">
                      <hr color="secondary" className="ion-no-padding" />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-end ion-no-padding">
                      <IonNote className="ion-text-end ion-no-padding">
                        <i>
                          {kurumBilgileri?.kurumKodu.startsWith("35")
                            ? "Online İşlemler"
                            : kurumBilgileri?.kurumKodu.startsWith("48")
                            ? "e-Birlik İşlemleri"
                            : "e-Belediye İşlemleri"}
                        </i>
                      </IonNote>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonListHeader>

          <Accordion
            className="accordion-custom"
            activeIndex={aktifIndeks}
            onTabChange={(e) => setAktifIndeks(e.index)}
          >
            {menus &&
              menus.map((tab, index) => {
                if (
                  (kurumBilgileri?.eBelediyeUyelikTipi === 0 &&
                    tab.title.indexOf("Üyelik") < 0) ||
                  kurumBilgileri?.eBelediyeUyelikTipi === 1
                ) {
                  return (
                    <AccordionTab key={index} header={tab.title}>
                      {tab.menuItems.map((appPage, index) => {
                        return (
                          <IonMenuToggle key={index} autoHide={false}>
                            <IonItem
                              className={
                                location.pathname === appPage.url
                                  ? "selected"
                                  : ""
                              }
                              routerLink={appPage.url}
                              routerDirection="none"
                              lines="none"
                              detail={false}
                            >
                              <Icon
                                className="ion-no-margin ion-margin-end"
                                slot="start"
                                ios={appPage.iosIcon}
                                md={appPage.mdIcon}
                              />
                              <IonLabel>{appPage.title}</IonLabel>
                            </IonItem>
                          </IonMenuToggle>
                        );
                      })}
                    </AccordionTab>
                  );
                }
              })}
          </Accordion>
          {kurumBilgileri?.ebysLink && (
            <IonItem
              href={kurumBilgileri?.ebysLink}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                className="ion-no-margin ion-margin-end"
                slot="start"
                ios="documentLockOutline"
                md="documentLockOutline"
              />
              <IonLabel className="ion-padding-start" color="dark">
                Evrak Doğrulama İşlemi
              </IonLabel>
            </IonItem>
          )}
        </IonList>
        <IonItem routerLink="/page/GuvenlikUyarilari">
          <IonAvatar>
            <img src="assets/shield.png" alt="Güvenlik Uyarıları" />
          </IonAvatar>
          <IonLabel className="ion-padding-start" color="danger">
            <b>Güvenlik Uyarıları</b>
          </IonLabel>
        </IonItem>
        <IonItem routerLink="/page/KVKK">
          <IonAvatar>
            <img src="assets/shield.png" alt="Güvenlik Uyarıları" />
          </IonAvatar>
          <IonLabel className="ion-padding-start" color="danger">
            <b>K.V.K.K Aydınlatma Metni</b>
          </IonLabel>
        </IonItem>
      </IonContent>
      {/* <IonFooter className="footer-sm-visible">
        <div className="footer ion-padding ion-text-center center">
          <small>
            T.C. {kurumBilgileri?.kisaTanim} . Tel: {kurumBilgileri?.telefon} . Faks: {kurumBilgileri?.faks}<br />{kurumBilgileri?.adres} . {kurumBilgileri?.eposta}
          </small>
        </div>
      </IonFooter> */}
    </IonMenu>
  );
};

export default MenuComponent;
