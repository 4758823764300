import axios from "axios";
import OdenecekBorc from "../ebelediye/entities/OdenecekBorc";
import {
  ODENECEK_BORC_SORGU_URL,
  TAHSILAT_HAZIRLA_URL,
  TAHSILAT_KAYDET_URL,
  TAHSILAT_MAKBUZ_URL,
  EPOSTA_GONDER_URL,
} from "./constants";

/*
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token');
        config.headers.Authorization = token;
        return Promise.resolve(config);
    },
    (error) => {
        return Promise.reject(error);
    }
);
*/

class TahsilatService {
  getBorclar(tcNo: string, tip: string) {
    return axios
      .post(ODENECEK_BORC_SORGU_URL, { tcNo, tip })
      .then((res) => res.data);
  }

  tahsilatHazirla(
    tcNo: string,
    tip: number,
    genelToplam: number,
    selectedBorcs: OdenecekBorc[]
  ) {
    const token = sessionStorage.getItem("token");
    return axios
      .post(
        TAHSILAT_HAZIRLA_URL,
        { tcNo, tip, genelToplam, selectedBorcs },
        { headers: { Authorization: token } }
      )
      .then((res) => res.data);
  }

  post3DPaymentInfo(sanalPosUrl: string, data: URLSearchParams) {
    return axios
      .post(sanalPosUrl, data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => res.data);
  }

  tahsilatKaydet(match: any) {
    const token = sessionStorage.getItem("token");
    return axios
      .post(TAHSILAT_KAYDET_URL, match, { headers: { Authorization: token } })
      .then((res) => res.data);
  }

  makbuzYaz(tahsilatId: any) {
    return axios
      .get(`${TAHSILAT_MAKBUZ_URL}/${tahsilatId}`, { responseType: "blob" })
      .then((res) => res.data);
  }

  epostaGonder(email: string, tahsilatId: any) {
    return axios
      .post(`${EPOSTA_GONDER_URL}/${email}/${tahsilatId}`)
      .then((res) => res.data);
  }
}

const tahsilatService = new TahsilatService();
export default tahsilatService;
