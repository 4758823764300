import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router-dom";

import "./Page.css";
import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";

const AskidaBorcTahsilatBasarisiz: React.FC = () => {
  const { ErrMsg } = useParams<any>();

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle className="ion-text-sm-wrap">
            <IonItem>
              <IonLabel className="ion-padding-start">Tahsilat Sonucu</IonLabel>
            </IonItem>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem>
          <IonGrid>
            <IonRow className="ion-text-start ion-align-items-center">
              <IonCol size="1">
                <img src="assets/denied.png" />
              </IonCol>
              <IonCol>
                <IonLabel color="danger">{ErrMsg}</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default AskidaBorcTahsilatBasarisiz;
