import "@formatjs/intl-numberformat/locale-data/tr";
import "@formatjs/intl-numberformat/polyfill";
import {
  IonApp,
  IonContent,
  IonFooter,
  IonLoading,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import "primeicons/primeicons.css";
// import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css';
import { setupIonicReact } from "@ionic/react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import "./App.css";
import AilePaketi from "./askidayardim/pages/AilePaketi";
import AnneBebekPaketi from "./askidayardim/pages/AnneBebekPaketi";
import AskidaBorcTahsilatBasarisiz from "./askidayardim/pages/AskidaBorcTahsilatBasarisiz";
import AskidaBorcTahsilatTamam from "./askidayardim/pages/AskidaBorcTahsilatTamam";
import AskidaFatura from "./askidayardim/pages/AskidaFatura";
import EgitimPaketi from "./askidayardim/pages/EgitimPaketi";
import GidaPaketi from "./askidayardim/pages/GidaPaketi";
import Home from "./askidayardim/pages/Home";
import YakacakPaketi from "./askidayardim/pages/YakacakPaketi";
import YemekPaketi from "./askidayardim/pages/YemekPaketi";
import Menu from "./components/Menu";
import { KurumBilgileri } from "./ebelediye/entities/KurumBilgileri";
import AnaSayfa from "./ebelediye/pages/AnaSayfa";
import AraziDegerSorgu from "./ebelediye/pages/AraziDegerSorgu";
import ArsaDegerSorgu from "./ebelediye/pages/ArsaDegerSorgu";
import BinaAsinmaSorgu from "./ebelediye/pages/BinaAsinmaSorgu";
import BorcOdeme from "./ebelediye/pages/BorcOdeme";
import BorcOdemeUye from "./ebelediye/pages/BorcOdemeUye";
import BorcTahsilatTamam from "./ebelediye/pages/BorcTahsilatTamam";
import CevreGrupDereceSorgu from "./ebelediye/pages/CevreGrupDereceSorgu";
import CtvBildirimSorgu from "./ebelediye/pages/CtvBildirimSorgu";
import EmlakBildirimSorgu from "./ebelediye/pages/EmlakBildirimSorgu";
import GuvenlikUyarilari from "./ebelediye/pages/GuvenlikUyarilari";
import IlanBeyanSorgu from "./ebelediye/pages/IlanBeyanSorgu";
import BinaDegerSorgu from "./ebelediye/pages/InsaatMaliyetSorgu";
import IstekSikayetBildirim from "./ebelediye/pages/IstekSikayetBildirim";
import IstekSikayetBildirimUye from "./ebelediye/pages/IstekSikayetBildirimUye";
import IstekSikayetSorgu from "./ebelediye/pages/IstekSikayetSorgu";
import KararEncumenSorgu from "./ebelediye/pages/KararEncumenSorgu";
import KararMeclisSorgu from "./ebelediye/pages/KararMeclisSorgu";
import KullaniciBilgiGuncellemeIslem from "./ebelediye/pages/KullaniciBilgiGuncellemeIslem";
import KullaniciKisitliIslem from "./ebelediye/pages/KullaniciKisitliIslem";
import Logout from "./ebelediye/pages/Logout";
import MakbuzSorgu from "./ebelediye/pages/MakbuzSorgu";
import MemberWelcome from "./ebelediye/pages/MemberWelcome";
import Page from "./ebelediye/pages/Page";
import TahakkukSorgu from "./ebelediye/pages/TahakkukSorgu";
import TahsilatSorgu from "./ebelediye/pages/TahsilatSorgu";
import UyelikOnayi from "./ebelediye/pages/UyelikOnayi";
import YeniKisiUyelik from "./ebelediye/pages/YeniKisiUyelik";
import YeniKurumUyelik from "./ebelediye/pages/YeniKurumUyelik";
import sorgulamaService from "./services/SorgulamaService";
import TapuLogin from "./tapu/pages/Login";
import RayicBedelSorgu from "./tapu/pages/RayicBedelSorgu";
/* Theme variables */
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import { ROLE } from "./enums/ROLE";
import { AuthContext } from "./providers/authContext";
import "./theme/variables.css";
import KVKK from "./ebelediye/pages/KVKK";
import EdevletLogin from "./ebelediye/pages/EdevletLogin";
import EdevletTapuLogin from "./tapu/pages/EdevletLogin";
import BinaBildirim from "./ebelediye/pages/BinaBildirim";

setupIonicReact();

const App: React.FC<any> = () => {
  //  const userStr = sessionStorage.getItem("user");
  //  const user = userStr && JSON.parse(userStr);
  //  const userHasRequiredRole = user && ROLE.User === user.detail?.role ? true : false;
  //  const isAuth = !!user && userHasRequiredRole;
  const {
    authInfo,
    initialize,
    setTitle,
    setLogo,
    setUyelikTipi,
    kurumBilgileri,
    setKurumBilgileri,
  } = React.useContext(AuthContext);
  const [kurumLogo, setKurumLogo] = useState<any>();
  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;
    sorgulamaService
      .getKurumBilgileri()
      .then((data: any) => {
        if (isMounted.current) {
          const kb = data;
          setKurumBilgileri(kb);
          setTitle(kb.kisaTanim);
          setUyelikTipi(kb?.eBelediyeUyelikTipi);
          document.title =
            kb.kisaTanim +
            (kb.kurumKodu.startsWith("35")
              ? " - Online İşlemler"
              : kb.kurumKodu.startsWith("48")
              ? " - E-Birlik Hizmetleri"
              : " - E-Belediye Hizmetleri");
        }
      })
      .catch((err: any) => console.log(err));
    sorgulamaService
      .getKurumLogo()
      .then((data: any) => {
        const logo = "data:image/png;base64," + data;
        setKurumLogo(logo);
        setLogo(logo);
      })
      .catch((err: any) => console.log(err));
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    !authInfo?.initialized && (() => initialize())();
  }, [authInfo, initialize]);

  if (!authInfo || !authInfo.initialized) {
    return (
      <IonApp>
        <IonLoading isOpen={true} />
      </IonApp>
    );
  }
  return window.location.pathname.indexOf("tapu") !== -1 ? (
    <IonApp>
      <IonContent>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route
              path="/tapu/EdevletLogin"
              exact
              component={EdevletTapuLogin}
            />
            <Route exact path="/tapu/login">
              <TapuLogin />
            </Route>
            <PrivateRoute
              roles={[ROLE.Tapu]}
              exact
              path="/tapu"
              redirectPath="/tapu/login"
            >
              <RayicBedelSorgu />
            </PrivateRoute>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonContent>
    </IonApp>
  ) : window.location.pathname.indexOf("askida") !== -1 ? (
    <IonApp>
      <IonContent>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/askidayardim/anasayfa">
              <Home />
            </Route>
            <Route exact path="/askidayardim/askidafatura">
              <AskidaFatura />
            </Route>
            <Route exact path="/askidayardim/gidapaketi">
              <GidaPaketi />
            </Route>
            <Route exact path="/askidayardim/yemekpaketi">
              <YemekPaketi />
            </Route>
            <Route exact path="/askidayardim/ailepaketi">
              <AilePaketi />
            </Route>
            <Route exact path="/askidayardim/egitimpaketi">
              <EgitimPaketi />
            </Route>
            <Route exact path="/askidayardim/annebebekpaketi">
              <AnneBebekPaketi />
            </Route>
            <Route exact path="/askidayardim/yakacakpaketi">
              <YakacakPaketi />
            </Route>
            <Route exact path="/askidayardim/BorcTahsilatTamam">
              <AskidaBorcTahsilatTamam />
            </Route>
            <Route
              path="/askidayardim/BorcTahsilatBasarisiz/:ErrMsg"
              exact={true}
            >
              <AskidaBorcTahsilatBasarisiz />
            </Route>
            <Route exact path="/askidayardim">
              <Redirect to="/askidayardim/anasayfa" />
            </Route>
            <Route exact path="/askidayardim/faq">
              <Page />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonContent>
    </IonApp>
  ) : (
    <IonApp>
      <IonContent>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />

            <IonRouterOutlet id="main">
              <Route
                path="/page/BorcTahsilatTamam"
                exact
                component={BorcTahsilatTamam}
              />
              <Route path="/page/EdevletLogin" exact component={EdevletLogin} />
              <Route path="/page/KVKK" exact>
                <KVKK />
              </Route>
              {authInfo?.loggedIn === true ? (
                <>
                  <Route
                    path="/page/MemberWelcome"
                    exact
                    render={(props: any) => <MemberWelcome />}
                  />
                  <Route
                    path="/page/BorcOdemeUye"
                    exact
                    render={(props: any) => <BorcOdemeUye />}
                  />
                  <Route
                    path="/page/IstekSikayetBildirimUye"
                    exact
                    render={(props: any) => <IstekSikayetBildirimUye />}
                  />
                  <Route
                    path="/page/EmlakBildirimSorgu"
                    exact
                    render={(props: any) => <EmlakBildirimSorgu />}
                  />
                  <Route
                    path="/page/CtvBildirimSorgu"
                    exact
                    render={(props: any) => <CtvBildirimSorgu />}
                  />
                  <Route
                    path="/page/IlanBeyanSorgu"
                    exact
                    render={(props: any) => <IlanBeyanSorgu />}
                  />
                  <Route
                    path="/page/TahakkukSorgu"
                    exact
                    render={(props: any) => <TahakkukSorgu />}
                  />
                  <Route
                    path="/page/TahsilatSorgu"
                    exact
                    render={(props: any) => <TahsilatSorgu />}
                  />
                  <Route
                    path="/page/MakbuzSorgu"
                    exact
                    render={(props: any) => <MakbuzSorgu />}
                  />
                  <Route
                    path="/page/KullaniciBilgiGuncellemeIslem"
                    exact
                    render={(props: any) => <KullaniciBilgiGuncellemeIslem />}
                  />
                  <Route
                    path="/page/KullaniciKisitliIslem"
                    exact
                    render={(props: any) => <KullaniciKisitliIslem />}
                  />
                  <Route
                    path="/page/Logout"
                    exact
                    render={(props: any) => <Logout />}
                  />
                  <Route
                    path="/page/ArsaDegerSorgu"
                    exact
                    component={ArsaDegerSorgu}
                  />
                  <Route
                    path="/page/InsaatMaliyetSorgu"
                    exact
                    component={BinaDegerSorgu}
                  />
                  <Route path="/page/GuvenlikUyarilari" exact>
                    <GuvenlikUyarilari />
                  </Route>
                  <Route
                    path="/page/BinaAsinmaSorgu"
                    exact
                    component={BinaAsinmaSorgu}
                  />
                  <Route
                    path="/page/AraziDegerSorgu"
                    exact
                    component={AraziDegerSorgu}
                  />
                  <Route
                    path="/page/CevreGrupDereceSorgu"
                    exact
                    component={CevreGrupDereceSorgu}
                  />
                  <Route
                    path="/page/KararEncumenSorgu"
                    exact
                    component={KararEncumenSorgu}
                  />
                  <Route
                    path="/page/KararMeclisSorgu"
                    exact
                    component={KararMeclisSorgu}
                  />
                  <Route
                    path="/page/IstekSikayetSorgu"
                    exact
                    component={IstekSikayetSorgu}
                  />
                  <Route
                    path="/page/IstekSikayetBildirim"
                    exact
                    component={IstekSikayetBildirim}
                  />
                  <Route
                    path="/page/BinaBildirim"
                    exact
                    render={(props: any) => <BinaBildirim />}
                  />
                  <Route
                    path="/page/ArsaBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/AraziBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/CtvBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/IlanBeyan"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Redirect exact from="/" to="/page/MemberWelcome" />
                </>
              ) : (
                <>
                  <Route
                    path="/page/BorcOdeme/:Tip"
                    exact
                    strict
                    render={(props: any) => (
                      <BorcOdeme key={props.match.params.Tip} />
                    )}
                  />
                  <Route
                    path="/page/AnaSayfa"
                    exact
                    render={(props) => <AnaSayfa />}
                  />
                  <Route path="/page/Login" exact component={Login} />
                  <Route
                    path="/page/ArsaDegerSorgu"
                    exact
                    component={ArsaDegerSorgu}
                  />
                  <Route
                    path="/page/InsaatMaliyetSorgu"
                    exact
                    component={BinaDegerSorgu}
                  />
                  <Route path="/page/GuvenlikUyarilari" exact>
                    <GuvenlikUyarilari />
                  </Route>
                  <Route
                    path="/page/BinaAsinmaSorgu"
                    exact
                    component={BinaAsinmaSorgu}
                  />
                  <Route
                    path="/page/AraziDegerSorgu"
                    exact
                    component={AraziDegerSorgu}
                  />
                  <Route
                    path="/page/CevreGrupDereceSorgu"
                    exact
                    component={CevreGrupDereceSorgu}
                  />
                  <Route
                    path="/page/EmlakBildirimSorgu"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/CtvBildirimSorgu"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/IlanBeyanSorgu"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/BinaBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/ArsaBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/AraziBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/CtvBildirim"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/IlanBeyan"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/TahakkukSorgu"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/TahsilatSorgu"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/MakbuzSorgu"
                    exact
                    render={(props: any) => <Login />}
                  />
                  <Route
                    path="/page/KararEncumenSorgu"
                    exact
                    component={KararEncumenSorgu}
                  />
                  <Route
                    path="/page/KararMeclisSorgu"
                    exact
                    component={KararMeclisSorgu}
                  />
                  <Route
                    path="/page/IstekSikayetSorgu"
                    exact
                    component={IstekSikayetSorgu}
                  />
                  <Route
                    path="/page/IstekSikayetBildirim"
                    exact
                    component={IstekSikayetBildirim}
                  />
                  <Route path="/page/YeniKisiUyelik" exact>
                    <YeniKisiUyelik />
                  </Route>
                  <Route path="/page/YeniKurumUyelik" exact>
                    <YeniKurumUyelik />
                  </Route>
                  <Route
                    path="/page/UyelikOnayi/:OnayKodu"
                    exact
                    component={UyelikOnayi}
                  />
                  <Redirect exact from="/" to="/page/AnaSayfa" />
                </>
              )}
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonContent>
      <IonFooter className="sm-invisible footer-sm-invisible">
        <div className="footer ion-padding center">
          <span>
            T.C. {kurumBilgileri?.kisaTanim} . Tel: {kurumBilgileri?.telefon} .
            Faks: {kurumBilgileri?.faks}
            <br />
            {kurumBilgileri?.adres} . {kurumBilgileri?.eposta}
          </span>
          <span style={{ position: "absolute", right: 0 }}>
            <a
              href="http://www.belsas.com.tr/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/belsas.png"
                alt="Belsaş"
                style={{ paddingRight: "1em" }}
              />
            </a>
          </span>
        </div>
      </IonFooter>
    </IonApp>
  );
};

export default App;
