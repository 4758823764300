export const SERVER_URL = window.origin;
// export const SERVER_URL = "http://test.belsas.net:8444";
// export const SERVER_URL = "http://192.168.68.107:8093";
// export const SERVER_URL = "https://ebelediye.camliyayla.bel.tr:8093";
// export const SERVER_URL = "https://e-belediye.can.bel.tr";
// export const SERVER_URL = "https://e-belediye.kucukkuyu.bel.tr";
// export const SERVER_URL = "https://e.gordes.bel.tr";
// export const SERVER_URL = "https://ebelediye.yahsihan.bel.tr";

export const API_URL = SERVER_URL + "/api";
export const PRIVATE_API_URL = SERVER_URL + "/private";

export const LOGIN_URL = PRIVATE_API_URL + "/user/login";
export const EDEVLET_LOGIN = API_URL + "/kurum/edevletlogin";
export const EDEVLET_AUTHORIZATION_CONTROLLER =
  "${eDevletAuthorizationController}?response_type=code&client_id=${clientId}&state=${state}&scope=Kimlik-Dogrula&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${redirectUri}";
export const REGISTER_USER = API_URL + "/kurum/kullanicikaydet";
export const CONFIRM_USER = API_URL + "/kurum/kullanici.onayla.islem";

// Kurum bilgileri sorgulama
export const KURUM_BILGILERI_URL = API_URL + "/kurum/kurumbilgileri";
export const KURUM_LOGO_URL = API_URL + "/kurum/logo";

// Sorgulama servisleri
export const ARSA_DEGER_SORGU_URL = API_URL + "/sorgu/arsadeger";
export const ARSA_DEGER_DONEM_URL = API_URL + "/sorgu/arsadegerdonemler";
export const ARSA_DEGER_MAH_URL = API_URL + "/sorgu/arsadegermahalleler";
export const ARSA_DEGER_RAPOR_URL = API_URL + "/rapor/arsadegerrapor";

export const BINA_DEGER_DONEM_URL = API_URL + "/sorgu/binadegerdonemler";
export const BINA_KULLANIM_SEKILLERI_URL =
  API_URL + "/sorgu/binakullanimsekilleri";
export const BINA_INSAAT_SINIFLARI_URL = API_URL + "/sorgu/binainsaatsiniflari";
export const BINA_DEGER_SORGU_URL = API_URL + "/sorgu/binadeger";
export const INSAAT_MALIYET_RAPOR_URL =
  API_URL + "/rapor/binainsaatmaliyetrapor";

export const BINA_INSAAT_TURLERI_URL = API_URL + "/sorgu/binainsaatturleri";
export const BINA_ASINMA_PAYLARI_SORGU_URL =
  API_URL + "/sorgu/binaasinmapaylari";
export const BINA_ASINMA_PAYLARI_RAPOR_URL =
  API_URL + "/rapor/binaasinmapaylarirapor";

export const ARAZI_DEGER_DONEM_URL = API_URL + "/sorgu/arazidegerdonemler";
export const ARAZI_DEGER_SORGU_URL = API_URL + "/sorgu/arazideger";
export const ARAZI_DEGER_RAPOR_URL = API_URL + "/rapor/arazidegerrapor";

export const CEVRE_DONEM_URL = API_URL + "/sorgu/cevredonemler";
export const CEVRE_GRUP_DERECE_SORGU_URL = API_URL + "/sorgu/cevregrupderece";
export const CEVRE_GRUP_DERECE_RAPOR_URL =
  API_URL + "/rapor/cevregrupderecerapor";

export const KARAR_ENCUMEN_DONEM_URL = API_URL + "/sorgu/kararencumendonemler";
export const KARAR_ENCUMEN_SORGU_URL = API_URL + "/sorgu/kararencumen";
export const KARAR_ENCUMEN_RAPOR_URL = API_URL + "/rapor/kararencumenrapor";

export const KARAR_MECLIS_DONEM_URL = API_URL + "/sorgu/kararmeclisdonemler";
export const KARAR_MECLIS_SORGU_URL = API_URL + "/sorgu/kararmeclis";
export const KARAR_MECLIS_RAPOR_URL = API_URL + "/rapor/kararmeclisrapor";

export const ISTEK_SIKAYET_SORGU_URL = API_URL + "/sorgu/isteksikayet";
export const ISTEK_SIKAYET_KAYDET_URL = API_URL + "/kurum/isteksikayetkaydet";
export const KULLANICI_ISTEK_SIKAYET_KAYDET_URL =
  PRIVATE_API_URL + "/user/isteksikayetkaydet";
export const BASVURU_NEDENLERI_URL = API_URL + "/sorgu/basvurunedenleri";
export const KONU_BASLIKLARI_URL = API_URL + "/sorgu/konubasliklari";
export const MAHALLELER_URL = API_URL + "/sorgu/mahalleler";
export const SOKAKLAR_URL = API_URL + "/sorgu/sokaklar";

export const DOGUM_TARIHI_DOGRULAMA_URL = API_URL + "/sorgu/verifybirthdate";
export const KOD_ALMA_URL = API_URL + "/sorgu/sendcode";
export const KOD_DOGRULAMA_URL = API_URL + "/sorgu/verifycode";
export const MUKELLEF_KONTROL_SORGU_URL = API_URL + "/sorgu/mukellefkontrol";
export const BORC_SORGU_URL = API_URL + "/sorgu/borc";
export const BORC_SORGULAMA_RAPOR_URL = API_URL + "/rapor/borcrapor";

// Tahsilat servisleri
export const ODENECEK_BORC_SORGU_URL = API_URL + "/tahsilat/borclar";
export const TAHSILAT_HAZIRLA_URL = API_URL + "/tahsilat/tahsilatHazirla";
export const HASH_URL = API_URL + "/tahsilat/hashHazirla";
export const CARD_ENROLLMENT_URL = API_URL + "/tahsilat/cardenrollment/false";
export const TAHSILAT_KAYDET_URL = API_URL + "/tahsilat/tahsilatKaydet";
export const TAHSILAT_BASARILI = API_URL + "/tahsilat/tahsilatBasarili/false";
export const TAHSILAT_BASARISIZ = API_URL + "/tahsilat/tahsilatBasarisiz";
export const TAHSILAT_MAKBUZ_URL = API_URL + "/tahsilat/makbuzYaz";

export const EPOSTA_GONDER_URL = API_URL + "/tahsilat/epostaGonder";

export const EMLAK_BILDIRIM_SORGU_URL =
  PRIVATE_API_URL + "/bildirim/emlak.bildirim.sorgu";
export const CTV_BILDIRIM_SORGU_URL =
  PRIVATE_API_URL + "/bildirim/ctv.bildirim.sorgu";
export const ILAN_BEYAN_SORGU_URL =
  PRIVATE_API_URL + "/bildirim/ilan.beyan.sorgu";
export const ILAN_BEYAN_YILLARI_URL =
  PRIVATE_API_URL + "/bildirim/ilan.beyan.yillar";
export const BILDIRIM_RAPOR_URL = PRIVATE_API_URL + "/bildirim/bildirim.rapor";
export const TAHAKKUK_LISTESI_URL = PRIVATE_API_URL + "/user/tahakkuk.listesi";
export const TAHSILAT_LISTESI_URL = PRIVATE_API_URL + "/user/tahsilat.listesi";
export const MAKBUZ_LISTESI_URL = PRIVATE_API_URL + "/user/makbuz.listesi";
export const TAHAKKUK_RAPORU_URL = PRIVATE_API_URL + "/user/tahakkuk.rapor";
export const TAHSILAT_RAPORU_URL = PRIVATE_API_URL + "/user/tahsilat.rapor";
export const TAHAKKUK_YILLARI_URL = PRIVATE_API_URL + "/user/tahakkuk.yillar";
export const TAHSILAT_YILLARI_URL = PRIVATE_API_URL + "/user/tahsilat.yillar";
export const TAHAKKUK_GELIR_TURLERI_URL =
  PRIVATE_API_URL + "/user/tahakkuk.gelirturleri";
export const KULLANICI_ISTEK_SIKAYET_SORGU_URL =
  PRIVATE_API_URL + "/user/istek.sikayet";
export const KULLANICI_KISITLI_ISLEM =
  PRIVATE_API_URL + "/user/kullanici.kisitli.islem";
export const KULLANICI_BILGI_URL = PRIVATE_API_URL + "/user/kullanici.bilgi";
export const KULLANICI_BILGISI_KAYDET_URL =
  PRIVATE_API_URL + "/user/kullanici.bilgisi.kaydet";
export const KURUM_TURLERI_URL = PRIVATE_API_URL + "/user/kurum.tur";
export const TELEFON_TURLERI_URL = PRIVATE_API_URL + "/user/telefon.tur";
export const TELEFON_KAYDET_URL = PRIVATE_API_URL + "/user/telefon.kaydet";
export const TELEFON_SIL_URL = PRIVATE_API_URL + "/user/telefon.sil";
export const ADRES_TURLERI_URL = PRIVATE_API_URL + "/user/adres.tur";
export const ADRES_KAYDET_URL = PRIVATE_API_URL + "/user/adres.kaydet";
export const ADRES_SIL_URL = PRIVATE_API_URL + "/user/adres.sil";
export const SIFREMI_UNUTTUM_KISI = API_URL + "/kurum/sifremi.unuttum.kisi";
export const SIFREMI_UNUTTUM_KURUM = API_URL + "/kurum/sifremi.unuttum.kurum";

// Tapu Modülü Sorgulama servisleri
export const DONEM_BILGILERI_URL = PRIVATE_API_URL + "/tapu/donemler";
export const RAYIC_MAHALLELER_URL = PRIVATE_API_URL + "/tapu/mahalleler";
export const RAYIC_BEDEL_BILGILERI_URL = PRIVATE_API_URL + "/tapu/rayicbedel";
export const RAYIC_BILDIRIM_RAPOR_URL =
  PRIVATE_API_URL + "/tapu/rayic.bildirim.rapor";
