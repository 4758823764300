import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonToast,
  isPlatform,
} from "@ionic/react";
import { logInOutline, logOutOutline, person } from "ionicons/icons";
import React from "react";
import { AuthContext } from "../providers/authContext";
import "./Header.css";

const Header: React.FC = () => {
  const { authInfo, logOut, title, logo, uyelikTipi } =
    React.useContext(AuthContext);
  const navigation = useIonRouter();
  const [showToast] = useIonToast();

  const logout = async () => {
    await logOut();
    navigation.push("/", "root", "replace");
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle
            className={`ion-text-sm-wrap ion-no-padding ${
              isPlatform("ios") && "ion-padding-start ion-margin-start"
            }`}
          >
            <IonItem
            // routerLink={
            //   authInfo?.loggedIn === true
            //     ? "/page/MemberWelcome"
            //     : "/page/AnaSayfa"
            // }
            >
              <IonAvatar>
                <img src={logo} alt="Kurum Logosu" title="Kurum Logosu" />
              </IonAvatar>
              <IonLabel className="ion-padding-start">{title}</IonLabel>
            </IonItem>
          </IonTitle>
          {uyelikTipi !== 0 && !authInfo?.loggedIn === true && (
            <IonButton
              slot="end"
              title="Sisteme Giriş"
              color="medium"
              onClick={() => navigation.push("/page/Login")}
            >
              <IonLabel className="ion-hide-sm-down">Üye Girişi</IonLabel>
              <IonIcon slot="end" icon={logInOutline} />
            </IonButton>
          )}
          {uyelikTipi !== 0 && authInfo?.loggedIn === true && (
            <IonItem slot="end">
              <IonChip className="ion-hide-sm-down">
                <IonIcon icon={person} />
                <IonLabel>{authInfo?.user?.detail?.adSoyad}</IonLabel>
              </IonChip>
              <IonButton
                slot="end"
                title="Çıkmak için tıklayınız.."
                color="medium"
                onClick={() => logout()}
                size="default"
              >
                <IonLabel className="ion-hide-sm-down">Güvenli Çıkış</IonLabel>
                <IonIcon slot="end" icon={logOutOutline} />
              </IonButton>
            </IonItem>
          )}
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default React.memo(Header);
