import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useParams } from "react-router";
import ExploreContainer from "../../components/ExploreContainer";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import "./Page.css";

const Page: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const { name } = useParams<{ name: string }>();
  React.useEffect(() => {
    setTitle("Sayfa Bulunamadı");
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name={name} />
      </IonContent>
    </IonPage>
  );
};

export default Page;
