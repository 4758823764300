import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonToast,
} from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { downloadOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../providers/authContext";
import raporService from "../../services/RaporService";
import sorgulamaService from "../../services/SorgulamaService";
import ArsaDeger from "../entities/ArsaDeger";
import Mahalle from "../entities/Mahalle";
import ArsaDegerFilter from "../entities/sorgu/ArsaDegerFilter";
import "./Page.css";
import Header from "../../components/Header";

const ArsaDegerSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [sonuc, setSonuc] = useState<ArsaDeger[]>([]);
  const dt = useRef<any>(null);
  const loadingData = [
    { caddeSokak: "", aciklama: "", deger: 0 },
    { caddeSokak: "", aciklama: "", deger: 0 },
    { caddeSokak: "", aciklama: "", deger: 0 },
    { caddeSokak: "", aciklama: "", deger: 0 },
    { caddeSokak: "", aciklama: "", deger: 0 },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [yillar, setYillar] = useState<number[]>([]);
  const [yil, setYil] = useState<number>(0);
  const [mahalleler, setMahalleler] = useState<Mahalle[]>([]);
  const [mahalleId, setMahalle] = useState<string>();
  const [yilMessage, setYilMessage] = useState<string>();
  const [mahMessage, setMahMessage] = useState<string>();
  const [showToastMessage, setShowToastMessage] = useState<string>();

  React.useEffect(() => {
    setTitle("Arsa m² Değerleri Sorgulama");
    setShowLoading(true);
    sorgulamaService
      .getArsaDegerDonemler()
      .then((data: number[]) => setYillar(data))
      .finally(() => setShowLoading(false))
      .catch((err) => {
        console.log(err);
        setShowToastMessage(err);
      });
  }, []);

  useEffect(() => {
    setShowLoading(true);
    sorgulamaService
      .getArsaDegerMahalleler(yil)
      .then((data: Mahalle[]) => setMahalleler(data))
      .finally(() => setShowLoading(false));
  }, [yil]);

  const getArsaDeger = (event: any) => {
    event.preventDefault();
    let errorFound = false;
    setYilMessage(undefined);
    setMahMessage(undefined);
    if (yil <= 0) {
      setYilMessage("* Yıl alanını doldurunuz");
      errorFound = true;
    }
    if (!mahalleId) {
      setMahMessage("* Mahalle alanını doldurunuz");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }

    setLoading(true);
    const data = new ArsaDegerFilter(yil, mahalleId!);
    sorgulamaService
      .getArsaDegers(data)
      .then((data: ArsaDeger[]) => setSonuc(data))
      .finally(() => setLoading(false));
  };

  const formatCurrency = (value: number) => {
    if (!value) {
      value = 0;
    }
    return value.toFixed(2);
  };

  const caddeSokakBodyTemplate = (rowData: any) => {
    return <IonText className="p-column-content">{rowData.caddeSokak}</IonText>;
  };

  const aciklamaBodyTemplate = (rowData: any) => {
    return <IonText className="p-column-content">{rowData.aciklama}</IonText>;
  };

  const degerBodyTemplate = (rowData: any) => {
    return <IonText>{formatCurrency(rowData.deger)}</IonText>;
  };

  const exportPDF = () => {
    setShowLoading(true);
    raporService
      .arsaDegerYazdir(yil, mahalleId!)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "arsadeger.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        setShowToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  };

  const footer = (
    <div
      style={{ textAlign: "end", display: sonuc.length > 0 ? "block" : "none" }}
    >
      <IonButton type="button" color="success" onClick={exportPDF}>
        <IonIcon slot="start" icon={downloadOutline} />
        Yazdır
      </IonButton>
    </div>
  );

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonContent>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonToast
            isOpen={showToastMessage !== undefined}
            onDidDismiss={() => setShowToastMessage(undefined)}
            message={showToastMessage}
            duration={2000}
            position="top"
          />
          <form
            onSubmit={(e) => {
              getArsaDeger(e);
            }}
          >
            <IonGrid>
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>Yıl</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">Yıl</IonLabel>
                    <IonSelect
                      name="yilInput"
                      interface="popover"
                      value={yil}
                      onIonChange={(e) => setYil(e.detail.value)}
                    >
                      {yillar &&
                        yillar.map((val, i) => (
                          <IonSelectOption key={i} value={val}>
                            {val}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonSelect
                      name="yilInput"
                      interface="popover"
                      value={yil}
                      placeholder="Seçiniz..."
                      onIonChange={(e) => setYil(e.detail.value)}
                    >
                      {yillar &&
                        yillar.map((val, i) => (
                          <IonSelectOption key={i} value={val}>
                            {val}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {yilMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {yilMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>Mahalle</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">Mahalle</IonLabel>
                    <IonSelect
                      name="mahalleInput"
                      interface="popover"
                      value={mahalleId}
                      onIonChange={(e) => setMahalle(e.detail.value)}
                    >
                      {mahalleler &&
                        mahalleler.map((val: Mahalle, i) => (
                          <IonSelectOption key={i} value={val.id}>
                            {val.tanim}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonSelect
                      name="mahalleInput"
                      interface="popover"
                      value={mahalleId}
                      placeholder="Seçiniz..."
                      onIonChange={(e) => setMahalle(e.detail.value)}
                    >
                      {mahalleler &&
                        mahalleler.map((val: Mahalle, i) => (
                          <IonSelectOption key={i} value={val.id}>
                            {val.tanim}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {mahMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {mahMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <IonButton type="submit">Sorgula</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonCard>
              <IonCardContent className="datatable-responsive">
                {loading && (
                  <DataTable
                    value={loadingData}
                    paginator
                    rows={10}
                    removableSort
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="caddeSokak"
                      header="Cadde Sokak Adı"
                      body={(row) => <IonSkeletonText animated />}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="aciklama"
                      header="Açıklama"
                      body={(row) => <IonSkeletonText animated />}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="deger"
                      header="Rayiç Değeri"
                      sortable
                      body={(row) => <IonSkeletonText animated />}
                      headerClassName="ion-text-center"
                      bodyClassName="p-column-content-currency"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                )}
                {!loading && (
                  <DataTable
                    value={sonuc}
                    paginator
                    rows={10}
                    removableSort
                    footer={footer}
                    ref={dt}
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="caddeSokak"
                      header="Cadde Sokak Adı"
                      sortable
                      headerClassName="ion-text-center"
                      body={caddeSokakBodyTemplate}
                    ></Column>
                    <Column
                      field="aciklama"
                      header="Açıklama"
                      sortable
                      headerClassName="ion-text-center"
                      body={aciklamaBodyTemplate}
                    ></Column>
                    <Column
                      field="deger"
                      header="Rayiç Değeri"
                      sortable
                      body={degerBodyTemplate}
                      headerClassName="ion-text-center"
                      bodyClassName="p-column-content-currency"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                )}
              </IonCardContent>
            </IonCard>
          </form>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default ArsaDegerSorgu;
