import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import "./AnaSayfa.css";

const AnaSayfa: React.FC = () => {
  const { kurumBilgileri, setTitle } = React.useContext(AuthContext);

  React.useEffect(() => {
    kurumBilgileri && setTitle(kurumBilgileri.kisaTanim);
  }, [kurumBilgileri]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonList>
          {!kurumBilgileri?.kurumKodu.startsWith("35") &&
            !kurumBilgileri?.kurumKodu.startsWith("48") && (
              <IonItem>
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  navigation
                  loop
                  speed={400}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  <SwiperSlide className="fill">
                    <div className="image-container">
                      <img src="assets/001.jpg" />
                      <div className="text-block">
                        <h5>{kurumBilgileri?.kisaTanim}</h5>
                        <p>e-Belediye İşlemlerine Hoşgeldiniz.</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="fill">
                    <div className="image-container">
                      <img src="assets/002.jpg" />
                      <div className="text-block">
                        <p>BELEDİYE'YE AİT TÜM ÖDEMELERİNİZ</p>
                        <p>Emlak Vergileri (Bina, Arsa, Arazi),</p>
                        <p>Çevre Temizlik Vergisi,</p>
                        <p>İlan Reklam Vergisi,</p>
                        <p>Eğlence Vergisi,</p>
                        <p>Kira Ödemesi,</p>
                        <p>
                          Ve Diğer Tüm Ödemelerinizi Buradan Yapabilirsiniz !
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="fill">
                    <div className="image-container">
                      <img src="assets/003.jpg" />
                      <div className="text-block">
                        <p>SİSTEMİMİZE ÜYE OLUN</p>
                        <p>Böylece Beyan ve Bildirim Sorgulama,</p>
                        <p>Geçmiş Tahsilatları İzleme v.b.</p>
                        <p>İşlemlere 7 /24 Internet Üzerinden</p>
                        <p>Ücretsiz Olarak Ulaşabilirsiniz !</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="fill">
                    <div className="image-container">
                      <img src="assets/004.jpg" />
                      <div className="text-block">
                        <p>
                          <b>E-BELEDİYE İŞLEMLERİ GÜVENLİDİR</b>
                        </p>
                        <p>E-Belediye Sayfasından Yapacağınız</p>
                        <p>Tüm İşlemler Dünyaca Kabul Görmüş</p>
                        <p>128 Bit SSL Şifrelemesi Kullanan Güvenlik</p>
                        <p>Sertifikaları İle Yapılmaktadır.</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </IonItem>
            )}
          <IonItem>
            <IonCard>
              <IonCardHeader>
                <IonText>
                  Borç Sorgulama
                  {kurumBilgileri?.eBelediyeClientId && " / Ödeme"} İşlemleri
                </IonText>
              </IonCardHeader>
              <IonCardContent>
                <IonGrid>
                  {kurumBilgileri?.menus
                    .filter((m) => m.title.toLowerCase().startsWith("borç"))
                    .map((m, i) => (
                      <IonRow key={i}>
                        {m.menuItems.map((mi, j) => (
                          <IonCol key={j}>
                            <IonCard button routerLink={mi.url} color="primary">
                              <IonButton
                                size="default"
                                expand="full"
                                fill="solid"
                              >
                                {mi.title}
                              </IonButton>
                            </IonCard>
                          </IonCol>
                        ))}
                      </IonRow>
                    ))}
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </IonItem>
          <IonItem>
            <IonText>
              <h5>
                Yazdırma işlemleri için bilgisayarınızda PDF görüntüleyici
                olması gerekmektedir.
              </h5>
            </IonText>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(AnaSayfa);
