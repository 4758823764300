import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

interface HeaderProps {
  title: string | undefined;
  subtitle: string;
  logo: string | undefined;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { title, subtitle, logo } = props;

  return (
    <IonHeader translucent>
      <IonToolbar>
        <IonButtons slot="end">
          <IonBackButton />
        </IonButtons>
        <IonTitle className="ion-text-sm-wrap">
          <IonItem
            className="ion-margin-top ion-margin-bottom"
            routerLink="/askidayardim"
          >
            <img
              src={logo}
              alt="Kurum Logosu"
              title="Kurum Logosu"
              style={{ maxWidth: "3em", height: "auto" }}
            />
            <IonText
              className="ion-padding-start ion-hide-sm-down ion-text-capitalize"
              style={{ fontSize: "1.5em" }}
            >
              {title?.toLowerCase()} - {subtitle}
            </IonText>
            <IonText
              className="ion-padding-start ion-hide-sm-up ion-text-capitalize"
              style={{ fontSize: "1.5em" }}
            >
              {title?.toLowerCase()}
              <br />
              {subtitle}
            </IonText>
          </IonItem>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
