import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import React, { useState } from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import { Kisi } from "../entities/Kisi";
import "./Page.css";

const YeniKisiUyelik: React.FC = () => {
  const { kurumBilgileri, setTitle } = React.useContext(AuthContext);
  const [aggreed, setAggreed] = useState<boolean>(false);
  const [showAggrementModal, setShowAggrementModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [kisi, setKisi] = useState<Kisi>(new Kisi("", "", "", "", "", "", ""));

  React.useEffect(() => {
    setTitle("Kişi Bilgileri Girişi");
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!kisi.tcKimlikNo) {
      setShowToastMessage("Lütfen T.C. No Bilgisini Giriniz!");
      return;
    }
    if (kisi.tcKimlikNo.length !== 11) {
      setShowToastMessage("Lütfen T.C Kimlik No Bilgisini Doğru Giriniz!");
      return;
    }
    if ((!kisi.cuzdanSeri || !kisi.cuzdanNo) && !kisi.cuzdanSeriNo) {
      setShowToastMessage(
        "Lütfen Cüzdan Serisi ve Numarası Bilgilerini Giriniz!"
      );
      return;
    }
    if (!kisi.eposta) {
      setShowToastMessage("Lütfen Geçerli bir E-Posta Giriniz!");
      return;
    }
    if (kisi.eposta !== kisi.eposta2) {
      setShowToastMessage("Lütfen E-Posta Bilgilerinizi Aynı Giriniz!");
      return;
    }
    if (!kisi.cepTel) {
      setShowToastMessage("Lütfen Cep Telefonu Bilgisini Giriniz!");
      return;
    }
    if (!aggreed) {
      setShowToastMessage(
        "Lütfen üyelik sözleşmesi onay kutusunu işaretleyiniz !"
      );
      return;
    }
    setShowLoading(true);
    userService
      .registerUser(kisi)
      .then((msg) => {
        setKisi(new Kisi("", "", "", "", "", "", ""));
        setShowToastMessage(msg);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={15000}
          position="top"
          buttons={[
            {
              text: "Tamam",
              role: "cancel",
            },
          ]}
        />
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>T.C. Kimlik No</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">T.C. Kimlik No</IonLabel>
                  <IonInput
                    name="tcNoInput"
                    value={kisi.tcKimlikNo}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, tcKimlikNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="tcNoInput"
                    value={kisi.tcKimlikNo}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, tcKimlikNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Cüzdan Seri</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Cüzdan Seri</IonLabel>
                  <IonInput
                    name="cuzdanSeriInput"
                    value={kisi.cuzdanSeri}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, cuzdanSeri: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="cuzdanSeriInput"
                    value={kisi.cuzdanSeri}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, cuzdanSeri: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Cüzdan No</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Cüzdan No</IonLabel>
                  <IonInput
                    name="cuzdanNoInput"
                    value={kisi.cuzdanNo}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, cuzdanNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="cuzdanNoInput"
                    value={kisi.cuzdanNo}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, cuzdanNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Cüzdan Seri No (T.C. Kimlik Kartı)</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">
                    Cüzdan Seri No (T.C. Kimlik Kartı)
                  </IonLabel>
                  <IonInput
                    name="cuzdanSeriNoInput"
                    value={kisi.cuzdanSeriNo}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, cuzdanSeriNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="cuzdanSeriNoInput"
                    value={kisi.cuzdanSeriNo}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, cuzdanSeriNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>E-Posta</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">E-Posta</IonLabel>
                  <IonInput
                    name="epostaInput"
                    value={kisi.eposta}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, eposta: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="epostaInput"
                    value={kisi.eposta}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, eposta: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>E-Posta (Tekrar)</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">E-Posta (Tekrar)</IonLabel>
                  <IonInput
                    name="eposta2Input"
                    value={kisi.eposta2}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, eposta2: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="eposta2Input"
                    value={kisi.eposta2}
                    onIonChange={(e) =>
                      setKisi({ ...kisi, eposta2: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Cep Telefonu</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="stacked" className="ion-padding-bottom">
                    Cep Telefonu
                  </IonLabel>
                  <InputMask
                    id="phone"
                    mask="(999)-999 99 99"
                    unmask={true}
                    placeholder="(599)-999 99 99"
                    value={kisi.cepTel}
                    size={15}
                    onChange={(e) => setKisi({ ...kisi, cepTel: e.value })}
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <InputMask
                    id="phone"
                    mask="(999)-999 99 99"
                    unmask={true}
                    placeholder="(599)-999 99 99"
                    value={kisi.cepTel}
                    size={15}
                    onChange={(e) => setKisi({ ...kisi, cepTel: e.value })}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="10" sizeSm="5" offset="1" offsetSm="4">
                <IonItem>
                  <Button
                    type="button"
                    className="p-button-link"
                    onClick={() => setShowAggrementModal(true)}
                  >
                    Üyelik sözleşmesini okudum kabul ediyorum.
                  </Button>
                  <IonCheckbox
                    color="primary"
                    checked={aggreed}
                    slot="start"
                    onIonChange={(e) => setAggreed(e.detail.checked)}
                  ></IonCheckbox>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="5">
                <IonButton type="submit">Gönder</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <IonModal
          isOpen={showAggrementModal}
          canDismiss
          className="disclaimer-modal"
          onDidDismiss={() => setShowAggrementModal(false)}
        >
          <IonContent className="ion-text-center">
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  İNTERNET HİZMETLERİ KULLANIM SÖZLEŞMESİ
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <p style={{ textAlign: "justify" }}>
                  <span style={{ textAlign: "justify" }}>
                    İnternet ortamında {kurumBilgileri?.kisaTanim}'ne,
                    tarafımdan verilen bilgilerimin doğru olduğunu, bu bilgilere
                    göre internet ortamında {kurumBilgileri?.kisaTanim}{" "}
                    bilgisayar sistemindeki kişisel bilgilerimi sorgulama ve
                    diğer işlemler için {kurumBilgileri?.kisaTanim} tarafından
                    verilen ve tarafımca değiştirilecek olan kişisel şifrenin
                    korunması ile internet üzerinden kredi kartı ile yapacağım
                    işlemlerde her türlü riskin tarafıma ait olduğunu,{" "}
                    {kurumBilgileri?.kisaTanim}'nin unutulmuş, çalınmış veya
                    kaybedilmiş olan kullanıcı kodu ve kişisel şifremden,
                    bunların üçüncü kişiler tarafından kullanılmasından ve
                    sonuçlarından hiçbir şekilde sorumlu olmadığını kabul
                    ediyorum.
                  </span>
                </p>
              </IonCardContent>
            </IonCard>
            <IonButton onClick={() => setShowAggrementModal(false)}>
              Tamam
            </IonButton>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default YeniKisiUyelik;
