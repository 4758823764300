export const API_URL = window.origin + "/api";
// export const API_URL = "http://ebelediye:8093/api";
// export const API_URL = "https://e-belediye.can.bel.tr/api";
// export const API_URL = "https://webportal.canakkaleayvacik.bel.tr/api";
// export const API_URL = "/ebelediye/api";

// Kurum bilgileri sorgulama
export const KURUM_BILGILERI_URL = API_URL + "/kurum/kurumbilgileri";
// export const KURUM_LOGO_URL = API_URL + "/kurum/logo";

// Sorgulama servisleri
export const YARDIM_BILGILERI_URL = API_URL + "/askidayardim/yardimpaketleri";
export const ASKIDA_FATURALAR_URL = API_URL + "/askidayardim/askidafaturalar";
export const ASKIDA_FATURA_ISTATISTIK_URL =
  API_URL + "/askidayardim/askidafaturaistatistik";
export const RAMAZAN_PAKETLERI_URL =
  API_URL + "/askidayardim/yardimpaketleri/1";
export const YEMEK_PAKETLERI_URL = API_URL + "/askidayardim/yardimpaketleri/2";
export const AILE_PAKETLERI_URL = API_URL + "/askidayardim/yardimpaketleri/3";
export const EGITIM_PAKETLERI_URL = API_URL + "/askidayardim/yardimpaketleri/4";
export const ANNEBEBEK_PAKETLERI_URL =
  API_URL + "/askidayardim/yardimpaketleri/5";
export const YAKACAK_PAKETLERI_URL =
  API_URL + "/askidayardim/yardimpaketleri/6";
export const ASKIDA_YARDIM_ISTATISTIK_URL =
  API_URL + "/askidayardim/askidayardimistatistik";

// Tahsilat servisleri
export const TAHSILAT_HAZIRLA_URL = API_URL + "/tahsilat/tahsilatHazirla";
export const HASH_URL = API_URL + "/tahsilat/hashHazirla";
export const TAHSILAT_BASARILI = API_URL + "/tahsilat/tahsilatBasarili";
export const TAHSILAT_BASARISIZ = API_URL + "/tahsilat/tahsilatBasarisiz";
