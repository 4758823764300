import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import Tahakkuk from "../entities/Tahakkuk";
import "./Page.css";

const TahakkukSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [yillar, setYillar] = useState<number[]>([]);
  const [donem, setDonem] = useState<number>();
  const [gelirTurleri, setGelirTurleri] = useState<string[]>([]);
  const [gelirTur, setGelirTur] = useState<string>();
  const [tahakkukListesi, setTahakkukListesi] = useState<Tahakkuk[]>([]);
  const [expandedRows, setExpandedRows] = useState<Tahakkuk[]>([]);
  const loadingData = [
    { yil: "", tahakkukTarihi: "", tur: "", tutar: "" },
    { yil: "", tahakkukTarihi: "", tur: "", tutar: "" },
    { yil: "", tahakkukTarihi: "", tur: "", tutar: "" },
    { yil: "", tahakkukTarihi: "", tur: "", tutar: "" },
    { yil: "", tahakkukTarihi: "", tur: "", tutar: "" },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const loadingColumnArray = [
    { field: "yil", header: "Dönem" },
    { field: "tahakkukTarihi", header: "Tahakkuk Tarihi" },
    { field: "tur", header: "Gelir Türü" },
    { field: "tutar", header: "Tutar" },
  ];

  React.useEffect(() => {
    setTitle("Tahakkuk Sorgulama");
    setShowLoading(true);
    userService
      .getTahakkukYillar()
      .then((data: number[]) => setYillar(data))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));

    userService
      .getTahakkukGelirTurleri()
      .then((data: string[]) => setGelirTurleri(data))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  }, []);

  const getTahakkuk = (event: any) => {
    event.preventDefault();
    setLoading(true);
    userService
      .getTahakkuk(donem, gelirTur)
      .then((data: Tahakkuk[]) => setTahakkukListesi(data))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setLoading(false));
  };

  const raporGoster = () => {
    setShowLoading(true);
    userService
      .tahakkukRaporuYazdir(donem, gelirTur)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "tahakkuk.rapor.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const loadingBodyTemplate = (header: string) => {
    return <IonSkeletonText animated />;
  };

  const bodyTemplate = (
    header: string,
    field: string,
    rowData: any,
    func?: any
  ) => {
    return <IonText>{func ? func(rowData[field]) : rowData[field]}</IonText>;
  };

  const formatCurrency = (value: number | undefined) => {
    if (!value) {
      value = 0;
    }
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        suffix="₺"
        decimalScale={2}
        fixedDecimalScale
      />
    );
  };

  const formatDate = (value: any) => {
    if (!value) {
      return value;
    }
    return new Date(Date.parse(value)).toLocaleDateString();
  };

  const rowExpansionTemplate = (rowData: Tahakkuk) => {
    return (
      <IonCard>
        <IonCardContent>
          <DataTable
            value={rowData.detay}
            stripedRows
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
          >
            <Column
              field="yil"
              style={{ width: "3.6em" }}
              header="Dönem"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding ion-tet-center"
            ></Column>
            <Column
              field="tur"
              style={{ width: "5.9em" }}
              header="Tür"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="tahakkukTarihi"
              style={{ width: "5.6em" }}
              header="Tahak. Tar."
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="ion-text-nowrap no-padding"
              body={(rowData: Tahakkuk) => formatDate(rowData.tahakkukTarihi)}
            ></Column>
            <Column
              field="aciklama"
              header="Açıklama"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="no-padding"
            ></Column>
            <Column
              field="vadeTarihi"
              style={{ width: "4.6em" }}
              header="Vade Tarihi"
              headerClassName="ion-text-start ion-text-nowrap"
              bodyClassName="ion-text-nowrap no-padding"
              body={(rowData: Tahakkuk) => formatDate(rowData.vadeTarihi)}
            ></Column>
            <Column
              field="tutar"
              style={{ width: "4.1em" }}
              header="Tutar"
              headerClassName="ion-text-end ion-text-nowrap"
              body={(rowData: Tahakkuk) => formatCurrency(rowData.tutar)}
              bodyClassName="no-padding ion-text-end"
            ></Column>
          </DataTable>
        </IonCardContent>
      </IonCard>
    );
  };

  const footer = (
    <IonToolbar>
      <IonTitle className="ion-text-sm-wrap">
        <IonItem>
          {tahakkukListesi?.length > 0 && (
            <IonButton slot="end" size="default" onClick={() => raporGoster()}>
              Yazdır
            </IonButton>
          )}
        </IonItem>
      </IonTitle>
    </IonToolbar>
  );

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={2000}
          position="top"
        />
        <form
          onSubmit={(e) => {
            getTahakkuk(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Tahakkuk Dönemi</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="stacked">Tahakkuk Dönemi</IonLabel>
                  <IonSelect
                    name="yilInput"
                    interface="popover"
                    value={donem}
                    placeholder="Seçiniz..."
                    onIonChange={(e) => setDonem(e.detail.value)}
                  >
                    <IonSelectOption value="">Seçiniz</IonSelectOption>
                    {yillar &&
                      yillar.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="yilInput"
                    interface="popover"
                    value={donem}
                    placeholder="Seçiniz..."
                    onIonChange={(e) => setDonem(e.detail.value)}
                  >
                    <IonSelectOption value="">Seçiniz</IonSelectOption>
                    {yillar &&
                      yillar.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Gelir Türü</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="stacked">Gelir Türü</IonLabel>
                  <IonSelect
                    name="turInput"
                    interface="popover"
                    value={gelirTur}
                    placeholder="Seçiniz..."
                    onIonChange={(e) => setGelirTur(e.detail.value)}
                  >
                    <IonSelectOption value="">Seçiniz</IonSelectOption>
                    {gelirTurleri &&
                      gelirTurleri.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="turInput"
                    interface="popover"
                    value={gelirTur}
                    placeholder="Seçiniz..."
                    onIonChange={(e) => setGelirTur(e.detail.value)}
                  >
                    <IonSelectOption value="">Seçiniz</IonSelectOption>
                    {gelirTurleri &&
                      gelirTurleri.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <IonButton type="submit">Sorgula</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonCard>
            <IonCardContent className="datatable-responsive">
              {loading && (
                <DataTable
                  value={loadingData}
                  paginator
                  rows={10}
                  removableSort
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  {loadingColumnArray.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      body={() => loadingBodyTemplate(col.header)}
                      headerClassName="ion-text-center"
                    />
                  ))}
                </DataTable>
              )}
              {!loading && (
                <DataTable
                  value={tahakkukListesi}
                  paginator
                  rows={10}
                  emptyMessage=""
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data as Tahakkuk[])}
                  rowExpansionTemplate={rowExpansionTemplate}
                  dataKey="id"
                  footer={footer}
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  <Column
                    expander
                    style={{ width: "2.4em" }}
                    bodyClassName="sm-invisible"
                  />

                  <Column
                    field="yil"
                    header="Dönem"
                    sortable
                    headerClassName="ion-text-start ion-text-nowrap"
                    body={(rowData) => bodyTemplate("Dönem", "yil", rowData)}
                    bodyClassName="no-padding"
                  ></Column>
                  <Column
                    field="tahakkukTarihi"
                    header="Tahakkuk Tarihi"
                    sortable
                    headerClassName="ion-text-start ion-text-nowrap"
                    body={(rowData) =>
                      bodyTemplate(
                        "Tahakkuk Tarihi",
                        "tahakkukTarihi",
                        rowData,
                        formatDate
                      )
                    }
                    bodyClassName="no-padding ion-text-nowrap"
                  ></Column>
                  <Column
                    field="tur"
                    header="Gelir Türü"
                    sortable
                    headerClassName="ion-text-start"
                    body={(rowData) =>
                      bodyTemplate("Gelir Türü", "tur", rowData)
                    }
                    bodyClassName="no-padding"
                  ></Column>
                  <Column
                    field="tutar"
                    header="Tutar"
                    sortable
                    body={(rowData) =>
                      bodyTemplate("Tutar", "tutar", rowData, formatCurrency)
                    }
                    bodyClassName="no-padding ion-text-end"
                    headerClassName="ion-text-end"
                  ></Column>
                </DataTable>
              )}
            </IonCardContent>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default TahakkukSorgu;
