import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonToast,
} from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import parse from "html-react-parser";

import { downloadOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import raporService from "../../services/RaporService";
import sorgulamaService from "../../services/SorgulamaService";
import KararEncumen from "../entities/KararEncumenMeclis";
import "./Page.css";

const KararEncumenSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [sonuc, setSonuc] = useState<KararEncumen[]>([]);
  const dt = useRef<any>(null);
  const loadingData = [
    { kararNo: "", tarih: "", oturumNo: "", icerik: "" },
    { kararNo: "", tarih: "", oturumNo: "", icerik: "" },
    { kararNo: "", tarih: "", oturumNo: "", icerik: "" },
    { kararNo: "", tarih: "", oturumNo: "", icerik: "" },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [yillar, setYillar] = useState<number[]>([]);
  const [yil, setYil] = useState<number>(0);
  const [yilMessage, setYilMessage] = useState<string>();
  const [icerik, setIcerik] = useState<string>();
  const [showToastMessage, setShowToastMessage] = useState<string>();

  React.useEffect(() => {
    setTitle("Encümen Kararları Sorgulama");
    setShowLoading(true);
    sorgulamaService
      .getKararEncumenDonemler()
      .then((data: number[]) => setYillar(data))
      .catch((err) => {
        console.log("error on setYillar on getKararEncumenDonemler: ", err);
        setShowToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  }, []);

  const getKararEncumen = (event: any) => {
    event.preventDefault();
    let errorFound = false;
    setYilMessage(undefined);
    if (yil <= 0) {
      setYilMessage("* Karar Dönemi alanını doldurunuz");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }

    setLoading(true);
    sorgulamaService
      .getKararEncumenler(yil, icerik)
      .then((data: KararEncumen[]) => setSonuc(data))
      .catch((err) => {
        console.log("error on setSonuc on getKararEncumenler: ", err);
        setShowToastMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const loadingBodyTemplate = () => {
    return <IonSkeletonText animated />;
  };
  const kararNoBodyTemplate = (rowData: any) => {
    return <IonText className="p-column-content">{rowData.kararNo}</IonText>;
  };

  const kararTarihiBodyTemplate = (rowData: any) => {
    return <IonText>{new Date(rowData.tarih).toLocaleDateString()}</IonText>;
  };

  const oturumNoBodyTemplate = (rowData: any) => {
    return <IonText>{rowData.oturumNo}</IonText>;
  };

  const kararIcerikBodyTemplate = (rowData: any) => {
    return <IonText>{rowData.icerik ? parse(rowData.icerik) : "-"}</IonText>;
  };

  const exportPDF = () => {
    setShowLoading(true);
    raporService
      .kararEncumenYazdir(yil, icerik)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "kararencumen.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        setShowToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  };

  const footer = (
    <div
      style={{ textAlign: "end", display: sonuc.length > 0 ? "block" : "none" }}
    >
      <IonButton type="button" color="success" onClick={exportPDF}>
        <IonIcon slot="start" icon={downloadOutline} />
        Yazdır
      </IonButton>
    </div>
  );

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonContent>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonToast
            isOpen={showToastMessage !== undefined}
            onDidDismiss={() => setShowToastMessage(undefined)}
            message={showToastMessage}
            duration={2000}
            position="top"
          />
          <form
            onSubmit={(e) => {
              getKararEncumen(e);
            }}
          >
            <IonGrid>
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>Karar Dönemi</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">Karar Dönemi</IonLabel>
                    <IonSelect
                      name="yilInput"
                      interface="popover"
                      value={yil}
                      onIonChange={(e) => setYil(e.detail.value)}
                    >
                      {yillar &&
                        yillar.map((val, i) => (
                          <IonSelectOption key={i} value={val}>
                            {val}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonSelect
                      name="yilInput"
                      interface="popover"
                      value={yil}
                      placeholder="Seçiniz..."
                      onIonChange={(e) => setYil(e.detail.value)}
                    >
                      {yillar &&
                        yillar.map((val, i) => (
                          <IonSelectOption key={i} value={val}>
                            {val}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {yilMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {yilMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>İçerik</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">İçerik</IonLabel>
                    <IonInput
                      name="icerikInput"
                      value={icerik}
                      onIonChange={(e) => setIcerik(e.detail.value!)}
                      clearInput
                    />
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonInput
                      name="icerikInput"
                      value={icerik}
                      placeholder="İçerik..."
                      onIonChange={(e) => setIcerik(e.detail.value!)}
                      clearInput
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <IonButton type="submit">Sorgula</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonCard>
              <IonCardContent className="datatable-responsive">
                {loading && (
                  <DataTable
                    value={loadingData}
                    paginator
                    rows={10}
                    removableSort
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="kararNo"
                      header="Karar No"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="tarih"
                      header="Karar Tarihi"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="oturumNo"
                      header="Oturum No"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="icerik"
                      header="Karar İçerik"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                  </DataTable>
                )}
                {!loading && (
                  <DataTable
                    value={sonuc}
                    paginator
                    rows={10}
                    removableSort
                    footer={footer}
                    ref={dt}
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="kararNo"
                      header="Karar No"
                      sortable
                      headerClassName="ion-text-center"
                      body={kararNoBodyTemplate}
                    ></Column>
                    <Column
                      field="tarih"
                      header="Karar Tarihi"
                      sortable
                      headerClassName="ion-text-center"
                      body={kararTarihiBodyTemplate}
                    ></Column>
                    <Column
                      field="oturumNo"
                      header="Oturum No"
                      sortable
                      headerClassName="ion-text-center"
                      body={oturumNoBodyTemplate}
                    ></Column>
                    <Column
                      field="icerik"
                      header="Karar İçerik"
                      sortable
                      body={kararIcerikBodyTemplate}
                      headerClassName="ion-text-center"
                    ></Column>
                  </DataTable>
                )}
              </IonCardContent>
            </IonCard>
          </form>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default KararEncumenSorgu;
