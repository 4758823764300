import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonText,
} from "@ionic/react";
import { checkmark, send } from "ionicons/icons";
import React from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import "./Page.css";

const MemberWelcome: React.FC = () => {
  const { authInfo, setTitle } = React.useContext(AuthContext);

  React.useEffect(() => {
    setTitle("Online Belediye İşlemleri");
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonItem color="light">
              <IonIcon icon={send} slot="start" />
              <IonText>
                Sayın {authInfo?.user?.detail.adSoyad}, hoşgeldiniz.
              </IonText>
            </IonItem>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonListHeader>
                e-Belediyecilik işlemleri kapsamında;
              </IonListHeader>
              <IonItem>
                <IonIcon icon={checkmark} slot="start" />
                <IonText>
                  İnternet üzerinden Tahakkuk, Tahsilat ve Borç bilgilerinizi
                  görebilirsiniz.
                </IonText>
              </IonItem>
              <IonItem>
                <IonIcon icon={checkmark} slot="start" />
                <IonText>
                  Kredi kartı ile internet üzerinden son derece güvenli bir
                  şekilde borcunuzu ödeyebilirsiniz.
                </IonText>
              </IonItem>
              <IonItem>
                <IonIcon icon={checkmark} slot="start" />
                <IonText>
                  Emlak , Çevre, İlan & Reklam Beyanlarınızı görebilir veya
                  isterseniz bu beyanlarınızın çıktısını alabilirsiniz.
                </IonText>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default MemberWelcome;
