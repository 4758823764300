import axios from "axios";
import {
  CONFIRM_USER,
  LOGIN_URL,
  REGISTER_USER,
  EMLAK_BILDIRIM_SORGU_URL,
  CTV_BILDIRIM_SORGU_URL,
  BILDIRIM_RAPOR_URL,
  ILAN_BEYAN_SORGU_URL,
  ILAN_BEYAN_YILLARI_URL,
  TAHAKKUK_LISTESI_URL,
  TAHAKKUK_RAPORU_URL,
  TAHAKKUK_YILLARI_URL,
  TAHAKKUK_GELIR_TURLERI_URL,
  TAHSILAT_YILLARI_URL,
  TAHSILAT_RAPORU_URL,
  TAHSILAT_LISTESI_URL,
  MAKBUZ_LISTESI_URL,
  KULLANICI_ISTEK_SIKAYET_SORGU_URL,
  KULLANICI_KISITLI_ISLEM,
  SIFREMI_UNUTTUM_KISI,
  SIFREMI_UNUTTUM_KURUM,
  KULLANICI_BILGI_URL,
  TELEFON_TURLERI_URL,
  TELEFON_KAYDET_URL,
  TELEFON_SIL_URL,
  ADRES_SIL_URL,
  ADRES_KAYDET_URL,
  ADRES_TURLERI_URL,
  KULLANICI_BILGISI_KAYDET_URL,
  KURUM_TURLERI_URL,
  ISTEK_SIKAYET_KAYDET_URL,
  KULLANICI_ISTEK_SIKAYET_KAYDET_URL,
  EDEVLET_LOGIN,
} from "../services/constants";
import { User } from "../ebelediye/entities/User";
import { Kisi } from "../ebelediye/entities/Kisi";
import { Kurum } from "../ebelediye/entities/Kurum";
import { KullaniciTip } from "../enums/KullaniciTip";
import { KullaniciBilgiTel } from "../ebelediye/entities/KullaniciBilgiTel";
import { KullaniciBilgiAdres } from "../ebelediye/entities/KullaniciBilgiAdres";
import { KullaniciBilgiKisi } from "../ebelediye/entities/KullaniciBilgiKisi";
import { KullaniciBilgiKurum } from "../ebelediye/entities/KullaniciBilgiKurum";
import IstekSikayet from "../ebelediye/entities/IstekSikayet";

class UserService {
  login(user: User) {
    return axios
      .post(
        LOGIN_URL,
        { tanim: user.username },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => {
        user.detail = res.data;
        window.sessionStorage.setItem("user", JSON.stringify(user));
      });
  }

  eDevletLogin(code: string | null) {
    return axios.post(`${EDEVLET_LOGIN}/${code}`).then((res) => res.data);
  }

  registerUser(kisi: Kisi) {
    return axios.post(`${REGISTER_USER}/0`, kisi).then((res) => res.data);
  }

  registerKurum(kurum: Kurum) {
    return axios.post(`${REGISTER_USER}/1`, kurum).then((res) => res.data);
  }

  confirmUser(onayKodu: string) {
    return axios.post(`${CONFIRM_USER}/${onayKodu}`).then((res) => res.data);
  }
  getEmlakBildirim(
    bildirimTipi: string | undefined,
    bildirimTuru: string | undefined
  ) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        `${EMLAK_BILDIRIM_SORGU_URL}`,
        { sicilNo: user.detail.sicilNo, bildirimTipi, bildirimTuru },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getCtvBildirim(sicilNo: number | undefined) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        `${CTV_BILDIRIM_SORGU_URL}`,
        { sicilNo },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getIlanBeyan(beyanYili: number | undefined, binaNo: number | undefined) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        `${ILAN_BEYAN_SORGU_URL}`,
        { beyanYili, binaNo },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getIlanBeyanYillar() {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        ILAN_BEYAN_YILLARI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  bildirimRaporuYazdir(actionURL: string, id: string) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .get(`${BILDIRIM_RAPOR_URL}/${actionURL}/${id}`, {
        responseType: "blob",
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  getTahakkuk(donem: number | undefined, gelirTur: string | undefined) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        TAHAKKUK_LISTESI_URL,
        { donem, gelirTur },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getTahsilat(donem: number | undefined, gelirTur: string | undefined) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        TAHSILAT_LISTESI_URL,
        { donem, gelirTur },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  tahakkukRaporuYazdir(
    donem: number | undefined,
    gelirTur: string | undefined
  ) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .get(`${TAHAKKUK_RAPORU_URL}/${donem ? donem : -1}/${gelirTur}`, {
        responseType: "blob",
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  tahsilatRaporuYazdir(
    donem: number | undefined,
    gelirTur: string | undefined
  ) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .get(`${TAHSILAT_RAPORU_URL}/${donem ? donem : -1}/${gelirTur}`, {
        responseType: "blob",
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  getTahakkukYillar() {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        TAHAKKUK_YILLARI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getTahsilatYillar() {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        TAHSILAT_YILLARI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getTahakkukGelirTurleri() {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        TAHAKKUK_GELIR_TURLERI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getMakbuz(donem: number) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        MAKBUZ_LISTESI_URL,
        { donem },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getIstekSikayetler(takipNo: string | undefined) {
    const userInfo = window.sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        KULLANICI_ISTEK_SIKAYET_SORGU_URL,
        { takipNo },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  sifreDegistir(oncekiSifre: string, yeniSifre: string, sifreTekrar: string) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(
        KULLANICI_KISITLI_ISLEM,
        {
          tanim: user.username,
          sifre: user.password,
          oncekiSifre,
          yeniSifre,
          sifreTekrar,
        },
        {
          auth: {
            username: user.username,
            password: user.password,
          },
        }
      )
      .then((res) => res.data);
  }
  sifreHatirlatKisi(kisi: Kisi) {
    return axios.post(SIFREMI_UNUTTUM_KISI, kisi).then((res) => res.data);
  }
  sifreHatirlatKurum(vergiNo: string, eposta: string) {
    return axios
      .post(SIFREMI_UNUTTUM_KURUM, { vergiNo, eposta })
      .then((res) => res.data);
  }
  getUserInfo() {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(
        `${KULLANICI_BILGI_URL}/${KullaniciTip[user.detail.tip]}`,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  bireyselKullaniciBilgisiKaydet(kullaniciBilgisi: KullaniciBilgiKisi) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .put(`${KULLANICI_BILGISI_KAYDET_URL}/0`, kullaniciBilgisi, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  kurumsalKullaniciBilgisiKaydet(kullaniciBilgisi: KullaniciBilgiKurum) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .put(`${KULLANICI_BILGISI_KAYDET_URL}/1`, kullaniciBilgisi, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  getKurumTurleri() {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(
        KURUM_TURLERI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  getTelefonTurleri() {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(
        TELEFON_TURLERI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  telefonKaydet(telefon: KullaniciBilgiTel) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(TELEFON_KAYDET_URL, telefon, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  telefonSil(telefon: KullaniciBilgiTel) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(TELEFON_SIL_URL, telefon, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  getAdresTurleri() {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(
        ADRES_TURLERI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }
  adresKaydet(adres: KullaniciBilgiAdres) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(ADRES_KAYDET_URL, adres, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  adresSil(adres: KullaniciBilgiAdres) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(ADRES_SIL_URL, adres, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
  istekSikayetKaydet(istek: IstekSikayet) {
    return axios.post(ISTEK_SIKAYET_KAYDET_URL, istek).then((res) => res.data);
  }
  kullaniciIstekSikayetKaydet(istek: IstekSikayet) {
    const userInfo = window.sessionStorage.getItem("user");
    let user: User = JSON.parse(userInfo!);
    return axios
      .post(KULLANICI_ISTEK_SIKAYET_KAYDET_URL, istek, {
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
}

const userService = new UserService();
export default userService;
