import axios from "axios";
import {
  ARAZI_DEGER_RAPOR_URL,
  ARSA_DEGER_RAPOR_URL,
  BINA_ASINMA_PAYLARI_RAPOR_URL,
  CEVRE_GRUP_DERECE_RAPOR_URL,
  INSAAT_MALIYET_RAPOR_URL,
  KARAR_ENCUMEN_RAPOR_URL,
  KARAR_MECLIS_RAPOR_URL,
  BORC_SORGULAMA_RAPOR_URL,
} from "./constants";

class RaporService {
  arsaDegerYazdir(yil: number, mahalleId: string) {
    return axios
      .get(`${ARSA_DEGER_RAPOR_URL}/${yil}/${mahalleId}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  }

  araziDegerYazdir(yil: number) {
    return axios
      .get(`${ARAZI_DEGER_RAPOR_URL}/${yil}`, { responseType: "blob" })
      .then((res) => res.data);
  }

  insaatMaliyetYazdir(
    yil: number,
    kullanimSekliId: string,
    insaatSinifiId: string | undefined
  ) {
    return axios
      .get(
        `${INSAAT_MALIYET_RAPOR_URL}/${yil}/${kullanimSekliId}/${insaatSinifiId}`,
        { responseType: "blob" }
      )
      .then((res) => res.data);
  }

  cevreGrupDereceYazdir(yil: number) {
    return axios
      .get(`${CEVRE_GRUP_DERECE_RAPOR_URL}/${yil}`, { responseType: "blob" })
      .then((res) => res.data);
  }

  kararEncumenYazdir(yil: number, icerik: string | undefined) {
    return axios
      .get(`${KARAR_ENCUMEN_RAPOR_URL}/${yil}/${icerik}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  }

  kararMeclisYazdir(yil: number, icerik: string | undefined) {
    return axios
      .get(`${KARAR_MECLIS_RAPOR_URL}/${yil}/${icerik}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  }

  binaAsinmaYazdir(insaatTuruId: string) {
    return axios
      .get(`${BINA_ASINMA_PAYLARI_RAPOR_URL}/${insaatTuruId}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  }

  borcRaporuYazdir(tcNo: string, tip: string) {
    return axios
      .get(`${BORC_SORGULAMA_RAPOR_URL}/${tcNo}/${tip}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  }
}

const raporService = new RaporService();
export default raporService;
