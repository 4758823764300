import { KullaniciBilgiAdres } from "./KullaniciBilgiAdres";
import { KullaniciBilgiTel } from "./KullaniciBilgiTel";

export class KullaniciBilgiKisi {
  constructor(
    public id?: string,
    public sicilNo?: number,
    public tcKimlikNo?: string,
    public adiSoyadi?: string,
    public babaAdi?: string,
    public anaAdi?: string,
    public dogumYeri?: string,
    public dogumTarihi?: string,
    public vergiDairesi?: string,
    public vergiNo?: string,
    public medeniHali?: string,
    public kanGrubu?: string,
    public telBilgi?: KullaniciBilgiTel[],
    public adresBilgi?: KullaniciBilgiAdres[]
  ) {}
}
