import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { exit, moon, sunny } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { ROLE } from "../../enums/ROLE";

interface HeaderProps {
  title: string | undefined;
  subtitle: string;
  logo: string | undefined;
  roles: Array<ROLE>;
}

const Header: React.FC<HeaderProps> = (props) => {
  const user = sessionStorage.getItem("user");
  const userHasRequiredRole =
    user && props.roles.includes(JSON.parse(user)?.detail?.role) ? true : false;
  const hist = useHistory();
  const { title, subtitle, logo } = props;
  const [themeIcon, setThemeIcon] = useState<any>(moon);
  const [isAuth, setAuth] = useState<boolean>(!!user && userHasRequiredRole);

  const onThemeChange = (e: any) => {
    if (e.detail.checked) {
      setThemeIcon(sunny);
    } else {
      setThemeIcon(moon);
    }
    document.body.classList.toggle("dark", e.detail.checked);
  };

  const logout = () => {
    sessionStorage.removeItem("user");
    setAuth(false);
    hist.push("/tapu/login");
  };

  return (
    <IonHeader translucent>
      <IonToolbar>
        {isAuth && (
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                logout();
              }}
            >
              <IonIcon slot="start" icon={exit} />
              <IonText className="ion-hide-sm-down">Çıkış</IonText>
            </IonButton>
          </IonButtons>
        )}
        <IonTitle className="ion-text-sm-wrap">
          <IonItem className="ion-margin-top ion-margin-bottom">
            <img src={logo} style={{ maxWidth: "3em", height: "auto" }} />
            <IonText className="ion-padding-start ion-hide-sm-up">
              {subtitle}
            </IonText>
            <IonText
              className="ion-padding-start ion-hide-sm-down"
              style={{ fontSize: "1.5em" }}
            >
              {title} - {subtitle}
            </IonText>

            {/* <IonToggle id="themeToggle" slot="end" onIonChange={onThemeChange}></IonToggle>
                        <IonIcon slot="end" icon={themeIcon}></IonIcon> */}
          </IonItem>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
