import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToast,
} from "@ionic/react";
import { format, parse } from "date-fns";
import { add, callOutline, create, trash } from "ionicons/icons";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import Header from "../../components/Header";
import { KullaniciTip } from "../../enums/KullaniciTip";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import { KullaniciBilgiAdres } from "../entities/KullaniciBilgiAdres";
import { KullaniciBilgiKisi } from "../entities/KullaniciBilgiKisi";
import { KullaniciBilgiKurum } from "../entities/KullaniciBilgiKurum";
import { KullaniciBilgiTel } from "../entities/KullaniciBilgiTel";
import "./AnaSayfa.css";
import "./Page.css";

const KullaniciBilgiGuncellemeIslem: React.FC = () => {
  const { authInfo, setTitle } = React.useContext(AuthContext);

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [bireyselKullaniciBilgi, setBireyselKullaniciBilgi] =
    useState<KullaniciBilgiKisi>(new KullaniciBilgiKisi());
  const [kurumsalKullaniciBilgi, setKurumsalKullaniciBilgi] =
    useState<KullaniciBilgiKurum>(new KullaniciBilgiKurum());
  const [showTelEditModal, setShowTelEditModal] = useState<boolean>(false);
  const [showAdresEditModal, setShowAdresEditModal] = useState<boolean>(false);
  const [showConfirmDelTelModal, setShowConfirmDelTelModal] =
    useState<boolean>(false);
  const [showConfirmDelAdresModal, setShowConfirmDelAdresModal] =
    useState<boolean>(false);
  const [telefon, setTelefon] = useState<KullaniciBilgiTel>(
    new KullaniciBilgiTel("", "", "", "", "", "90")
  );
  const [adres, setAdres] = useState<KullaniciBilgiAdres>(
    new KullaniciBilgiAdres("", "Türkiye", "Ev", "", "", "", "", "", "", "", "")
  );
  const [kurumTurleri, setKurumTurleri] = useState<string[]>([]);
  const [telefonTurleri, setTelefonTurleri] = useState<string[]>([]);
  const [adresTurleri, setAdresTurleri] = useState<string[]>([]);
  const telColumnArray = [
    { field: "tur", header: "Tür" },
    { field: "alanKodu", header: "Alan Kodu" },
    { field: "telNo", header: "Telefon No" },
    { field: "dahiliNo", header: "Dahili No" },
    { field: "ulkeKodu", header: "Ülke Kodu" },
  ];

  addLocale("tr", {
    firstDayOfWeek: 1,
    dayNames: [
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
      "Pazar",
    ],
    dayNamesShort: ["Ptsi", "Sal", "Çar", "Per", "Cum", "Ctesi", "Paz"],
    dayNamesMin: ["Pt", "Sa", "Ça", "Pe", "Cu", "Ct", "Pa"],
    monthNames: [
      "Ocak",
      "Şubak",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  React.useEffect(() => {
    setTitle("Üye Bilgileri Güncelleme");
    loadData();
  }, []);

  const loadData = () => {
    setShowLoading(true);
    if (
      authInfo?.user?.detail.tip.toString() ===
      KullaniciTip[KullaniciTip.KURUMSAL]
    ) {
      userService.getKurumTurleri().then((data) => setKurumTurleri(data));
    }
    userService.getTelefonTurleri().then((data) => setTelefonTurleri(data));
    userService.getAdresTurleri().then((data) => setAdresTurleri(data));
    userService
      .getUserInfo()
      .then((data) => {
        if (
          authInfo?.user?.detail.tip.toString() ===
          KullaniciTip[KullaniciTip.BIREYSEL]
        ) {
          const bireyselData: KullaniciBilgiKisi = data;
          setBireyselKullaniciBilgi((prev) => bireyselData);
        } else setKurumsalKullaniciBilgi((prev) => data);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const bilgiKaydet = (event: any) => {
    event.preventDefault();
    setShowLoading(true);
    let service;
    if (
      authInfo?.user?.detail.tip.toString() ===
      KullaniciTip[KullaniciTip.BIREYSEL]
    ) {
      service = userService.bireyselKullaniciBilgisiKaydet(
        bireyselKullaniciBilgi!
      );
    } else {
      service = userService.kurumsalKullaniciBilgisiKaydet(
        kurumsalKullaniciBilgi!
      );
    }
    service
      .then(() => {
        setShowToastMessage("İşlem Başarılı");
        loadData();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const telefonKaydet = (event: any) => {
    event.preventDefault();
    if (!telefon.tur) {
      setShowToastMessage("Lütfen Telefon Türü Bilgisini Giriniz!");
      return;
    }
    if (!telefon.ulkeKodu) {
      setShowToastMessage("Lütfen Ülke Kodu Bilgisini Giriniz!");
      return;
    }
    if (!telefon.alanKodu) {
      setShowToastMessage("Lütfen Alan Kodu Bilgisini Giriniz!");
      return;
    }
    if (!telefon.telNo) {
      setShowToastMessage("Lütfen Telefon No Bilgisini Giriniz!");
      return;
    }
    setShowLoading(true);
    userService
      .telefonKaydet(telefon)
      .then(() => {
        setShowTelEditModal(false);
        setShowToastMessage("İşlem Başarılı");
        loadData();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const telefonSil = () => {
    setShowLoading(true);
    userService
      .telefonSil(telefon)
      .then(() => {
        setShowConfirmDelTelModal(false);
        setShowToastMessage("İşlem Başarılı");
        loadData();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const adresKaydet = (event: any) => {
    event.preventDefault();
    if (!adres.tur) {
      setShowToastMessage("Lütfen Adres Türü Bilgisini Giriniz!");
      return;
    }
    if (!adres.ulke) {
      setShowToastMessage("Lütfen Ülke Bilgisini Giriniz!");
      return;
    }
    if (!adres.il) {
      setShowToastMessage("Lütfen İl Bilgisini Giriniz!");
      return;
    }
    if (!adres.ilce) {
      setShowToastMessage("Lütfen İlce Bilgisini Giriniz!");
      return;
    }
    setShowLoading(true);
    userService
      .adresKaydet(adres)
      .then(() => {
        setShowAdresEditModal(false);
        setShowToastMessage("İşlem Başarılı");
        loadData();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const adresSil = () => {
    setShowLoading(true);
    userService
      .adresSil(adres)
      .then(() => {
        setShowConfirmDelAdresModal(false);
        setShowToastMessage("İşlem Başarılı");
        loadData();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const iletisimBodyTemplate = (
    header: string,
    field: string,
    rowData: any
  ) => {
    return <IonText>{rowData[field]}</IonText>;
  };
  const ikiAlanBodyTemplate = (
    header1: string,
    header2: string,
    field1: any,
    field2: any
  ) => {
    return (
      <IonText className="ion-text-nowrap">
        {field1}/{field2}
      </IonText>
    );
  };
  const telEditTemplate = (rowData: KullaniciBilgiTel) => {
    return (
      <div className="ion-text-end">
        <IonButton
          size="small"
          onClick={() => {
            setTelefon(rowData);
            setShowTelEditModal(true);
          }}
        >
          <IonIcon slot="icon-only" icon={create} />
        </IonButton>
        <IonButton
          size="small"
          onClick={() => {
            setTelefon(rowData);
            setShowConfirmDelTelModal(true);
          }}
        >
          <IonIcon slot="icon-only" icon={trash} />
        </IonButton>
      </div>
    );
  };
  const adresEditTemplate = (rowData: KullaniciBilgiAdres) => {
    return (
      <div className="ion-text-end">
        <IonButton
          size="small"
          onClick={() => {
            setAdres(rowData);
            setShowAdresEditModal(true);
          }}
        >
          <IonIcon slot="icon-only" icon={create} />
        </IonButton>
        <IonButton
          size="small"
          onClick={() => {
            setAdres(rowData);
            setShowConfirmDelAdresModal(true);
          }}
        >
          <IonIcon slot="icon-only" icon={trash} />
        </IonButton>
      </div>
    );
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={5000}
          position="top"
        />
        <TabView>
          <TabPanel header="Mükellef Bilgileri">
            <form onSubmit={(e) => bilgiKaydet(e)}>
              {authInfo?.user?.detail.tip.toString() ===
                KullaniciTip[KullaniciTip.BIREYSEL] && (
                <IonGrid>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Sicil No</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Sicil No</IonLabel>
                        <IonInput
                          name="sicilNoInput"
                          value={bireyselKullaniciBilgi?.sicilNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="sicilNoInput"
                          value={bireyselKullaniciBilgi?.sicilNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>T.C. Kimlik No</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">T.C. Kimlik No</IonLabel>
                        <IonInput
                          name="tcNoInput"
                          value={bireyselKullaniciBilgi?.tcKimlikNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="tcNoInput"
                          value={bireyselKullaniciBilgi?.tcKimlikNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Adı Soyadı</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Adı Soyadı</IonLabel>
                        <IonInput
                          name="adSoyadInput"
                          value={bireyselKullaniciBilgi?.adiSoyadi}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="adSoyadInput"
                          value={bireyselKullaniciBilgi?.adiSoyadi}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Baba Adı</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Baba Adı</IonLabel>
                        <IonInput
                          name="babaAdiInput"
                          value={bireyselKullaniciBilgi?.babaAdi}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              babaAdi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="babaAdiInput"
                          value={bireyselKullaniciBilgi?.babaAdi}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              babaAdi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Ana Adı</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Ana Adı</IonLabel>
                        <IonInput
                          name="anaAdiInput"
                          value={bireyselKullaniciBilgi?.anaAdi}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              anaAdi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="anaAdiInput"
                          value={bireyselKullaniciBilgi?.anaAdi}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              anaAdi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Doğum Yeri</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Doğum Yeri</IonLabel>
                        <IonInput
                          name="dogumYeriInput"
                          value={bireyselKullaniciBilgi?.dogumYeri}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              dogumYeri: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="dogumYeriInput"
                          value={bireyselKullaniciBilgi?.dogumYeri}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              dogumYeri: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Doğum Tarihi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="stacked">Doğum Tarihi</IonLabel>
                        <Calendar
                          id="dogumTarihi"
                          name="dogumTarihiInput"
                          className="ion-padding-top"
                          value={parse(
                            bireyselKullaniciBilgi.dogumTarihi!,
                            "yyyy-MM-dd",
                            new Date()
                          )}
                          onChange={(e) => {
                            const tar = e.value;
                            if (tar) {
                              const tarih = tar as Date;
                              setBireyselKullaniciBilgi({
                                ...bireyselKullaniciBilgi,
                                dogumTarihi: format(tarih, "yyyy-MM-dd"),
                              });
                            }
                          }}
                          showIcon
                          readOnlyInput
                          dateFormat="dd/mm/yy"
                          locale="tr"
                          placeholder="Doğum Tarihi"
                        />
                        {/*  <IonDatetimeButton id="dogumTarihi-trigger" datetime="dogumTarihi" />*/}

                        {/*  <IonPopover trigger="dogumTarihi-trigger" triggerAction="click" keepContentsMounted={true}>*/}
                        {/*    <IonDatetime*/}
                        {/*      id="dogumTarihi" name="dogumTarihiInput"*/}
                        {/*      cancelText="Vazgeç"*/}
                        {/*      doneText="Tamam" showDefaultButtons firstDayOfWeek={1}*/}
                        {/*      value={bireyselKullaniciBilgi.dogumTarihi?.toString()}*/}
                        {/*      presentation="date"*/}
                        {/*      onIonChange={(ev: DatetimeCustomEvent) => {*/}
                        {/*        const tar: string | string[] = ev.detail.value!;*/}
                        {/*        if (tar) {*/}
                        {/*          setBireyselKullaniciBilgi({*/}
                        {/*            ...bireyselKullaniciBilgi,*/}
                        {/*            dogumTarihi: parseISO(tar[0])*/}
                        {/*          });*/}
                        {/*        }*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      <span slot="title">Tarih Seçin</span>*/}
                        {/*    </IonDatetime>*/}
                        {/*  </IonPopover>*/}
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <Calendar
                          id="dogumTarihi"
                          name="dogumTarihiInput"
                          value={parse(
                            bireyselKullaniciBilgi.dogumTarihi!,
                            "yyyy-MM-dd",
                            new Date()
                          )}
                          onChange={(e) => {
                            const tar = e.value;
                            if (tar) {
                              const tarih = tar as Date;
                              setBireyselKullaniciBilgi({
                                ...bireyselKullaniciBilgi,
                                dogumTarihi: format(tarih, "yyyy-MM-dd"),
                              });
                            }
                          }}
                          showIcon
                          readOnlyInput
                          dateFormat="dd/mm/yy"
                          locale="tr"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Vergi Dairesi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Vergi Dairesi</IonLabel>
                        <IonInput
                          name="vergiDairesiInput"
                          value={bireyselKullaniciBilgi?.vergiDairesi}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              vergiDairesi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="vergiDairesiInput"
                          value={bireyselKullaniciBilgi?.vergiDairesi}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              vergiDairesi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Vergi No</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Vergi No</IonLabel>
                        <IonInput
                          name="vergiNoInput"
                          value={bireyselKullaniciBilgi?.vergiNo}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              vergiNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          name="vergiNoInput"
                          value={bireyselKullaniciBilgi?.vergiNo}
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              vergiNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Medeni Hali</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Medeni Hali</IonLabel>
                        <IonSelect
                          name="medeniHaliInput"
                          interface="popover"
                          value={bireyselKullaniciBilgi?.medeniHali}
                          placeholder="Seçiniz..."
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              medeniHali: e.detail.value!,
                            })
                          }
                        >
                          <IonSelectOption value="">Seçiniz</IonSelectOption>
                          <IonSelectOption value="Evli">Evli</IonSelectOption>
                          <IonSelectOption value="Bekar">Bekar</IonSelectOption>
                          <IonSelectOption value="Boşanmış">
                            Boşanmış
                          </IonSelectOption>
                          <IonSelectOption value="Dul">Dul</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonSelect
                          name="medeniHaliInput"
                          interface="popover"
                          value={bireyselKullaniciBilgi?.medeniHali}
                          placeholder="Seçiniz..."
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              medeniHali: e.detail.value!,
                            })
                          }
                        >
                          <IonSelectOption value="">Seçiniz</IonSelectOption>
                          <IonSelectOption value="Evli">Evli</IonSelectOption>
                          <IonSelectOption value="Bekar">Bekar</IonSelectOption>
                          <IonSelectOption value="Boşanmış">
                            Boşanmış
                          </IonSelectOption>
                          <IonSelectOption value="Dul">Dul</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Kan Grubu</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Kan Grubu</IonLabel>
                        <IonSelect
                          name="kanGrubuInput"
                          interface="popover"
                          value={bireyselKullaniciBilgi?.kanGrubu}
                          placeholder="Seçiniz..."
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              kanGrubu: e.detail.value!,
                            })
                          }
                        >
                          <IonSelectOption value="">Seçiniz</IonSelectOption>
                          <IonSelectOption value="0 Rh (+)">
                            0 Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="0 Rh ( - )">
                            0 Rh ( - )
                          </IonSelectOption>
                          <IonSelectOption value="A Rh (+)">
                            A Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="A Rh ( - )">
                            A Rh ( - )
                          </IonSelectOption>
                          <IonSelectOption value="B Rh (+)">
                            B Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="B Rh ( - )">
                            B Rh ( - )
                          </IonSelectOption>
                          <IonSelectOption value="AB Rh (+)">
                            AB Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="AB Rh ( - )">
                            AB Rh ( - )
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonSelect
                          name="kanGrubuInput"
                          interface="popover"
                          value={bireyselKullaniciBilgi?.kanGrubu}
                          placeholder="Seçiniz..."
                          onIonChange={(e) =>
                            setBireyselKullaniciBilgi({
                              ...bireyselKullaniciBilgi,
                              kanGrubu: e.detail.value!,
                            })
                          }
                        >
                          <IonSelectOption value="">Seçiniz</IonSelectOption>
                          <IonSelectOption value="0 Rh (+)">
                            0 Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="0 Rh ( - )">
                            0 Rh ( - )
                          </IonSelectOption>
                          <IonSelectOption value="A Rh (+)">
                            A Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="A Rh ( - )">
                            A Rh ( - )
                          </IonSelectOption>
                          <IonSelectOption value="B Rh (+)">
                            B Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="B Rh ( - )">
                            B Rh ( - )
                          </IonSelectOption>
                          <IonSelectOption value="AB Rh (+)">
                            AB Rh (+)
                          </IonSelectOption>
                          <IonSelectOption value="AB Rh ( - )">
                            AB Rh ( - )
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    ></IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonButton type="submit" className="ion-hide-sm-down">
                        Kaydet
                      </IonButton>
                      <IonButton
                        type="submit"
                        expand="full"
                        className="ion-hide-sm-up"
                      >
                        Kaydet
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}

              {authInfo?.user?.detail.tip.toString() ===
                KullaniciTip[KullaniciTip.KURUMSAL] && (
                <IonGrid>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Sicil No</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Sicil No</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.sicilNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.sicilNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Unvanı</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Unvanı</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.unvani}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.unvani}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Kurum Türü</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Kurum Türü</IonLabel>
                        <IonSelect
                          interface="popover"
                          value={kurumsalKullaniciBilgi?.kurumTuru}
                          placeholder="Seçiniz..."
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              kurumTuru: e.detail.value!,
                            })
                          }
                        >
                          {kurumTurleri.map((t, i) => (
                            <IonSelectOption key={i} value={t}>
                              {t}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonSelect
                          interface="popover"
                          value={kurumsalKullaniciBilgi?.kurumTuru}
                          placeholder="Seçiniz..."
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              kurumTuru: e.detail.value!,
                            })
                          }
                        >
                          {kurumTurleri.map((t, i) => (
                            <IonSelectOption key={i} value={t}>
                              {t}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Yetkili Adı Soyadı</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">
                          Yetkili Adı Soyadı
                        </IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.yetkiliAdiSoyadi}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              yetkiliAdiSoyadi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.yetkiliAdiSoyadi}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              yetkiliAdiSoyadi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Görevi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Görevi</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.gorevi}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              gorevi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.gorevi}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              gorevi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Kuruluş Yeri</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Kuruluş Yeri</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.kurulusYeri}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              kurulusYeri: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.kurulusYeri}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              kurulusYeri: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Kuruluş Tarihi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="stacked">Kuruluş Tarihi</IonLabel>
                        <Calendar
                          id="kurulusTarihi"
                          name="kurulusTarihiInput"
                          className="ion-padding-top"
                          value={parse(
                            kurumsalKullaniciBilgi.kurulusTarihi!,
                            "yyyy-MM-dd",
                            new Date()
                          )}
                          onChange={(e) => {
                            const tar = e.value;
                            if (tar) {
                              const tarih = tar as Date;
                              setKurumsalKullaniciBilgi({
                                ...kurumsalKullaniciBilgi,
                                kurulusTarihi: format(tarih, "yyyy-MM-dd"),
                              });
                            }
                          }}
                          showIcon
                          readOnlyInput
                          dateFormat="dd/mm/yy"
                          locale="tr"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <Calendar
                          id="kurulusTarihi"
                          name="kurulusTarihiInput"
                          value={parse(
                            kurumsalKullaniciBilgi.kurulusTarihi!,
                            "yyyy-MM-dd",
                            new Date()
                          )}
                          onChange={(e) => {
                            const tar = e.value;
                            if (tar) {
                              const tarih = tar as Date;
                              setKurumsalKullaniciBilgi({
                                ...kurumsalKullaniciBilgi,
                                kurulusTarihi: format(tarih, "yyyy-MM-dd"),
                              });
                            }
                          }}
                          showIcon
                          readOnlyInput
                          dateFormat="dd/mm/yy"
                          locale="tr"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Vergi Dairesi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Vergi Dairesi</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.vergiDairesi}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              vergiDairesi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.vergiDairesi}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              vergiDairesi: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Vergi No</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Vergi No</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.vergiNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.vergiNo}
                          readonly={true}
                          className="readonlyInput"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Ticaret Sicil No</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">
                          Ticaret Sicil No
                        </IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.ticaretSicilNo}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              ticaretSicilNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.ticaretSicilNo}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              ticaretSicilNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>E-Posta Adresi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">E-Posta Adresi</IonLabel>
                        <IonInput
                          type="email"
                          value={kurumsalKullaniciBilgi?.eposta}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              eposta: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          type="email"
                          value={kurumsalKullaniciBilgi?.eposta}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              eposta: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    >
                      <IonItem className="ion-text-end">
                        <IonLabel>Web Adresi</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonItem className="ion-hide-sm-up">
                        <IonLabel position="floating">Web Adresi</IonLabel>
                        <IonInput
                          value={kurumsalKullaniciBilgi?.web}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              web: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                      <IonItem className="ion-hide-sm-down">
                        <IonInput
                          value={kurumsalKullaniciBilgi?.web}
                          onIonChange={(e) =>
                            setKurumsalKullaniciBilgi({
                              ...kurumsalKullaniciBilgi,
                              web: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="4"
                      offset="0"
                      sizeSm="4"
                      offsetSm="1"
                      className="ion-hide-sm-down"
                    ></IonCol>
                    <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                      <IonButton type="submit" className="ion-hide-sm-down">
                        Kaydet
                      </IonButton>
                      <IonButton
                        type="submit"
                        expand="full"
                        className="ion-hide-sm-up"
                      >
                        Kaydet
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </form>
          </TabPanel>
          <TabPanel header="İletişim Bilgileri">
            <IonCard>
              <IonCardHeader>
                <IonItem>
                  <IonButton
                    slot="start"
                    size="default"
                    onClick={() => {
                      setTelefon(
                        new KullaniciBilgiTel("", "", "", "", "", "90")
                      );
                      setShowTelEditModal(true);
                    }}
                  >
                    <IonIcon slot="start" icon={add} />
                    Yeni
                  </IonButton>
                  Telefon Bilgileri
                </IonItem>
              </IonCardHeader>
              <IonCardContent className="datatable-responsive">
                <DataTable
                  value={
                    authInfo?.user?.detail.tip.toString() ===
                    KullaniciTip[KullaniciTip.BIREYSEL]
                      ? bireyselKullaniciBilgi?.telBilgi
                      : kurumsalKullaniciBilgi?.telBilgi
                  }
                  paginator
                  rows={10}
                  emptyMessage=""
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  {telColumnArray.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      filterPlaceholder="Ara..."
                      headerClassName="ion-text-center"
                      bodyClassName="ion-text-center"
                      body={(rowData) =>
                        iletisimBodyTemplate(col.header, col.field, rowData)
                      }
                    />
                  ))}
                  <Column
                    field="edit"
                    header="Düzenle"
                    body={(rowData) => telEditTemplate(rowData)}
                    headerClassName="ion-text-end"
                  />
                </DataTable>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonItem>
                  <IonButton
                    slot="start"
                    size="default"
                    onClick={() => {
                      setAdres(
                        new KullaniciBilgiAdres(
                          "",
                          "Türkiye",
                          "Ev",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          ""
                        )
                      );
                      setShowAdresEditModal(true);
                    }}
                  >
                    <IonIcon slot="start" icon={add} />
                    Yeni
                  </IonButton>
                  Adres Bilgileri
                </IonItem>
              </IonCardHeader>
              <IonCardContent className="datatable-responsive">
                <DataTable
                  value={
                    authInfo?.user?.detail.tip.toString() ===
                    KullaniciTip[KullaniciTip.BIREYSEL]
                      ? bireyselKullaniciBilgi?.adresBilgi
                      : kurumsalKullaniciBilgi?.adresBilgi
                  }
                  paginator
                  rows={10}
                  emptyMessage=""
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  <Column
                    field="tur"
                    header="Tür"
                    headerClassName="ion-text-center"
                    bodyClassName="ion-text-center"
                    body={(rowData) =>
                      iletisimBodyTemplate("Tür", "tur", rowData)
                    }
                  />
                  <Column
                    header="İl/İlçe"
                    headerClassName="ion-text-center"
                    bodyClassName="ion-text-center"
                    body={(rowData) =>
                      ikiAlanBodyTemplate(
                        "İl",
                        "İlce",
                        rowData.il,
                        rowData.ilce
                      )
                    }
                  />
                  <Column
                    header="Bucak/Köy"
                    headerClassName="ion-text-center"
                    bodyClassName="ion-text-center"
                    body={(rowData) =>
                      ikiAlanBodyTemplate(
                        "Bucak",
                        "Köy",
                        rowData.bucak,
                        rowData.koy
                      )
                    }
                  />
                  <Column
                    header="Mahalle"
                    headerClassName="ion-text-center"
                    bodyClassName="ion-text-center"
                    body={(rowData) =>
                      iletisimBodyTemplate("Mahalle", "mahalle", rowData)
                    }
                  />
                  <Column
                    header="Cadde/Sokak"
                    headerClassName="ion-text-center"
                    bodyClassName="ion-text-center"
                    body={(rowData) =>
                      iletisimBodyTemplate("Cadde/Sokak", "caddeSokak", rowData)
                    }
                  />
                  <Column
                    header="Kapı/Daire No"
                    headerClassName="ion-text-center"
                    bodyClassName="ion-text-center"
                    body={(rowData) =>
                      ikiAlanBodyTemplate(
                        "Kapı",
                        "Daire No",
                        rowData.kapiNo,
                        rowData.daireNo
                      )
                    }
                  />
                  <Column
                    field="edit"
                    header="Düzenle"
                    body={(rowData) => adresEditTemplate(rowData)}
                    headerClassName="ion-text-end"
                  />
                </DataTable>
              </IonCardContent>
            </IonCard>
            <IonModal
              isOpen={showTelEditModal}
              onDidDismiss={() => setShowTelEditModal(false)}
            >
              <IonCard>
                <IonCardHeader>
                  <IonItem color="light">
                    <IonIcon icon={callOutline} slot="start" />
                    <IonLabel>Telefon Bilgileri</IonLabel>
                  </IonItem>
                </IonCardHeader>
                <IonCardContent>
                  <form onSubmit={(e) => telefonKaydet(e)}>
                    <IonGrid>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Telefon Türü</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">
                              Telefon Türü
                            </IonLabel>
                            <IonSelect
                              name="telTurInput"
                              interface="popover"
                              value={telefon?.tur}
                              placeholder="Seçiniz..."
                              onIonChange={(e) =>
                                setTelefon({ ...telefon, tur: e.detail.value! })
                              }
                            >
                              {telefonTurleri.map((t, i) => (
                                <IonSelectOption key={i} value={t}>
                                  {t}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonSelect
                              name="telTurInput"
                              interface="popover"
                              value={telefon?.tur}
                              placeholder="Seçiniz..."
                              onIonChange={(e) =>
                                setTelefon({ ...telefon, tur: e.detail.value! })
                              }
                            >
                              {telefonTurleri.map((t, i) => (
                                <IonSelectOption key={i} value={t}>
                                  {t}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Alan Kodu</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Alan Kodu</IonLabel>
                            <IonInput
                              name="alanKoduInput"
                              type="tel"
                              maxlength={3}
                              value={telefon.alanKodu}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  alanKodu: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="alanKoduInput"
                              type="tel"
                              maxlength={3}
                              value={telefon.alanKodu}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  alanKodu: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Telefon No</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Telefon No</IonLabel>
                            <IonInput
                              name="telNoInput"
                              type="tel"
                              maxlength={7}
                              value={telefon.telNo}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  telNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="telNoInput"
                              value={telefon.telNo}
                              type="tel"
                              maxlength={7}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  telNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Dahili No</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Dahili No</IonLabel>
                            <IonInput
                              name="dahiliNoInput"
                              type="tel"
                              maxlength={3}
                              value={telefon.dahiliNo}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  dahiliNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="dahiliNoInput"
                              type="tel"
                              maxlength={3}
                              value={telefon.dahiliNo}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  dahiliNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Ülke Kodu</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Ülke Kodu</IonLabel>
                            <IonInput
                              name="ulkeKoduInput"
                              type="tel"
                              maxlength={3}
                              value={telefon.ulkeKodu}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  ulkeKodu: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="ulkeKoduInput"
                              type="tel"
                              maxlength={3}
                              value={telefon.ulkeKodu}
                              onIonChange={(e) =>
                                setTelefon({
                                  ...telefon,
                                  ulkeKodu: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-padding-top">
                        <IonCol className="ion-text-end">
                          <IonButton type="submit">Kaydet</IonButton>
                        </IonCol>
                        <IonCol className="ion-text-start">
                          <IonButton
                            type="button"
                            fill="outline"
                            onClick={() => {
                              setShowTelEditModal(false);
                              setTelefon(
                                new KullaniciBilgiTel("", "", "", "", "", "90")
                              );
                            }}
                          >
                            İptal
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                </IonCardContent>
              </IonCard>
            </IonModal>
            <IonModal
              className="verification-modal"
              isOpen={showConfirmDelTelModal}
              onDidDismiss={() => setShowConfirmDelTelModal(false)}
            >
              <IonList>
                <IonListHeader>
                  <IonTitle>Silmeyi Onayla</IonTitle>
                </IonListHeader>
                <IonItem>Silme işlemini onaylıyor musunuz?</IonItem>
                <IonItemDivider />
                <IonItem>
                  <IonButton
                    size="default"
                    onClick={() => {
                      telefonSil();
                    }}
                  >
                    Evet
                  </IonButton>
                  <IonButton
                    size="default"
                    color="light"
                    onClick={() => {
                      setShowConfirmDelTelModal(false);
                      setTelefon(
                        new KullaniciBilgiTel("", "", "", "", "", "90")
                      );
                    }}
                  >
                    Hayır
                  </IonButton>
                </IonItem>
              </IonList>
            </IonModal>
            <IonModal
              isOpen={showAdresEditModal}
              onDidDismiss={() => setShowAdresEditModal(false)}
            >
              <IonContent>
                <IonCard>
                  <IonCardHeader>
                    <IonItem color="light">
                      <IonIcon icon={callOutline} slot="start" />
                      <IonLabel>Adres Bilgileri</IonLabel>
                    </IonItem>
                  </IonCardHeader>
                  <IonCardContent>
                    <form onSubmit={(e) => adresKaydet(e)}>
                      <IonGrid>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Adres Türü</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">
                                Adres Türü
                              </IonLabel>
                              <IonSelect
                                interface="popover"
                                value={adres?.tur}
                                placeholder="Seçiniz..."
                                onIonChange={(e) =>
                                  setAdres({ ...adres, tur: e.detail.value! })
                                }
                              >
                                {adresTurleri.map((t, i) => (
                                  <IonSelectOption key={i} value={t}>
                                    {t}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonSelect
                                interface="popover"
                                value={adres?.tur}
                                placeholder="Seçiniz..."
                                onIonChange={(e) =>
                                  setAdres({ ...adres, tur: e.detail.value! })
                                }
                              >
                                {adresTurleri.map((t, i) => (
                                  <IonSelectOption key={i} value={t}>
                                    {t}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Ülke</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">Ülke</IonLabel>
                              <IonInput
                                value={adres.ulke}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    ulke: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                value={adres.ulke}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    ulke: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>İl/İlçe</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">İl</IonLabel>
                              <IonInput
                                value={adres.il}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    il: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                value={adres.il}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    il: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                              <IonText> / </IonText>
                              <IonInput
                                value={adres.ilce}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    ilce: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-hide-sm-up">
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating">İlçe</IonLabel>
                              <IonInput
                                value={adres.ilce}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    ilce: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Bucak/Köy</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">Bucak</IonLabel>
                              <IonInput
                                value={adres.bucak}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    bucak: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                value={adres.bucak}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    bucak: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                              <IonText> / </IonText>
                              <IonInput
                                value={adres.koy}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    koy: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-hide-sm-up">
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating">Köy</IonLabel>
                              <IonInput
                                value={adres.koy}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    koy: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Mahalle</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">Mahalle</IonLabel>
                              <IonInput
                                value={adres.mahalle}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    mahalle: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                value={adres.mahalle}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    mahalle: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Cadde/Sokak</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">
                                Cadde/Sokak
                              </IonLabel>
                              <IonInput
                                value={adres.caddeSokak}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    caddeSokak: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                value={adres.caddeSokak}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    caddeSokak: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Kapı No/Daire No</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">Kapı No</IonLabel>
                              <IonInput
                                value={adres.kapiNo}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    kapiNo: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                value={adres.kapiNo}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    kapiNo: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                              <IonText> / </IonText>
                              <IonInput
                                value={adres.daireNo}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    daireNo: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-hide-sm-up">
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating">Daire No</IonLabel>
                              <IonInput
                                value={adres.daireNo}
                                onIonChange={(e) =>
                                  setAdres({
                                    ...adres,
                                    daireNo: e.detail.value!,
                                  })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-padding-top">
                          <IonCol className="ion-text-end">
                            <IonButton type="submit">Kaydet</IonButton>
                          </IonCol>
                          <IonCol className="ion-text-start">
                            <IonButton
                              type="button"
                              fill="outline"
                              onClick={() => {
                                setShowAdresEditModal(false);
                                setAdres(
                                  new KullaniciBilgiAdres(
                                    "",
                                    "Türkiye",
                                    "Ev",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    ""
                                  )
                                );
                              }}
                            >
                              İptal
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </form>
                  </IonCardContent>
                </IonCard>
              </IonContent>
            </IonModal>
            <IonModal
              className="verification-modal"
              isOpen={showConfirmDelAdresModal}
              onDidDismiss={() => setShowConfirmDelAdresModal(false)}
            >
              <IonList>
                <IonListHeader>
                  <IonTitle>Silmeyi Onayla</IonTitle>
                </IonListHeader>
                <IonItem>Silme işlemini onaylıyor musunuz?</IonItem>
                <IonItemDivider />
                <IonItem>
                  <IonButton
                    size="default"
                    onClick={() => {
                      adresSil();
                    }}
                  >
                    Evet
                  </IonButton>
                  <IonButton
                    size="default"
                    color="light"
                    onClick={() => {
                      setShowConfirmDelAdresModal(false);
                      setAdres(
                        new KullaniciBilgiAdres(
                          "",
                          "Türkiye",
                          "Ev",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          ""
                        )
                      );
                    }}
                  >
                    Hayır
                  </IonButton>
                </IonItem>
              </IonList>
            </IonModal>
          </TabPanel>
        </TabView>
      </IonContent>
    </IonPage>
  );
};

export default KullaniciBilgiGuncellemeIslem;
