import axios from "axios";
import {
  KURUM_BILGILERI_URL,
  YARDIM_BILGILERI_URL,
  ASKIDA_FATURALAR_URL,
  ASKIDA_FATURA_ISTATISTIK_URL,
  RAMAZAN_PAKETLERI_URL,
  AILE_PAKETLERI_URL,
  ANNEBEBEK_PAKETLERI_URL,
  EGITIM_PAKETLERI_URL,
  YEMEK_PAKETLERI_URL,
  ASKIDA_YARDIM_ISTATISTIK_URL,
  YAKACAK_PAKETLERI_URL,
} from "./constants";

class SorgulamaService {
  getYakacakPaketleri() {
    return axios.post(YAKACAK_PAKETLERI_URL).then((res) => res.data);
  }
  getAnneBebekPaketleri() {
    return axios.post(ANNEBEBEK_PAKETLERI_URL).then((res) => res.data);
  }
  getEgitimPaketleri() {
    return axios.post(EGITIM_PAKETLERI_URL).then((res) => res.data);
  }
  getAilePaketleri() {
    return axios.post(AILE_PAKETLERI_URL).then((res) => res.data);
  }
  getYemekPaketleri() {
    return axios.post(YEMEK_PAKETLERI_URL).then((res) => res.data);
  }

  getKurumBilgileri() {
    return axios
      .post(
        KURUM_BILGILERI_URL,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => res.data);
  }

  getYardimBilgileri() {
    return axios.post(YARDIM_BILGILERI_URL).then((res) => res.data);
  }

  getAskidaFaturalar() {
    return axios.post(ASKIDA_FATURALAR_URL).then((res) => res.data);
  }

  getAskidaFaturaIstatistik() {
    return axios.post(ASKIDA_FATURA_ISTATISTIK_URL).then((res) => res.data);
  }

  getRamazanPaketleri() {
    return axios.post(RAMAZAN_PAKETLERI_URL).then((res) => res.data);
  }

  getAskidaYardimIstatistik(tip: number) {
    return axios
      .post(`${ASKIDA_YARDIM_ISTATISTIK_URL}/${tip}`)
      .then((res) => res.data);
  }
}

const sorgulamaService = new SorgulamaService();
export default sorgulamaService;
