import React from "react";
import { KurumBilgileri } from "../ebelediye/entities/KurumBilgileri";
import { User } from "../ebelediye/entities/User";

type UserDataInterface = {
  initialized: boolean;
  loggedIn: boolean;
  user: any;
};
type AppContextInterface = {
  authInfo: UserDataInterface;
  title: string;
  setTitle: (title: string) => void;
  logo: string;
  setLogo: (logo: string) => void;
  uyelikTipi: number;
  setUyelikTipi: (tip: number) => void;
  kurumBilgileri: KurumBilgileri | undefined;
  setKurumBilgileri: (kb: KurumBilgileri) => void;
  initialize: () => void;
  logOut: () => Promise<boolean>;
  logIn: (user: User) => Promise<boolean>;
};

// create the context
export const AuthContext = React.createContext<AppContextInterface>({
  authInfo: { initialized: false, loggedIn: false, user: null },
  title: "",
  setTitle: (title: string) => {},
  logo: "",
  setLogo: (logo: string) => {},
  uyelikTipi: 0,
  setUyelikTipi: (tip: number) => {},
  kurumBilgileri: undefined,
  setKurumBilgileri: (tip: KurumBilgileri) => {},
  initialize: () => {},
  logOut: async () => false,
  logIn: async (user: User) => false,
});

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...
type Props = {
  children: React.ReactNode;
};
export const AuthProvider: React.FC<Props> = (props: Props) => {
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>({
    initialized: false,
    loggedIn: false,
    user: null,
  });
  const [title, setTitle] = React.useState<string>("");
  const [logo, setLogo] = React.useState<string>("");
  const [uyelikTipi, setUyelikTipi] = React.useState<number>(0);
  const [kurumBilgileri, setKurumBilgileri] = React.useState<KurumBilgileri>();

  const logOut = () => {
    return new Promise<boolean>((resolve) => {
      window.sessionStorage.removeItem("user");
      setAuthInfo({ initialized: true, loggedIn: false, user: null });
      return resolve(true);
    });
  };

  const logIn = (user: User) => {
    return new Promise<boolean>((resolve) => {
      let v = {
        initialized: true,
        loggedIn: true,
        user,
      };
      setAuthInfo(v);
      //   window.sessionStorage.setItem("user", JSON.stringify(v.user));
      return resolve(true);
    });
  };

  const initialize = () => {
    let response = window.sessionStorage.getItem("user") || null;
    if (response !== null) {
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        user: JSON.parse(response),
      });
    } else {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: null,
      });
    }
  };

  let v = {
    authInfo,
    logOut: logOut,
    logIn: logIn,
    initialize,
    title,
    setTitle,
    logo,
    setLogo,
    uyelikTipi,
    setUyelikTipi,
    kurumBilgileri,
    setKurumBilgileri,
  };

  return (
    <AuthContext.Provider value={v}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
