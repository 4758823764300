import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { sha512 } from "js-sha512";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import "../../ebelediye/pages/Page.css";
import userService from "../../services/UserService";
import { KullaniciTip } from "../../enums/KullaniciTip";
import { ROLE } from "../../enums/ROLE";
import { User } from "../../ebelediye/entities/User";

const EdevletTapuLogin: React.FC = () => {
  const { setTitle, logIn } = React.useContext(AuthContext);
  const navigation = useIonRouter();
  const [error, setError] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const location = useLocation();

  React.useEffect(() => {
    setTitle("E-Devlet ile Giriş");
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("error")) {
      setError(searchParams.get("error"));
      setErrorMessage(searchParams.get("error_description"));
    } else {
      const user = new User(
        {
          sicilNo: -1,
          tip: KullaniciTip.TAPU,
          adSoyad: undefined,
          role: ROLE.Tapu,
          menus: [],
        },
        searchParams.get("user")!,
        sha512(searchParams.get("token")!)
      );
      userService
        .login(user)
        .then(async () => {
          await logIn(user);
          navigation.push("/tapu", "root");
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          setError("Hata");
          setErrorMessage(error.response ? error.response.data.message : error);
        })
        .finally(() => setShowLoading(false));
    }
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        {error && (
          <IonItem>
            <IonGrid>
              <IonRow className="ion-text-start ion-align-items-center">
                <IonCol size="1">
                  <img src="assets/denied.png" />
                </IonCol>
                <IonCol>
                  <IonLabel color="danger">{error}</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="ion-text-start ion-align-items-center">
                <IonCol size="1"></IonCol>
                <IonCol>
                  <IonLabel color="danger">{errorMessage}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EdevletTapuLogin;
