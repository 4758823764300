import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import "./Page.css";

const UyelikOnayi: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const { OnayKodu } = useParams<{ OnayKodu: string }>();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [msg, setMsg] = useState<string>();
  const [hata, setHata] = useState<string>();

  useEffect(() => {
    setTitle("Üyelik Onay Durumu");
    userService
      .confirmUser(OnayKodu)
      .then((resp) => setMsg(resp))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        setHata(error.response ? error.response.data.message : error);
      })
      .finally(() => setShowLoading(false));
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        {!hata && (
          <IonItem>
            <IonGrid>
              <IonRow className="ion-text-center ion-align-items-center">
                <IonCol size="1">
                  <img src="assets/success.png" width="28" height="28" />
                </IonCol>
                <IonCol className="ion-text-start" size="11">
                  <IonItem>
                    <IonText>{msg}</IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        )}
        {hata && (
          <IonItem>
            <IonGrid>
              <IonRow className="ion-text-center">
                <IonCol size="1">
                  <img src="assets/denied.png" />
                </IonCol>
                <IonCol>
                  <IonLabel>{hata}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        )}
      </IonContent>
    </IonPage>
  );
};

export default UyelikOnayi;
