import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonPage,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToast,
} from "@ionic/react";
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from "mui-datatables";
import { Accordion, AccordionTab } from "primereact/accordion";
import React, { createRef, useEffect, useState } from "react";
import Mahalle from "../../ebelediye/entities/Mahalle";
import { ROLE } from "../../enums/ROLE";
import { AuthContext } from "../../providers/authContext";
import Header from "../components/Header";
import { RayicBedel } from "../entities/RayicBedel";
import sorgulamaService from "../services/SorgulamaService";
import "./RayicBedelSorgu.css";

const RayicBedelSorgu: React.FC = () => {
  const { logo, kurumBilgileri } = React.useContext(AuthContext);
  const isMounted = React.useRef(false);
  const tableRef = React.useRef<
    | React.RefObject<
        React.Component<MUIDataTableProps, MUIDataTableState> | null | undefined
      >[]
    | undefined
  >([]);
  const [elRefs, setElRefs] = React.useState([]);
  const [aktifIndeks, setAktifIndeks] = useState(0);
  const [tcNo, setTCNo] = useState<string>();
  const [mahalle, setMahalle] = useState<Mahalle>();
  const [mahalleler, setMahalleler] = useState<Mahalle[]>([]);
  const [ada, setAda] = useState<number | undefined>();
  const [parsel, setParsel] = useState<number | undefined>();
  const [bagimsizBolum, setBagimsizBolum] = useState<string | undefined>();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedBorc, setSelectedBorc] = useState<number>(0);
  const [tcNoMessage, setTCNoMessage] = useState<string>();
  const [donemMessage, setDonemMessage] = useState<string>();
  const [mahalleMessage, setMahalleMessage] = useState<string>();
  const [adaMessage, setAdaMessage] = useState<string>();
  const [parselMessage, setParselMessage] = useState<string>();
  const [beyanTuru, setBeyanTuru] = useState<string>();
  const [selectedBeyanIds, setSelectedBeyanIds] = useState<string[]>([]);
  const [donem, setDonem] = useState<number>(new Date().getFullYear());
  const [yillar, setYillar] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [sonuc, setSonuc] = useState<RayicBedel[]>([]);
  const [raporTurPopupGoster, setRaporTurPopupGoster] =
    useState<boolean>(false);
  const [raporTur, setRaporTur] = useState<number>(1);
  const [borcPopupGoster, setBorcPopupGoster] = useState<boolean>(false);

  const columns = [
    { name: "beyanId", options: { display: false } },
    { name: "bildirimNo", label: "Bildirim No" },
    { name: "beyanTuru", label: "Beyan Tür" },
    { name: "mahalle", label: "Mahalle" },
    { name: "sokak", label: "Sokak" },
    { name: "paftaNo", label: "Pafta No" },
    { name: "adaNo", label: "Ada No" },
    { name: "parselNo", label: "Parsel No" },
    { name: "ciltNo", label: "Cilt No" },
    { name: "sahifeNo", label: "Sahife No" },
    { name: "kapiNo", label: "Kapı No" },
    { name: "bagimsizBolumNo", label: "Bagimsiz Bolum" },
    { name: "katNo", label: "Kat No" },
    { name: "blokNo", label: "Blok No" },
    {
      name: "borc",
      label: "Borç",
      options: {
        customBodyRender: (
          value: any,
          tableMeta: MUIDataTableMeta,
          updateValue: (value: any) => void
        ) => <span>{value > 0 ? "Borcu Var" : "-"}</span>,
      },
    },
    { name: "borc", options: { display: false } },
    { name: "mukellefId", options: { display: false } },
    { name: "borcuVarMi", options: { display: false } },
    { name: "borcVarsaDaRaporGoster", options: { display: false } },
    { name: "rayicGoster", options: { display: false } },
  ];
  const options: MUIDataTableOptions = {
    responsive: "simple",
    selectableRows: "multiple",
    customToolbarSelect: (selectedRows, displayData) => {
      const selectedRayics: any[] = [];
      for (let i = 0; i < displayData.length; i++) {
        const el = displayData[i];
        for (let j = 0; j < selectedRows.data.length; j++) {
          if (selectedRows.data[j].dataIndex === el.dataIndex) {
            selectedRayics.push(el.data);
            break;
          }
        }
      }
      return (
        <IonButton
          className="ion-padding-end"
          onClick={() => raporYazdir(selectedRayics, displayData)}
          color="medium"
        >
          Rapor Yazdır
        </IonButton>
      );
    },
    textLabels: {
      body: {
        noMatch: "Kayıt bulunamadı",
        toolTip: "Sırala",
      },
      pagination: {
        next: "Sonraki Sayfa",
        previous: "Önceki Sayfa",
        rowsPerPage: "Satır Sayısı:",
        displayRows: "/",
        jumpToPage: "Sayfaya Git:",
      },
      toolbar: {
        search: "Ara",
        downloadCsv: "Listeyi Yazdır",
        print: "Yazdır",
        viewColumns: "Sütunları Görüntüle",
        filterTable: "Tabloyu Filtrele",
      },
      filter: {
        all: "Tümü",
        title: "FİLTRELER",
        reset: "RESET",
      },
      viewColumns: {
        title: "Sütunları Göster",
        titleAria: "Tablo Sütunlarını Göster/Gizle",
      },
      selectedRows: {
        text: "satır seçildi",
        delete: "Sil",
        deleteAria: "Seçili Satırları Sil",
      },
    },
    print: false,
    onDownload: (buildHead, buildbody, columns, data) => {
      setLoading(true);
      const mukellefId = data[0].data[16];
      sorgulamaService
        .rayicBildirimListesiYazdir(mukellefId, donem!, beyanTuru)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "rayic.bildirim.listesi.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err.response);
          setShowToastMessage(err.message);
        })
        .finally(() => setLoading(false));
      return false;
    },
  };

  const raporYazdir = (rayicler: any[], displayData: any[]) => {
    let rayicGoster = false;
    let borcuVarMi = false;
    let borcVarsaDaRaporGoster = false;
    for (let row of displayData) {
      console.log(row);
      if (row.data[17]) {
        borcuVarMi = true;
      }
      if (row.data[18]) {
        borcVarsaDaRaporGoster = true;
      }
      if (row.data[19]) {
        rayicGoster = true;
      }
    }
    if (rayicGoster) {
      setSelectedRows(rayicler);
      if (rayicler.length === 1) {
        const data = rayicler[0];
        setSelectedBeyanIds([data[0]]);
        setRaporTurPopupGoster(true);
      } else {
        const ids = [];
        for (let i = 0; i < rayicler.length; i++) {
          const data = rayicler[i];
          ids.push(data[0]);
        }
        setSelectedBeyanIds(ids);
        setRaporTurPopupGoster(true);
      }
    } else {
      if (borcuVarMi && !borcVarsaDaRaporGoster) {
        setSelectedBeyanIds([]);
        setBorcPopupGoster(true);
      } else {
        borcuVarMi = rayicler.filter((row) => row[17]).length > 0;
        if (borcuVarMi) {
          setSelectedBeyanIds([]);
          setBorcPopupGoster(true);
        } else {
          setSelectedRows(rayicler);
          if (rayicler.length === 1) {
            const data = rayicler[0];
            if (data[15]) {
              setSelectedBeyanIds([]);
              setBorcPopupGoster(true);
            } else {
              setSelectedBeyanIds([data[0]]);
              setRaporTurPopupGoster(true);
            }
          } else {
            const ids = [];
            for (let i = 0; i < rayicler.length; i++) {
              const data = rayicler[i];
              if (data[15]) {
                borcuVarMi = true;
              }
              ids.push(data[0]);
            }
            if (borcuVarMi) {
              setSelectedBeyanIds([]);
              setBorcPopupGoster(true);
            } else {
              setSelectedBeyanIds(ids);
              setRaporTurPopupGoster(true);
            }
          }
        }
      }
    }
  };

  const raporGoster = () => {
    if (raporTur === 1 && selectedRows.length > 1) {
      setShowToastMessage(
        "Rapor Tür 1 için sadece 1 adet beyan seçebilirsiniz."
      );
      return;
    }
    setLoading(true);
    const mukellefId = selectedRows[0][16];
    sorgulamaService
      .rayicBildirimRaporuYazdir(
        mukellefId,
        donem!,
        selectedBeyanIds!,
        raporTur
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "rayic.bildirim.rapor.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err.response);
        setShowToastMessage(err.message);
      })
      .finally(() => setLoading(false));
  };

  const formatCurrency = (value: number | undefined) => {
    if (!value) {
      value = 0;
    }
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
    }).format(value);
  };

  React.useEffect(() => {
    isMounted.current = true;
    setLoading(true);
    setTCNo(undefined);
    setBeyanTuru(undefined);
    setSonuc([]);
    sorgulamaService
      .getDonemler()
      .then((data: number[]) => {
        if (isMounted.current) {
          setYillar(data);
        }
      })
      .catch((err) => {
        console.error(err);
        setShowToastMessage(err.message);
      })
      .finally(() => {
        setDonem(new Date().getFullYear());
      });

    sorgulamaService
      .getMahalleler()
      .then((data: Mahalle[]) => {
        if (isMounted.current) {
          setMahalleler(data);
        }
      })
      .catch((err) => {
        console.error(err);
        setShowToastMessage(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(sonuc.length)
        .fill(0)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [sonuc.length]);

  const getRayicBedel = (event: any) => {
    event.preventDefault();
    let errorFound = false;
    setTCNoMessage(undefined);
    setDonemMessage(undefined);
    setMahalleMessage(undefined);
    setAdaMessage(undefined);
    setParselMessage(undefined);
    if (!tcNo) {
      // setTCNoMessage("* Lütfen T.C Kimlik No / Vergi No Giriniz!");
      // TODO SADECE YAHŞİHAN İÇİN MAH ZORUNLULUĞU KALDIRILDI
      // if (!mahalle) {
      //   setMahalleMessage("* Lütfen Mahalle Seçiniz!");
      //   errorFound = true;
      //}
      // if (!ada) {
      //   setAdaMessage("* Lütfen Ada Bilgisini Giriniz!");
      //   errorFound = true;
      // }
      if (!parsel) {
        setParselMessage("* Lütfen Parsel Bilgisini Giriniz!");
        errorFound = true;
      }
    }
    if (!donem) {
      setDonemMessage("* Lütfen Dönem Giriniz!");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }

    setLoading(true);
    sorgulamaService
      .getRayicBedeller(
        tcNo,
        donem!,
        beyanTuru,
        mahalle?.id,
        ada,
        parsel,
        bagimsizBolum
      )
      .then((data: RayicBedel[]) => {
        if (!data || data.length === 0) {
          throw "Girilen parametrelere göre sonuç bulunamadı!";
        }
        elRefs.forEach((elRef: any) => elRef.current?.resetFilters());
        const rayicbedeller: RayicBedel[] = data.map((rb) => {
          const detay = rb.detay.map((rbd) => {
            rbd.mukellefId = rb.mukellefId;
            rbd.borcuVarMi = rb.borcuVarMi;
            rbd.borcVarsaDaRaporGoster = rb.borcVarsaDaRaporGoster;
            rbd.rayicGoster = rb.rayicGoster;
            return rbd;
          });
          rb.detay = detay;
          return rb;
        });
        setSonuc(rayicbedeller);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage>
      <Header
        title={kurumBilgileri?.kisaTanim}
        subtitle="Vergi Değeri Sorgulama"
        logo={logo}
        roles={[ROLE.Tapu]}
      />
      <IonContent fullscreen>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={5000}
          position="top"
        />
        <IonPopover
          isOpen={borcPopupGoster}
          onDidDismiss={() => setBorcPopupGoster(false)}
          className="borc-popover"
        >
          <IonCard className="ion-margin ion-padding">
            <IonText>
              Mükellefin belediyeye ödenmemiş borçları bulunduğundan rapor
              görüntülenememektir. E-belediye üzerinden bütün borçların ödenmesi
              veya mükellefin belediyemize başvurması gerekmektedir.
            </IonText>
          </IonCard>
          <IonFooter className="ion-text-center">
            <IonButton onClick={() => setBorcPopupGoster(false)}>
              Tamam
            </IonButton>
          </IonFooter>
        </IonPopover>
        <IonPopover
          isOpen={raporTurPopupGoster}
          onDidDismiss={() => setRaporTurPopupGoster(false)}
          className="borc-popover"
        >
          <IonCard className="ion-margin ion-padding">
            <IonRadioGroup
              value={raporTur}
              onIonChange={(e) => setRaporTur(e.detail.value)}
            >
              <IonListHeader>
                <IonLabel>
                  Raporu almak için rapor çeşidini seçip Tamam'a tıklayınız.
                </IonLabel>
              </IonListHeader>

              <IonItem>
                <IonLabel>Rapor Tür 1</IonLabel>
                <IonRadio slot="start" value={1} />
              </IonItem>

              <IonItem>
                <IonLabel>Rapor Tür 2</IonLabel>
                <IonRadio slot="start" value={2} />
              </IonItem>
            </IonRadioGroup>
          </IonCard>
          <IonFooter className="ion-text-center">
            <IonButton
              onClick={() => {
                raporGoster();
                setRaporTurPopupGoster(false);
              }}
              className="ion-padding-end"
            >
              Tamam
            </IonButton>
            <IonButton
              onClick={() => setRaporTurPopupGoster(false)}
              color="light"
            >
              Vazgeç
            </IonButton>
          </IonFooter>
        </IonPopover>
        <form
          onSubmit={(e) => {
            getRayicBedel(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol sizeSm="6" size="12">
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="4"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>T.C Kimlik No / Vergi No</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">
                        T.C Kimlik No / Vergi No
                      </IonLabel>
                      <IonInput
                        name="tcNoInput"
                        value={tcNo}
                        onIonChange={(e) => setTCNo(e.detail.value!)}
                        clearInput
                      />
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="tcNoInput"
                        value={tcNo}
                        onIonChange={(e) => setTCNo(e.detail.value!)}
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                {tcNoMessage && (
                  <IonRow>
                    <IonCol size="8" offset="4">
                      <IonText color="danger ion-padding-start">
                        {tcNoMessage}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="4"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Dönemi</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="stacked">Dönemi</IonLabel>
                      <IonSelect
                        name="yilInput"
                        interface="popover"
                        value={donem}
                        okText="Tamam"
                        cancelText="Vazgeç"
                        onIonChange={(e) => setDonem(e.detail.value)}
                      >
                        {yillar &&
                          yillar.map((val, i) => (
                            <IonSelectOption key={i} value={val}>
                              {val}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonSelect
                        name="yilInput"
                        interface="popover"
                        value={donem}
                        onIonChange={(e) => setDonem(e.detail.value)}
                      >
                        {yillar &&
                          yillar.map((val, i) => (
                            <IonSelectOption key={i} value={val}>
                              {val}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                {donemMessage && (
                  <IonRow>
                    <IonCol size="8" offset="4">
                      <IonText color="danger ion-padding-start">
                        {donemMessage}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="4"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Beyan Türü</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">Beyan Türü</IonLabel>
                      <IonSelect
                        name="beyanTuruInput"
                        interface="popover"
                        value={beyanTuru}
                        placeholder="Seçiniz..."
                        onIonChange={(e) => setBeyanTuru(e.detail.value)}
                      >
                        <IonSelectOption value="">Seçiniz</IonSelectOption>
                        <IonSelectOption value="Bina">Bina</IonSelectOption>
                        <IonSelectOption value="Arsa">Arsa</IonSelectOption>
                        <IonSelectOption value="Arazi">Arazi</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonSelect
                        name="beyanTuruInput"
                        interface="popover"
                        value={beyanTuru}
                        placeholder="Seçiniz..."
                        onIonChange={(e) => setBeyanTuru(e.detail.value)}
                      >
                        <IonSelectOption value="">Seçiniz</IonSelectOption>
                        <IonSelectOption value="Bina">Bina</IonSelectOption>
                        <IonSelectOption value="Arsa">Arsa</IonSelectOption>
                        <IonSelectOption value="Arazi">Arazi</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol sizeSm="6" size="12">
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="3"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Mahalle</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="stacked">Mahalle</IonLabel>
                      <IonSelect
                        name="mahalleInput"
                        interface="popover"
                        value={mahalle}
                        okText="Tamam"
                        cancelText="Vazgeç"
                        placeholder="Seçiniz..."
                        onIonChange={(e) => setMahalle(e.detail.value)}
                      >
                        {mahalleler &&
                          mahalleler.map((val) => (
                            <IonSelectOption key={val.id} value={val}>
                              {val.tanim}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonSelect
                        name="mahalleInput"
                        interface="popover"
                        value={mahalle}
                        placeholder="Seçiniz..."
                        onIonChange={(e) => setMahalle(e.detail.value)}
                      >
                        <IonSelectOption value="">Seçiniz</IonSelectOption>
                        {mahalleler &&
                          mahalleler.map((val) => (
                            <IonSelectOption key={val.id} value={val}>
                              {val.tanim}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                {mahalleMessage && (
                  <IonRow>
                    <IonCol size="8" offset="4">
                      <IonText color="danger ion-padding-start">
                        {mahalleMessage}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="3"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Ada</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">Ada</IonLabel>
                      <IonInput
                        name="adaInput"
                        value={ada}
                        type="number"
                        inputmode="numeric"
                        onIonChange={(e) => {
                          let val = e.detail.value;
                          if (val) {
                            return setAda(parseInt(val));
                          }
                          return setAda(undefined);
                        }}
                        clearInput
                      />
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="adaInput"
                        value={ada}
                        type="number"
                        inputmode="numeric"
                        onIonChange={(e) => {
                          let val = e.detail.value;
                          if (val) {
                            return setAda(parseInt(val));
                          }
                          return setAda(undefined);
                        }}
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                {adaMessage && (
                  <IonRow>
                    <IonCol size="8" offset="4">
                      <IonText color="danger ion-padding-start">
                        {adaMessage}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="3"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Parsel</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">Parsel</IonLabel>
                      <IonInput
                        name="parselInput"
                        value={parsel}
                        type="number"
                        inputmode="numeric"
                        onIonChange={(e) => {
                          let val = e.detail.value;
                          if (val) {
                            return setParsel(parseInt(val));
                          }
                          return setParsel(undefined);
                        }}
                        clearInput
                      />
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="parselInput"
                        value={parsel}
                        type="number"
                        inputmode="numeric"
                        onIonChange={(e) => {
                          let val = e.detail.value;
                          if (val) {
                            return setParsel(parseInt(val));
                          }
                          return setParsel(undefined);
                        }}
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                {parselMessage && (
                  <IonRow>
                    <IonCol size="8" offset="4">
                      <IonText color="danger ion-padding-start">
                        {parselMessage}
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="3"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Bğmsz.Bölüm</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">Bağımsız Bölüm</IonLabel>
                      <IonInput
                        name="bagimsizBolumInput"
                        value={bagimsizBolum}
                        onIonChange={(e) => {
                          let val = e.detail.value;
                          if (val) {
                            return setBagimsizBolum(val);
                          }
                          return setBagimsizBolum(undefined);
                        }}
                        clearInput
                      />
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="bagimsizBolumInput"
                        value={bagimsizBolum}
                        onIonChange={(e) => {
                          let val = e.detail.value;
                          if (val) {
                            return setBagimsizBolum(val);
                          }
                          return setBagimsizBolum(undefined);
                        }}
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <IonButton type="submit">Sorgula</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <Accordion
          activeIndex={aktifIndeks}
          onTabChange={(e) => setAktifIndeks(e.index)}
        >
          {sonuc &&
            sonuc.map((rayicBedel, i) => (
              <AccordionTab
                key={i}
                header={
                  <React.Fragment>
                    <span>{rayicBedel.adiSoyadiUnvan}</span>
                  </React.Fragment>
                }
              >
                <MUIDataTable
                  innerRef={elRefs[i]}
                  title={`Sayın, ${rayicBedel?.adiSoyadiUnvan} ${
                    rayicBedel?.detay.length > 0
                      ? "Beyan dökümünüz aşağıdaki gibidir."
                      : "Kayıtlı Emlak Beyanınız Bulunmamaktadır."
                  }`}
                  data={rayicBedel?.detay}
                  columns={columns}
                  options={options}
                />
              </AccordionTab>
            ))}
        </Accordion>
      </IonContent>
    </IonPage>
  );
};

export default RayicBedelSorgu;
