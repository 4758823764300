import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { home } from "ionicons/icons";
import React from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/authContext";
import { KurumBilgileri } from "../entities/KurumBilgileri";
import "./Page.css";

const AskidaBorcTahsilatTamam: React.FC = () => {
  const { logo, kurumBilgileri } = React.useContext(AuthContext);
  const { TahsilatId } = useParams<any>();

  React.useEffect(() => {
    window.history.pushState(
      {},
      kurumBilgileri?.kisaTanim + " - Derman Ol Kampanyası",
      "/askidayardim"
    );
  }, []);

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="end">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle className="ion-text-sm-wrap">
            <IonItem className="ion-margin-top ion-margin-bottom">
              <img src={logo} style={{ maxWidth: "3em", height: "auto" }} />
              <IonText
                className="ion-padding-start"
                style={{ fontSize: "1.5em" }}
              >
                {kurumBilgileri?.kisaTanim} - Derman Ol Kampanyası
              </IonText>
            </IonItem>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItemDivider>
                  <IonLabel>Tahsilat Sonucu</IonLabel>
                </IonItemDivider>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center ion-align-items-center">
              <IonCol size="1">
                <img src="assets/success.png" width="28" height="28" />
              </IonCol>
              <IonCol className="ion-text-start" size="11">
                <IonItem>
                  <IonText>
                    Tahsilat İşleminiz başarıyla sonuçlanmıştır. Teşekkür
                    ederiz.
                  </IonText>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center ion-align-items-center">
              <IonCol>
                <IonButton
                  size="default"
                  title="Anasayfaya dönmek için tıklayınız"
                  routerLink="/askidayardim"
                >
                  <IonIcon slot="start" icon={home} />
                  Anasayfa
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default AskidaBorcTahsilatTamam;
