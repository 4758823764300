import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import { InputMask } from "primereact/inputmask";
import React, { useEffect, useState } from "react";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import sorgulamaService from "../../services/SorgulamaService";
import userService from "../../services/UserService";
import BinaryData from "../entities/BinaryData";
import IstekSikayet from "../entities/IstekSikayet";
import Mahalle from "../entities/Mahalle";
import Sokak from "../entities/Sokak";
import "./Page.css";

const IstekSikayetBildirim: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [basvuruNedenleri, setBasvuruNedenleri] = useState<string[]>([]);
  const [konuBasliklari, setKonuBasliklari] = useState<string[]>([]);
  const [mahalleler, setMahalleler] = useState<Mahalle[]>([]);
  const [sikayetSokaklar, setSikayetSokaklar] = useState<Sokak[]>([]);
  const [ikametSokaklar, setIkametSokaklar] = useState<Sokak[]>([]);
  const [istek, setIstek] = useState<IstekSikayet>(new IstekSikayet());
  const [file, setFile] = useState<any>();
  const [kaptcha, setKaptcha] = useState<any>();

  React.useEffect(() => {
    setTitle("İstek / Şikayet Bildirimi");
    loadCaptchaEnginge(5);

    sorgulamaService
      .getBasvuruNedenleri()
      .then((data: string[]) => setBasvuruNedenleri(data));
    sorgulamaService
      .getKonuBasliklari()
      .then((data: string[]) => setKonuBasliklari(data));
    sorgulamaService
      .getMahalleler()
      .then((data: Mahalle[]) => setMahalleler(data));
  }, []);

  useEffect(() => {
    if (istek.sikayetMahalleId) {
      sorgulamaService
        .getSokaklar(istek.sikayetMahalleId)
        .then((data: Sokak[]) => setSikayetSokaklar(data));
    }
  }, [istek.sikayetMahalleId]);

  useEffect(() => {
    if (istek.ikametMahalleId) {
      sorgulamaService
        .getSokaklar(istek.ikametMahalleId)
        .then((data: Sokak[]) => setIkametSokaklar(data));
    }
  }, [istek.ikametMahalleId]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!istek.basvuruNedeni) {
      setShowToastMessage("Lütfen Başvuru Nedeni Bilgisini Giriniz!");
      return;
    }
    if (!istek.tcNo) {
      setShowToastMessage("Lütfen T.C. No Bilgisini Giriniz!");
      return;
    }
    if (!istek.adi) {
      setShowToastMessage("Lütfen Ad Bilgisini Giriniz!");
      return;
    }
    if (!istek.soyadi) {
      setShowToastMessage("Lütfen Soyadı Bilgisini Giriniz!");
      return;
    }
    if (!istek.eposta) {
      setShowToastMessage("Lütfen E-Posta Bilgisini Giriniz!");
      return;
    }
    if (!istek.konuAciklamasi) {
      setShowToastMessage("Lütfen Konu Açıklaması Bilgisini Giriniz!");
      return;
    }
    if (
      istek.basvuruNedeni === "Şikayet" &&
      (!istek.sikayetMahalleId || !istek.sikayetSokakId)
    ) {
      setShowToastMessage("Lütfen Şikayet Mahalle/Sokak Bilgisini Giriniz!");
      return;
    }

    if (!validateCaptcha(kaptcha)) {
      setShowToastMessage("Lütfen Resimdeki Kodu Doğru Giriniz!");
      return;
    }

    setShowLoading(true);

    userService
      .istekSikayetKaydet(istek)
      .then((msg) => {
        (document as any).getElementById("file-upload").value = "";
        setFile(null);
        setIstek((prev) => new IstekSikayet());
        setShowToastMessage(msg);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={15000}
          position="top"
          buttons={[
            {
              text: "Tamam",
              role: "cancel",
            },
          ]}
        />
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Başvuru Nedeni</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Başvuru Nedeni</IonLabel>
                  <IonSelect
                    name="basvuruNedeniInput"
                    interface="popover"
                    value={istek?.basvuruNedeni}
                    onIonChange={(e) =>
                      setIstek({ ...istek, basvuruNedeni: e.detail.value! })
                    }
                  >
                    {basvuruNedenleri &&
                      basvuruNedenleri.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="basvuruNedeniInput"
                    interface="popover"
                    value={istek?.basvuruNedeni}
                    placeholder="Seçiniz..."
                    onIonChange={(e) =>
                      setIstek({ ...istek, basvuruNedeni: e.detail.value! })
                    }
                  >
                    {basvuruNedenleri &&
                      basvuruNedenleri.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>T.C. Kimlik No</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">T.C. Kimlik No</IonLabel>
                  <IonInput
                    name="tcNoInput"
                    value={istek.tcNo}
                    onIonChange={(e) =>
                      setIstek({ ...istek, tcNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="tcNoInput"
                    value={istek.tcNo}
                    onIonChange={(e) =>
                      setIstek({ ...istek, tcNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Adı</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Adı</IonLabel>
                  <IonInput
                    name="adiInput"
                    value={istek.adi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, adi: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="adiInput"
                    value={istek.adi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, adi: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Soyadı</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Soyadı</IonLabel>
                  <IonInput
                    name="soyadiInput"
                    value={istek.soyadi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, soyadi: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="soyadiInput"
                    value={istek.soyadi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, soyadi: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>E-Posta Adresi</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">E-Posta</IonLabel>
                  <IonInput
                    name="epostaInput"
                    value={istek.eposta}
                    onIonChange={(e) =>
                      setIstek({ ...istek, eposta: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="epostaInput"
                    value={istek.eposta}
                    onIonChange={(e) =>
                      setIstek({ ...istek, eposta: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Telefonu</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="stacked" className="ion-padding-bottom">
                    Telefonu
                  </IonLabel>
                  <InputMask
                    id="phone"
                    mask="(999)-999 99 99"
                    unmask={true}
                    placeholder="(999)-999 99 99"
                    value={istek.telefon}
                    size={15}
                    onChange={(e) => setIstek({ ...istek, telefon: e.value })}
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <InputMask
                    id="phone"
                    mask="(999)-999 99 99"
                    unmask={true}
                    placeholder="(999)-999 99 99"
                    value={istek.telefon}
                    size={15}
                    onChange={(e) => setIstek({ ...istek, telefon: e.value })}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Cep Telefonu</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="stacked" className="ion-padding-bottom">
                    Cep Telefonu
                  </IonLabel>
                  <InputMask
                    id="mobilephonesmup"
                    mask="(999)-999 99 99"
                    unmask={true}
                    placeholder="(599)-999 99 99"
                    value={istek.cepTelefonu}
                    size={15}
                    onChange={(e) =>
                      setIstek({ ...istek, cepTelefonu: e.value })
                    }
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <InputMask
                    id="mobilephonesmdown"
                    mask="(999)-999 99 99"
                    unmask={true}
                    placeholder="(599)-999 99 99"
                    value={istek.cepTelefonu}
                    size={15}
                    onChange={(e) =>
                      setIstek({ ...istek, cepTelefonu: e.value })
                    }
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Konu Başlığı</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Konu Başlığı</IonLabel>
                  <IonSelect
                    name="konuBasligiInput"
                    interface="popover"
                    value={istek?.konuBasligi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, konuBasligi: e.detail.value! })
                    }
                  >
                    {konuBasliklari &&
                      konuBasliklari.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="konuBasligiInput"
                    interface="popover"
                    value={istek?.konuBasligi}
                    placeholder="Seçiniz..."
                    onIonChange={(e) =>
                      setIstek({ ...istek, konuBasligi: e.detail.value! })
                    }
                  >
                    {konuBasliklari &&
                      konuBasliklari.map((val, i) => (
                        <IonSelectOption key={i} value={val}>
                          {val}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end ion-margin-top">
                  <IonLabel>Konu Açıklaması</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Konu Açıklaması</IonLabel>
                  <IonTextarea
                    name="ackInput"
                    value={istek.konuAciklamasi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, konuAciklamasi: e.detail.value! })
                    }
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonTextarea
                    name="ackInput"
                    value={istek.konuAciklamasi}
                    onIonChange={(e) =>
                      setIstek({ ...istek, konuAciklamasi: e.detail.value! })
                    }
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6" offset="1" sizeSm="4" offsetSm="1">
                <IonItem className="ion-text-end">
                  <IonLabel>Gizli Şikayet Mi?</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4">
                <IonRadioGroup
                  value={istek.gizliSikayet}
                  onIonChange={(e) =>
                    setIstek({ ...istek, gizliSikayet: e.detail.value! })
                  }
                >
                  <IonGrid className="ion-no-padding">
                    <IonRow className="ion-nowrap">
                      <IonCol>
                        <IonItem>
                          <IonRadio slot="start" value="true" />
                          <IonLabel>Evet</IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol>
                        <IonItem>
                          <IonRadio slot="start" value="false" />
                          <IonLabel>Hayır</IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRadioGroup>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard color="primary" className="ion-text-center">
                  <IonCardHeader>ŞİKAYET ADRES BİLGİLERİ</IonCardHeader>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Bulunduğu Köy veya Mahalle</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">
                    Bulunduğu Köy veya Mahalle
                  </IonLabel>
                  <IonSelect
                    name="mahInput"
                    interface="popover"
                    value={istek?.sikayetMahalleId}
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetMahalleId: e.detail.value! })
                    }
                  >
                    {mahalleler &&
                      mahalleler.map((val, i) => (
                        <IonSelectOption key={i} value={val.id}>
                          {val.tanim}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="mahInput"
                    interface="popover"
                    value={istek?.sikayetMahalleId}
                    placeholder="Seçiniz..."
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetMahalleId: e.detail.value! })
                    }
                  >
                    {mahalleler &&
                      mahalleler.map((val, i) => (
                        <IonSelectOption key={i} value={val.id}>
                          {val.tanim}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Cadde veya Sokağı</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Cadde veya Sokağı</IonLabel>
                  <IonSelect
                    name="caddeInput"
                    interface="popover"
                    value={istek?.sikayetSokakId}
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetSokakId: e.detail.value! })
                    }
                  >
                    {sikayetSokaklar &&
                      sikayetSokaklar.map((val, i) => (
                        <IonSelectOption key={i} value={val.id}>
                          {val.tanim}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonSelect
                    name="caddeInput"
                    interface="popover"
                    value={istek?.sikayetSokakId}
                    placeholder="Seçiniz..."
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetSokakId: e.detail.value! })
                    }
                  >
                    {sikayetSokaklar &&
                      sikayetSokaklar.map((val, i) => (
                        <IonSelectOption key={i} value={val.id}>
                          {val.tanim}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Blok No / Kat No</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonGrid className="ion-hide-sm-up ion-no-padding">
                  <IonRow className="ion-nowrap">
                    <IonCol size="4">
                      <IonItem>
                        <IonLabel position="floating">Blok No</IonLabel>
                        <IonInput
                          name="sikayetBlokNoInput"
                          value={istek.sikayetBlokNo}
                          maxlength={15}
                          onIonChange={(e) =>
                            setIstek({
                              ...istek,
                              sikayetBlokNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="1">
                      <IonItem className="ion-padding-start ion-padding-end">
                        <IonText>-</IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size="4">
                      <IonItem>
                        <IonLabel position="floating">Kat No</IonLabel>
                        <IonInput
                          name="sikayetKatNoInput"
                          value={istek.sikayetKatNo}
                          maxlength={15}
                          onIonChange={(e) =>
                            setIstek({
                              ...istek,
                              sikayetKatNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="sikayetBlokNoInput"
                    value={istek.sikayetBlokNo}
                    maxlength={15}
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetBlokNo: e.detail.value! })
                    }
                    clearInput
                  />
                  <IonText className="ion-padding-start ion-padding-end">
                    /
                  </IonText>
                  <IonInput
                    name="sikayetKatNoInput"
                    value={istek.sikayetKatNo}
                    maxlength={15}
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetKatNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="4"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Kapı No / Daire No</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonGrid className="ion-hide-sm-up ion-no-padding">
                  <IonRow className="ion-nowrap">
                    <IonCol size="4">
                      <IonItem>
                        <IonLabel position="floating">Blok No</IonLabel>
                        <IonInput
                          name="sikayetKapiNoInput"
                          value={istek.sikayetKapiNo}
                          maxlength={15}
                          onIonChange={(e) =>
                            setIstek({
                              ...istek,
                              sikayetKapiNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="1">
                      <IonItem className="ion-padding-start ion-padding-end">
                        <IonText>-</IonText>
                      </IonItem>
                    </IonCol>
                    <IonCol size="4">
                      <IonItem>
                        <IonLabel position="floating">Daire No</IonLabel>
                        <IonInput
                          name="sikayetDaireNoInput"
                          value={istek.sikayetDaireNo}
                          maxlength={15}
                          onIonChange={(e) =>
                            setIstek({
                              ...istek,
                              sikayetDaireNo: e.detail.value!,
                            })
                          }
                          clearInput
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="sikayetKapiNoInput"
                    value={istek.sikayetKapiNo}
                    maxlength={15}
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetKapiNo: e.detail.value! })
                    }
                    clearInput
                  />
                  <IonText className="ion-padding-start ion-padding-end">
                    /
                  </IonText>
                  <IonInput
                    name="sikayetDaireNoInput"
                    value={istek.sikayetDaireNo}
                    maxlength={15}
                    onIonChange={(e) =>
                      setIstek({ ...istek, sikayetDaireNo: e.detail.value! })
                    }
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard color="primary" className="ion-text-center">
                  <IonCardHeader>İKAMET ADRES BİLGİLERİ</IonCardHeader>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="8" offset="0" sizeSm="8" offsetSm="1">
                <IonItem>
                  <IonText>Şikayet Adresi İle Aynı</IonText>
                  <IonCheckbox
                    color="primary"
                    checked={istek.ikametAdresiAyni}
                    slot="start"
                    onIonChange={(e) =>
                      setIstek({
                        ...istek,
                        ikametAdresiAyni: !istek.ikametAdresiAyni,
                      })
                    }
                  ></IonCheckbox>
                </IonItem>
              </IonCol>
            </IonRow>
            {!istek.ikametAdresiAyni && (
              <div>
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Bulunduğu Köy veya Mahalle</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">
                        Bulunduğu Köy veya Mahalle
                      </IonLabel>
                      <IonSelect
                        name="ikametMahInput"
                        interface="popover"
                        value={istek?.ikametMahalleId}
                        onIonChange={(e) =>
                          setIstek({
                            ...istek,
                            ikametMahalleId: e.detail.value!,
                          })
                        }
                      >
                        {mahalleler &&
                          mahalleler.map((val, i) => (
                            <IonSelectOption key={i} value={val.id}>
                              {val.tanim}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonSelect
                        name="ikametMahInput"
                        interface="popover"
                        value={istek?.ikametMahalleId}
                        placeholder="Seçiniz..."
                        onIonChange={(e) =>
                          setIstek({
                            ...istek,
                            ikametMahalleId: e.detail.value!,
                          })
                        }
                      >
                        {mahalleler &&
                          mahalleler.map((val, i) => (
                            <IonSelectOption key={i} value={val.id}>
                              {val.tanim}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Cadde veya Sokağı</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">Cadde veya Sokağı</IonLabel>
                      <IonSelect
                        name="ikametCaddeInput"
                        interface="popover"
                        value={istek?.ikametSokakId}
                        onIonChange={(e) =>
                          setIstek({ ...istek, ikametSokakId: e.detail.value! })
                        }
                      >
                        {ikametSokaklar &&
                          ikametSokaklar.map((val, i) => (
                            <IonSelectOption key={i} value={val.id}>
                              {val.tanim}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonSelect
                        name="ikametCaddeInput"
                        interface="popover"
                        value={istek?.ikametSokakId}
                        placeholder="Seçiniz..."
                        onIonChange={(e) =>
                          setIstek({ ...istek, ikametSokakId: e.detail.value! })
                        }
                      >
                        {ikametSokaklar &&
                          ikametSokaklar.map((val, i) => (
                            <IonSelectOption key={i} value={val.id}>
                              {val.tanim}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Blok No / Kat No</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonGrid className="ion-hide-sm-up ion-no-padding">
                      <IonRow className="ion-nowrap">
                        <IonCol size="4">
                          <IonItem>
                            <IonLabel position="floating">Blok No</IonLabel>
                            <IonInput
                              name="ikametBlokNoInput"
                              value={istek.ikametBlokNo}
                              maxlength={15}
                              onIonChange={(e) =>
                                setIstek({
                                  ...istek,
                                  ikametBlokNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol size="1">
                          <IonItem className="ion-padding-start ion-padding-end">
                            <IonText>-</IonText>
                          </IonItem>
                        </IonCol>
                        <IonCol size="4">
                          <IonItem>
                            <IonLabel position="floating">Kat No</IonLabel>
                            <IonInput
                              name="ikametKatNoInput"
                              value={istek.ikametKatNo}
                              maxlength={15}
                              onIonChange={(e) =>
                                setIstek({
                                  ...istek,
                                  ikametKatNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="ikametBlokNoInput"
                        value={istek.ikametBlokNo}
                        maxlength={15}
                        onIonChange={(e) =>
                          setIstek({ ...istek, ikametBlokNo: e.detail.value! })
                        }
                        clearInput
                      />
                      <IonText className="ion-padding-start ion-padding-end">
                        /
                      </IonText>
                      <IonInput
                        name="ikametKatNoInput"
                        value={istek.ikametKatNo}
                        maxlength={15}
                        onIonChange={(e) =>
                          setIstek({ ...istek, ikametKatNo: e.detail.value! })
                        }
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="4"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>Kapı No / Daire No</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonGrid className="ion-hide-sm-up ion-no-padding">
                      <IonRow className="ion-nowrap">
                        <IonCol size="4">
                          <IonItem>
                            <IonLabel position="floating">Blok No</IonLabel>
                            <IonInput
                              name="ikametKapiNoInput"
                              value={istek.ikametKapiNo}
                              maxlength={15}
                              onIonChange={(e) =>
                                setIstek({
                                  ...istek,
                                  ikametKapiNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol size="1">
                          <IonItem className="ion-padding-start ion-padding-end">
                            <IonText>-</IonText>
                          </IonItem>
                        </IonCol>
                        <IonCol size="4">
                          <IonItem>
                            <IonLabel position="floating">Daire No</IonLabel>
                            <IonInput
                              name="ikametDaireNoInput"
                              value={istek.ikametDaireNo}
                              maxlength={15}
                              onIonChange={(e) =>
                                setIstek({
                                  ...istek,
                                  ikametDaireNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="ikametKapiNoInput"
                        value={istek.ikametKapiNo}
                        maxlength={15}
                        onIonChange={(e) =>
                          setIstek({ ...istek, ikametKapiNo: e.detail.value! })
                        }
                        clearInput
                      />
                      <IonText className="ion-padding-start ion-padding-end">
                        /
                      </IonText>
                      <IonInput
                        name="ikametDaireNoInput"
                        value={istek.ikametDaireNo}
                        maxlength={15}
                        onIonChange={(e) =>
                          setIstek({ ...istek, ikametDaireNo: e.detail.value! })
                        }
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              </div>
            )}
            <IonRow>
              <IonCol size="10" offset="0" sizeSm="4" offsetSm="1">
                <IonItem className="ion-text-end">
                  <IonLabel>Belge Yükleme ( Yanlızca 1 Adet )</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="6" offset="1" offsetSm="0">
                <IonItem>
                  <input
                    type="file"
                    id="file-upload"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={async (_event: any) => {
                      const file = _event.target.files![0];
                      setFile(file);
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = function () {
                        const base64data = reader.result;
                        const belge = new BinaryData();
                        belge.data = base64data;
                        belge.fileName = file.name;
                        belge.contentType = file.type;
                        setIstek({
                          ...istek,
                          belge,
                        });
                      };
                    }}
                  />
                  <IonButton
                    slot="start"
                    size="default"
                    onClick={() =>
                      (document as any).getElementById("file-upload").click()
                    }
                  >
                    Dosya Seç
                  </IonButton>
                  <IonText>{file?.name}</IonText>
                  <IonButton
                    slot="end"
                    size="small"
                    color="light"
                    onClick={() => {
                      (document as any).getElementById("file-upload").value =
                        "";
                      setFile(null);
                    }}
                  >
                    X
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4" offset="0" sizeSm="4" offsetSm="1">
                <IonItem className="ion-text-center ion-float-end">
                  <LoadCanvasTemplate reloadText="Yenile" />
                </IonItem>
              </IonCol>
              <IonCol size="6" sizeSm="6" offset="1" offsetSm="0">
                <IonItem>
                  <IonInput
                    name="kaptchaInput"
                    value={kaptcha}
                    onIonChange={(e) => setKaptcha(e.detail.value!)}
                    clearInput
                  />
                  <IonNote slot="helper">
                    Sol taraftaki resimde görünen yazıları yukarıdaki alana
                    giriniz !
                  </IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="5">
                <IonButton type="submit">Gönder</IonButton>
                <IonButton
                  type="button"
                  color="success"
                  onClick={() => {
                    (document as any).getElementById("file-upload").value = "";
                    setFile(null);
                    setIstek((prev) => new IstekSikayet());
                  }}
                >
                  Temizle
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default IstekSikayetBildirim;
