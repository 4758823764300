import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  IonToast,
} from "@ionic/react";
import { printOutline } from "ionicons/icons";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import { CtvBildirim } from "../entities/CtvBildirim";
import "./Page.css";

const CtvBildirimSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [sicilNo, setSicilNo] = useState<number>();
  const loadingData = [
    { beyanNo: "", sicilNo: "", adi: "", paftaNo: "", adaNo: "", parselNo: "" },
    { beyanNo: "", sicilNo: "", adi: "", paftaNo: "", adaNo: "", parselNo: "" },
    { beyanNo: "", sicilNo: "", adi: "", paftaNo: "", adaNo: "", parselNo: "" },
    { beyanNo: "", sicilNo: "", adi: "", paftaNo: "", adaNo: "", parselNo: "" },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const loadingColumnArray = [
    { field: "beyanNo", header: "Beyan No" },
    { field: "sicilNo", header: "Bina Sah.Sicil No" },
    { field: "adi", header: "Bina Sah.Adı Soyadı" },
    { field: "paftaNo", header: "Pafta No" },
    { field: "adaNo", header: "Ada No" },
    { field: "parselNo", header: "Parsel No" },
  ];
  const [sonuc, setSonuc] = useState<CtvBildirim[]>([]);

  React.useEffect(() => {
    setTitle("Çevre Bildirim Sorgulama");
  }, []);

  const getCtvBildirim = (event: any) => {
    event.preventDefault();
    setLoading(true);
    userService
      .getCtvBildirim(sicilNo)
      .then((data: CtvBildirim[]) => setSonuc(data))
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setLoading(false));
  };

  const raporGoster = (actionURL: string, id: string) => {
    setShowLoading(true);
    userService
      .bildirimRaporuYazdir(actionURL, id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ctv.bildirim.rapor.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const loadingBodyTemplate = (header: string) => {
    return <IonSkeletonText animated />;
  };

  const bodyTemplate = (header: string, field: string, rowData: any) => {
    return <IonText>{rowData[field]}</IonText>;
  };

  const detayTemplate = (rowData: CtvBildirim) => {
    return (
      <IonButton
        type="button"
        fill="clear"
        title="Yazdır"
        color="dark"
        onClick={() => raporGoster(rowData.actionURL, rowData.id)}
      >
        <IonIcon icon={printOutline} />
      </IonButton>
    );
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={2000}
          position="top"
        />
        <form
          onSubmit={(e) => {
            getCtvBildirim(e);
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol
                size="4"
                offset="0"
                sizeSm="3"
                offsetSm="1"
                className="ion-hide-sm-down"
              >
                <IonItem className="ion-text-end">
                  <IonLabel>Bina Sahibi Sicil No</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                <IonItem className="ion-hide-sm-up">
                  <IonLabel position="floating">Bina Sahibi Sicil No</IonLabel>
                  <IonInput
                    name="sicilNoInput"
                    value={sicilNo}
                    maxlength={11}
                    onIonChange={(e) => {
                      let val = e.detail.value;
                      if (val) {
                        return setSicilNo(parseInt(val));
                      }
                      return setSicilNo(undefined);
                    }}
                    clearInput
                  />
                </IonItem>
                <IonItem className="ion-hide-sm-down">
                  <IonInput
                    name="sicilNoInput"
                    value={sicilNo}
                    maxlength={11}
                    placeholder="Sicil No..."
                    onIonChange={(e) => {
                      let val = e.detail.value;
                      if (val) {
                        return setSicilNo(parseInt(val));
                      }
                      return setSicilNo(undefined);
                    }}
                    clearInput
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <IonButton type="submit">Sorgula</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonCard>
            <IonCardContent className="datatable-responsive">
              {loading && (
                <DataTable
                  value={loadingData}
                  paginator
                  rows={10}
                  removableSort
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  {loadingColumnArray.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      body={() => loadingBodyTemplate(col.header)}
                      headerClassName="ion-text-center"
                    />
                  ))}
                  <Column
                    field="detay"
                    header="Detay"
                    body={() => loadingBodyTemplate("Detay")}
                    headerClassName="ion-text-center"
                  />
                </DataTable>
              )}
              {!loading && (
                <DataTable
                  value={sonuc}
                  paginator
                  rows={10}
                  emptyMessage=""
                  stripedRows
                  size="small"
                  responsiveLayout="stack"
                  breakpoint="960px"
                >
                  {loadingColumnArray.map((col, index) => (
                    <Column
                      key={index}
                      field={col.field}
                      header={col.header}
                      headerClassName="ion-text-center"
                      body={(rowData) =>
                        bodyTemplate(col.header, col.field, rowData)
                      }
                    />
                  ))}
                  <Column
                    field="detay"
                    header="Detay"
                    body={(rowData) => detayTemplate(rowData)}
                    headerClassName="ion-text-center"
                  />
                </DataTable>
              )}
            </IonCardContent>
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default CtvBildirimSorgu;
