import React from "react";
import { AuthContext } from "../../providers/authContext";
import Header from "../../components/Header";
import { IonContent, IonPage } from "@ionic/react";
import { SERVER_URL } from "../../services/constants";

const KVKK: React.FC = () => {
  const { kurumBilgileri, setTitle } = React.useContext(AuthContext);

  React.useEffect(() => {
    setTitle(kurumBilgileri?.kisaTanim! + " K.V.K.K AYDINLATMA METNİ");
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen className="ion-padding">
        <h3>
          <b>
            E-BELEDİYE HİZMETLERİNE ve BAŞVURULARINA İLİŞKİN AYDINLATMA METNİ
          </b>
        </h3>

        <div style={{ textAlign: "justify" }}>
          <span style={{ textAlign: "justify" }}>
            <h6>
              Değerli Vatandaşlarımız; Kişisel verilerin işlenmesinde başta özel
              hayatın gizliliği olmak üzere kişilerin temel hak ve
              özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel
              kişilerin yükümlülükleri belirlemek amacıyla çıkarılmış olan 6698
              sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) hakkında
              sizleri bilgilendirmek isteriz. {kurumBilgileri?.kisaTanim} olarak
              kişisel verilerinizin güvenliği hususuna azami hassasiyet
              göstermekteyiz. Bu bilinçle, hizmet faaliyetlerimizden faydalanan
              vatandaşlarımız dahil, {kurumBilgileri?.kisaTanim} bünyesinde
              ilişkili tüm şahıslara ait her türlü kişisel verilerin 6698 sayılı
              Kişisel Verilerin Korunması Kanunu (“KVK Kanunu”)’na uygun olarak
              işlenerek, muhafaza edilmesine büyük önem atfetmekteyiz. Bu
              sorumluluğumuzun tam idraki ile KVK Kanunu’nda tanımlı şekli ile
              “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi aşağıda izah
              edildiği surette ve mevzuat tarafından verilen sınırlar
              çerçevesinde işlemekteyiz. KVK Kanunu Hakkında detaylı bilgi almak
              için Kişisel Verileri Koruma Kurumu’nun internet sayfasını ziyaret
              edebilirsiniz.
            </h6>
            <h4>
              <b>
                1. {kurumBilgileri?.kisaTanim} kişisel verilerin toplanmasında
                hangi yöntemleri kullanıyor
              </b>
            </h4>
            <h6>
              {SERVER_URL} ve diğer kurumsal web sitelerimiz , e-belediye ,
              {kurumBilgileri?.kisaTanim} e- devlet kısmı üzerinden işlem yapan
              vatandaşlarımızın verdikleri veriler, vatandaşlarımızın rızaları
              ve mevzuat hükümleri uyarınca {kurumBilgileri?.kisaTanim}{" "}
              tarafından işlenmektedir.
            </h6>
            <h4>
              <b>
                2. Kayıtlara Ait Kişisel Verilerin İşlenme Amacı ve Aktarımı
              </b>
            </h4>
            <h6>
              Kişisel verileriniz şu amaçlarla kullanılmaktadır:{" "}
              <ul>
                <li>
                  Kurumsal web sitemiz ve mobil uygulamalar üzerinden işlem
                  yapanın / yaptıranın kimlik bilgilerini teyit etmek,
                </li>
                <li>
                  Halk Masası üzerinden yapılan bildirimlere dair iletişim için
                  adres ve diğer gerekli bilgileri kaydetmek,
                </li>
                <li>
                  Elektronik ortam ve platformlarda (internet/mobil vs.) veya
                  kağıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri
                  düzenlemek,
                </li>
                <li>
                  Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat
                  gereği kamu görevlilerine bilgi verebilmek,
                </li>
                <li>
                  Vatandaşlarımızın memnuniyetini artırmak, web sitesi ve/veya
                  mobil uygulamalardan işlem yapan vatandaşlarımızı tanıyabilmek
                  ve vatandaş çevresi analizinde kullanabilmek, çeşitli kamusal
                  faaliyetlerinde kullanabilmek ve bu kapsamda anlaşmalı
                  kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki
                  ortamda anketler düzenlemek,
                </li>
                <li>
                  Vatandaşlarımıza öneri sunabilmek, hizmetlerimizle ilgili
                  vatandaşlarımızı bilgilendirebilmek,
                </li>
                <li>
                  Hizmetlerimiz ile ilgili şikayet, istek ve önerilerini
                  değerlendirebilmek,
                </li>
                <li>
                  Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki
                  mevzuattan doğan haklarımızı kullanabilmek,
                </li>
              </ul>
            </h6>
            <h4>
              <b>
                3. {kurumBilgileri?.kisaTanim} kişisel verileri hangi koşullar
                altında korunuyor?
              </b>
            </h4>
            <h6>
              {kurumBilgileri?.kisaTanim} ile paylaşılan kişisel veriler,
              {kurumBilgileri?.kisaTanim} gözetimi ve kontrolü altındadır.
              {kurumBilgileri?.kisaTanim}, yürürlükteki ilgili mevzuat hükümleri
              gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla
              gerekli organizasyonu kurmak ve teknik önlemleri almak ve
              uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu
              üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri
              gizliliğini konu alan teknik standartlara uygun surette periyodik
              aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri
              işleme politikalarımızı her zaman güncellediğimizi bilginize
              sunarız.
            </h6>
            <h4>
              <b>
                4. Kişisel Verilerin Korunması Kanunu’ndan doğan haklarınız
                nelerdir?
              </b>
            </h4>
            <h6>
              Belediyemize başvurarak KVKK uyarınca kişisel verilerinizin;{" "}
              <ul>
                <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                <li>
                  Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                </li>
                <li>
                  Kişisel verilerin işlenme amacını ve bunların amacına uygun
                  kullanılıp kullanılmadığını öğrenme,
                </li>
                <li>
                  Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                  üçüncü kişileri bilme,
                </li>
                <li>
                  Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                  bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
                  kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
                  isteme,
                </li>
                <li>
                  KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak
                  işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                  ortadan kalkması hâlinde kişisel verilerin silinmesini veya
                  yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                  verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                </li>
                <li>
                  İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                  analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                  ortaya çıkmasına itiraz etme,
                </li>
                <li>
                  Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğraması hâlinde zararın giderilmesini talep etme
                </li>
              </ul>
              haklarına sahiptir. Bu amaçlarla yaptığınız başvurunun ek bir
              maliyet gerektirmesi durumunda, Kişisel Verileri Koruma Kurulu
              tarafından belirlenecek tarifedeki ücret tutarını ödemeniz
              gerekebilir. Başvurunuzda yer alan talepleriniz, talebin
              niteliğine göre en kısa sürede ve en geç 30 (otuz) gün içinde
              sonuçlandırılacaktır.
            </h6>
            <h6>
              Kanun kapsamında başvuru yapabileceğiniz veri sorumlusu:{" "}
              {kurumBilgileri?.kisaTanim}
            </h6>
          </span>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(KVKK);
