import { useIonRouter } from "@ionic/react";
import React, { useEffect } from "react";
import { AuthContext } from "../../providers/authContext";

const Logout: React.FC = () => {
  // const userStr = sessionStorage.getItem("user");
  // const user = userStr && JSON.parse(userStr);
  // const userHasRequiredRole = user && ROLE.User === user.detail?.role;
  // const [isAuth, setAuth] = useState<boolean>(!!user && userHasRequiredRole);

  const { logOut } = React.useContext(AuthContext);
  const navigation = useIonRouter();

  useEffect(() => {
    logout();
  }, []);

  const logout = async () => {
    await logOut();
    navigation.push("/page/AnaSayfa", "root");
  };

  return <></>;
};

export default Logout;
