import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToast,
  isPlatform,
} from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import tahsilatService from "../../services/TahsilatService";

import { AuthContext } from "../../providers/authContext";
import "./Page.css";
import Header from "../../components/Header";

const BorcTahsilatTamam: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const { search } = useLocation<any>();

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();
  const [tahsilatKaydedildi, setTahsilatKaydedildi] = useState<boolean>(true);
  const [tahsilatHata, setTahsilatHata] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [tahsilatId, setTahsilatId] = useState<any>();

  React.useEffect(() => {
    setTitle("Tahsilat Sonucu");
    const params = new URLSearchParams(search);
    setTahsilatId(params.get("tid"));
    window.history.pushState({}, "", "/");
  }, []);

  const makbuzYaz = () => {
    setShowLoading(true);
    tahsilatService
      .makbuzYaz(tahsilatId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "makbuz.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  const epostaGonder = () => {
    if (!email) {
      setShowToastMessage("Lütfen geçerli bir e-posta adresi girin!");
      return;
    }
    setShowLoading(true);
    tahsilatService
      .epostaGonder(email, tahsilatId)
      .then(() =>
        setShowToastMessage(
          "Eposta gönderildi. Lütfen Gelen Kutusunu veya Spam Klasörünü kontrol ediniz."
        )
      )
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        setShowToastMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Lütfen Bekleyin..."}
        />
        <IonToast
          isOpen={showToastMessage !== undefined}
          onDidDismiss={() => setShowToastMessage(undefined)}
          message={showToastMessage}
          duration={3000}
          position="middle"
        />
        {tahsilatKaydedildi && (
          <IonItem>
            <IonGrid>
              <IonRow className="ion-text-center ion-align-items-center">
                <IonCol size="1">
                  <img src="assets/success.png" width="28" height="28" />
                </IonCol>
                <IonCol className="ion-text-start" size="11">
                  <IonItem>
                    <IonText>
                      Tahsilat İşleminiz başarıyla sonuçlanmıştır.
                    </IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonText>
                      Makbuz çıktısını almak için butonu kullanabilirsiniz.
                    </IonText>
                    <IonButton slot="end" onClick={() => makbuzYaz()}>
                      Makbuz Yaz
                    </IonButton>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonIcon slot="start" icon={mailOutline}></IonIcon>
                    <IonText>
                      Makbuzunuzu E-posta olarak almak isterseniz aşağıdaki
                      kutucuğa mail adresinizi girip gönderebilirsiniz.
                    </IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">E-posta adresi</IonLabel>
                    <IonInput
                      type="email"
                      inputmode="email"
                      name="emailInput"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value!)}
                      clearInput
                    />
                    <IonButton
                      size="default"
                      slot="end"
                      onClick={() => epostaGonder()}
                    >
                      Gönder
                    </IonButton>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        )}
        {tahsilatHata && (
          <IonItem>
            <IonGrid>
              <IonRow className="ion-text-center">
                <IonCol size="1">
                  <img src="assets/denied.png" />
                </IonCol>
                <IonCol>
                  <IonLabel>{tahsilatHata}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(BorcTahsilatTamam);
