import axios from "axios";
import {
  KURUM_BILGILERI_URL,
  KURUM_LOGO_URL,
  ARSA_DEGER_SORGU_URL,
  ARSA_DEGER_DONEM_URL,
  ARSA_DEGER_MAH_URL,
  BINA_DEGER_DONEM_URL,
  BINA_KULLANIM_SEKILLERI_URL,
  BINA_INSAAT_SINIFLARI_URL,
  BINA_DEGER_SORGU_URL,
  BINA_INSAAT_TURLERI_URL,
  BINA_ASINMA_PAYLARI_SORGU_URL,
  ARAZI_DEGER_DONEM_URL,
  ARAZI_DEGER_SORGU_URL,
  CEVRE_DONEM_URL,
  CEVRE_GRUP_DERECE_SORGU_URL,
  KARAR_ENCUMEN_DONEM_URL,
  KARAR_ENCUMEN_SORGU_URL,
  KARAR_MECLIS_DONEM_URL,
  KARAR_MECLIS_SORGU_URL,
  ISTEK_SIKAYET_SORGU_URL,
  MUKELLEF_KONTROL_SORGU_URL,
  BORC_SORGU_URL,
  DOGUM_TARIHI_DOGRULAMA_URL,
  KOD_ALMA_URL,
  KOD_DOGRULAMA_URL,
  BASVURU_NEDENLERI_URL,
  KONU_BASLIKLARI_URL,
  MAHALLELER_URL,
  SOKAKLAR_URL,
} from "./constants";
import BinaDegerFilter from "../ebelediye/entities/sorgu/BinaDegerFilter";
import ArsaDegerFilter from "../ebelediye/entities/sorgu/ArsaDegerFilter";

class SorgulamaService {
  getKurumBilgileri() {
    return axios.post(KURUM_BILGILERI_URL).then((res) => res.data);
  }

  getKurumLogo() {
    return axios.get(KURUM_LOGO_URL).then((res) => res.data);
  }

  getArsaDegerDonemler() {
    return axios.get(ARSA_DEGER_DONEM_URL).then((res) => res.data);
  }

  getArsaDegerMahalleler(yil: number) {
    return axios.get(`${ARSA_DEGER_MAH_URL}/${yil}`).then((res) => res.data);
  }

  getArsaDegers(params: ArsaDegerFilter) {
    return axios.post(ARSA_DEGER_SORGU_URL, params).then((res) => res.data);
  }

  getBinaDegerDonemler() {
    return axios.get(BINA_DEGER_DONEM_URL).then((res) => res.data);
  }

  getBinaKullanimSekilleri(yil: number) {
    return axios
      .get(`${BINA_KULLANIM_SEKILLERI_URL}/${yil}`)
      .then((res) => res.data);
  }

  getBinaInsaatSiniflari(yil: number, kullanimSekliId: string) {
    return axios
      .get(`${BINA_INSAAT_SINIFLARI_URL}/${yil}/${kullanimSekliId}`)
      .then((res) => res.data);
  }

  getBinaDegers(params: BinaDegerFilter) {
    return axios.post(BINA_DEGER_SORGU_URL, params).then((res) => res.data);
  }

  getBinaInsaatTurleri() {
    return axios.get(BINA_INSAAT_TURLERI_URL).then((res) => res.data);
  }

  getBinaAsinmaPaylari(insaatTuruId: string) {
    return axios
      .get(`${BINA_ASINMA_PAYLARI_SORGU_URL}/${insaatTuruId}`)
      .then((res) => res.data);
  }

  getAraziDegerDonemler() {
    return axios.get(ARAZI_DEGER_DONEM_URL).then((res) => res.data);
  }

  getAraziDegerler(donem: number) {
    return axios
      .get(`${ARAZI_DEGER_SORGU_URL}/${donem}`)
      .then((res) => res.data);
  }

  getCevreDonemler() {
    return axios.get(CEVRE_DONEM_URL).then((res) => res.data);
  }

  getCevreGrupDereceDegerler(donem: number) {
    return axios
      .get(`${CEVRE_GRUP_DERECE_SORGU_URL}/${donem}`)
      .then((res) => res.data);
  }

  getKararEncumenDonemler() {
    return axios.get(KARAR_ENCUMEN_DONEM_URL).then((res) => res.data);
  }

  getKararEncumenler(donem: number, icerik: string | undefined) {
    return axios
      .post(KARAR_ENCUMEN_SORGU_URL, { donem, icerik })
      .then((res) => res.data);
  }

  getKararMeclisDonemler() {
    return axios.get(KARAR_MECLIS_DONEM_URL).then((res) => res.data);
  }

  getKararMeclisler(donem: number, icerik: string | undefined) {
    return axios
      .post(KARAR_MECLIS_SORGU_URL, { donem, icerik })
      .then((res) => res.data);
  }

  getIstekSikayetler(takipNo: string, eposta: string) {
    return axios
      .post(ISTEK_SIKAYET_SORGU_URL, { takipNo, eposta })
      .then((res) => res.data);
  }

  mukellefKontrol(tcNo: string, tip: string) {
    return axios
      .post(MUKELLEF_KONTROL_SORGU_URL, { tcNo, tip })
      .then((res) => res.data);
  }

  getBorclar(tcNo: string) {
    return axios.post(BORC_SORGU_URL, { tcNo }).then((res) => res.data);
  }

  dogumTarihiDogrula(tcNo: string, dogumTarihi: string) {
    return axios
      .post(DOGUM_TARIHI_DOGRULAMA_URL, { tcNo, dogumTarihi })
      .then((res) => res.data);
  }

  kodAl(tcNo: string, tip: string) {
    return axios.post(KOD_ALMA_URL, { tcNo, tip }).then((res) => res.data);
  }

  kodDogrula(tcNo: string, tip: string, kod: string) {
    return axios
      .post(KOD_DOGRULAMA_URL, { tcNo, tip, kod })
      .then((res) => res.data);
  }
  getBasvuruNedenleri() {
    return axios.get(BASVURU_NEDENLERI_URL).then((res) => res.data);
  }
  getKonuBasliklari() {
    return axios.get(KONU_BASLIKLARI_URL).then((res) => res.data);
  }
  getMahalleler() {
    return axios.get(MAHALLELER_URL).then((res) => res.data);
  }
  getSokaklar(sikayetMahalleId: string) {
    return axios
      .get(`${SOKAKLAR_URL}/${sikayetMahalleId}`)
      .then((res) => res.data);
  }
}

const sorgulamaService = new SorgulamaService();
export default sorgulamaService;
