import axios from "axios";
import {
  DONEM_BILGILERI_URL,
  KURUM_BILGILERI_URL,
  RAYIC_MAHALLELER_URL,
  RAYIC_BEDEL_BILGILERI_URL,
  RAYIC_BILDIRIM_RAPOR_URL,
} from "../../services/constants";

class SorgulamaService {
  getKurumBilgileri() {
    return axios.post(KURUM_BILGILERI_URL).then((res) => res.data);
  }

  getDonemler() {
    const userInfo = sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        DONEM_BILGILERI_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }

  getMahalleler() {
    const userInfo = sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        RAYIC_MAHALLELER_URL,
        {},
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }

  getRayicBedeller(
    tcNo: string | undefined,
    donem: number,
    beyanTuru: string | undefined,
    mahalleId: string | undefined,
    adaNo: number | undefined,
    parselNo: number | undefined,
    bagimsizBolumNo: string | undefined
  ) {
    const userInfo = sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .post(
        RAYIC_BEDEL_BILGILERI_URL,
        { tcNo, donem, beyanTuru, mahalleId, adaNo, parselNo, bagimsizBolumNo },
        {
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }

  rayicBildirimRaporuYazdir(
    mukellefId: string,
    donem: number,
    beyanIds: string[],
    raporTur: number
  ) {
    const userInfo = sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .get(
        `${RAYIC_BILDIRIM_RAPOR_URL}/${mukellefId}/${donem}/${beyanIds}/${raporTur}`,
        {
          responseType: "blob",
          auth: {
            username: user?.username,
            password: user?.password,
          },
        }
      )
      .then((res) => res.data);
  }

  rayicBildirimListesiYazdir(
    mukellefId: string,
    donem: number,
    beyanTuru: string | undefined
  ) {
    const userInfo = sessionStorage.getItem("user");
    let user;
    if (userInfo) {
      user = JSON.parse(userInfo);
    }
    return axios
      .get(`${RAYIC_BILDIRIM_RAPOR_URL}/${mukellefId}/${donem}/${beyanTuru}`, {
        responseType: "blob",
        auth: {
          username: user?.username,
          password: user?.password,
        },
      })
      .then((res) => res.data);
  }
}

const sorgulamaService = new SorgulamaService();
export default sorgulamaService;
