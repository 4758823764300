import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { sha512 } from "js-sha512";
import { User } from "../../ebelediye/entities/User";
import { KullaniciTip } from "../../enums/KullaniciTip";
import { ROLE } from "../../enums/ROLE";
import { AuthContext } from "../../providers/authContext";
import userService from "../../services/UserService";
import Header from "../components/Header";
import "./Login.css";
import {
  EDEVLET_AUTHORIZATION_CONTROLLER,
  EDEVLET_LOGIN,
} from "../../services/constants";
import { sha256 } from "js-sha256";

const TapuLogin: React.FC = () => {
  const { kurumBilgileri, logo } = React.useContext(AuthContext);
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const [usernameMessage, setUsernameMessage] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const [passwordMessage, setPasswordMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showToastMessage, setShowToastMessage] = useState<string>();

  const handleLogin = () => {
    let errorFound = false;
    setUsernameMessage(undefined);
    setPasswordMessage(undefined);
    if (!username) {
      setUsernameMessage("* Lütfen Kullanıcı Adınızı Giriniz!");
      errorFound = true;
    }
    if (!username.startsWith("tapu")) {
      setUsernameMessage("* Lütfen Geçerli Bir Kullanıcı Adı Giriniz!");
      errorFound = true;
    }
    if (!password) {
      setPasswordMessage("* Lütfen Şifrenizi Giriniz!");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }
    setLoading(true);
    const user = new User(
      {
        sicilNo: -1,
        tip: KullaniciTip.TAPU,
        adSoyad: undefined,
        role: ROLE.Tapu,
        menus: [],
      },
      username!,
      sha512(password!)
    );
    userService
      .login(user)
      .then(() => {
        setUsername("");
        setPassword("");
        history.push("/tapu");
      })
      .catch((err) => {
        console.error(err.response);
        if (err.response?.status === 401) {
          setShowToastMessage("Kullanıcı Adı ve/veya Şifre Yanlış");
        } else {
          setShowToastMessage(err.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage>
      <Header
        title={kurumBilgileri?.kisaTanim}
        subtitle="Vergi Değeri Sorgulama"
        logo={logo}
        roles={[ROLE.Tapu]}
      />
      <IonContent fullscreen>
        <div className="container">
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonToast
            isOpen={showToastMessage !== undefined}
            onDidDismiss={() => setShowToastMessage(undefined)}
            message={showToastMessage}
            duration={2000}
            position="top"
          />
          <IonCard className="login-card">
            <IonCardHeader color="primary">
              <IonCardTitle>Kullanıcı Girişi</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <IonItem>
                  <IonLabel position="floating">Kullanıcı Adı</IonLabel>
                  <IonInput
                    name="usernameInput"
                    value={username}
                    onIonChange={(e) => setUsername(e.detail.value!)}
                    clearInput
                  />
                  {usernameMessage && (
                    <IonText color="warning">{usernameMessage}</IonText>
                  )}
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Şifre</IonLabel>
                  <IonInput
                    name="passwordInput"
                    value={password}
                    type="password"
                    onIonChange={(e) => setPassword(e.detail.value!)}
                    clearInput
                  />
                  {passwordMessage && (
                    <IonText color="warning">{passwordMessage}</IonText>
                  )}
                </IonItem>

                {kurumBilgileri?.eDevletClientId && (
                  <IonButton
                    slot="start"
                    type="button"
                    color="danger"
                    href={EDEVLET_AUTHORIZATION_CONTROLLER.replace(
                      /\${eDevletAuthorizationController}/g,
                      kurumBilgileri!.eDevletAuthorizationController
                    )
                      .replace(/\${clientId}/g, kurumBilgileri!.eDevletClientId)
                      .replace(/\${redirectUri}/g, EDEVLET_LOGIN + "/tapu")
                      .replace(/\${state}/g, sessionStorage.getItem("rnd_val")!)
                      .replace(
                        /\${codeChallenge}/g,
                        btoa(sha256(kurumBilgileri!.kurumKodu))
                      )}
                    style={{ textTransform: "none" }}
                  >
                    <IonIcon slot="start" src="assets/edevlet.svg" /> e-Devlet
                    ile Giriş
                  </IonButton>
                )}
                <IonButton type="submit">Giriş</IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TapuLogin;
