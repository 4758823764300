import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
  IonTitle,
  useIonRouter,
} from "@ionic/react";
import { help } from "ionicons/icons";
import { sha256 } from "js-sha256";
import { sha512 } from "js-sha512";
import { InputMask } from "primereact/inputmask";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Kisi } from "../ebelediye/entities/Kisi";
import { User } from "../ebelediye/entities/User";
import { KullaniciTip } from "../enums/KullaniciTip";
import { ROLE } from "../enums/ROLE";
import { AuthContext } from "../providers/authContext";
import userService from "../services/UserService";
import {
  EDEVLET_AUTHORIZATION_CONTROLLER,
  EDEVLET_LOGIN,
} from "../services/constants";
import Header from "./Header";

const Login: React.FC = () => {
  const { setTitle, logIn, kurumBilgileri } = React.useContext(AuthContext);
  const navigation = useIonRouter();
  const [username, setUsername] = useState<string>("");
  const [usernameMessage, setUsernameMessage] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const [passwordMessage, setPasswordMessage] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [sifreHatirlatmaHataMessage, setSifreHatirlatmaHataMessage] =
    useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<string>("eposta");
  const [showForgotPasswordModal, setShowForgotPasswordModal] =
    useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [infoModalMessage, setInfoModalMessage] = useState<string>();
  const [kisi, setKisi] = useState<Kisi>(new Kisi("", "", "", "", "", "", ""));

  React.useEffect(() => {
    setTitle("Üye Girişi");
    const val = uuidv4();
    sessionStorage.setItem("rnd_val", val.split("-")[0]);
  }, []);

  const handleLogin = () => {
    let errorFound = false;
    setMessage(undefined);
    setUsernameMessage(undefined);
    setPasswordMessage(undefined);
    if (!username) {
      setUsernameMessage("* Lütfen Kullanıcı Adınızı Giriniz!");
      errorFound = true;
    }
    if (!password) {
      setPasswordMessage("* Lütfen Şifrenizi Giriniz!");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }
    setLoading(true);
    const user = new User(
      {
        sicilNo: -1,
        tip: KullaniciTip.BIREYSEL,
        adSoyad: undefined,
        role: ROLE.User,
        menus: [],
      },
      username!,
      sha512(password!)
    );
    userService
      .login(user)
      .then(async () => {
        setUsername("");
        setPassword("");
        await logIn(user);
        navigation.push("/page/MemberWelcome", "root");
      })
      .catch((err) => {
        console.error(err.response);
        if (err.response?.status === 401) {
          setMessage("Kullanıcı Adı ve/veya Şifre Yanlış");
        } else {
          setMessage(err.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const sifreHatirlatKisi = (event: any) => {
    event.preventDefault();
    if (!kisi.tcKimlikNo) {
      setSifreHatirlatmaHataMessage("Lütfen T.C. No Bilgisini Giriniz!");
      return;
    }
    if (kisi.tcKimlikNo.length !== 11) {
      setSifreHatirlatmaHataMessage(
        "Lütfen T.C Kimlik No Bilgisini Doğru Giriniz!"
      );
      return;
    }
    if ((!kisi.cuzdanSeri || !kisi.cuzdanNo) && !kisi.cuzdanSeriNo) {
      setSifreHatirlatmaHataMessage(
        "Lütfen Cüzdan Serisi ve Numarası Bilgilerini Giriniz!"
      );
      return;
    }
    if (selectedMethod === "cep" && !kisi.cepTel) {
      setSifreHatirlatmaHataMessage("Lütfen Cep Telefonu Bilgisini Giriniz!");
      return;
    }
    if (selectedMethod === "eposta" && !kisi.eposta) {
      setSifreHatirlatmaHataMessage("Lütfen Geçerli bir E-Posta Giriniz!");
      return;
    }
    setLoading(true);
    if (selectedMethod === "cep") {
      kisi.eposta = "";
    }
    if (selectedMethod === "eposta") {
      kisi.cepTel = "";
    }
    userService
      .sifreHatirlatKisi(kisi)
      .then((msg) => {
        setInfoModalMessage(msg);
        setShowInfoModal(true);
        setKisi(new Kisi("", "", "", "", "", "", ""));
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setSifreHatirlatmaHataMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setLoading(false));
  };

  const sifreHatirlatKurum = (event: any) => {
    event.preventDefault();
    if (!kisi.tcKimlikNo) {
      setSifreHatirlatmaHataMessage("Lütfen Vergi No Bilgisini Giriniz!");
      return;
    }
    if (kisi.tcKimlikNo.length !== 10) {
      setSifreHatirlatmaHataMessage("Lütfen Vergi No Bilgisini Doğru Giriniz!");
      return;
    }
    if (!kisi.eposta) {
      setSifreHatirlatmaHataMessage("Lütfen Geçerli bir E-Posta Giriniz!");
      return;
    }
    setLoading(true);
    userService
      .sifreHatirlatKurum(kisi.tcKimlikNo, kisi.eposta)
      .then((msg) => {
        setInfoModalMessage(msg);
        setShowInfoModal(true);
        setKisi(new Kisi("", "", "", "", "", "", ""));
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setSifreHatirlatmaHataMessage(
          error.response ? error.response.data.message : error
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <div className="container">
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonCard className="login-card">
            <IonCardHeader color="primary">
              <IonCardTitle>Kullanıcı Girişi</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              {message && (
                <IonItem>
                  <IonText color="danger">
                    <strong>{message}</strong>
                  </IonText>
                </IonItem>
              )}
              <IonItem>
                <IonLabel position="floating">
                  Kullanıcı Adı (TC No / Vergi No)
                </IonLabel>
                <IonInput
                  name="usernameInput"
                  value={username}
                  onIonChange={(e) => setUsername(e.detail.value!)}
                  clearInput
                />
                {usernameMessage && (
                  <IonText color="danger">{usernameMessage}</IonText>
                )}
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Şifre</IonLabel>
                <IonInput
                  name="passwordInput"
                  value={password}
                  type="password"
                  onIonChange={(e) => setPassword(e.detail.value!)}
                  clearInput
                />
                {passwordMessage && (
                  <IonText color="danger">{passwordMessage}</IonText>
                )}
                <IonButton
                  title="Şifremi Unuttum"
                  slot="end"
                  fill="outline"
                  size="default"
                  className="ion-padding"
                  onClick={() => setShowForgotPasswordModal(true)}
                >
                  <IonIcon slot="icon-only" icon={help} />
                </IonButton>
              </IonItem>

              {kurumBilgileri?.eDevletClientId && (
                <IonButton
                  slot="start"
                  type="button"
                  color="danger"
                  href={EDEVLET_AUTHORIZATION_CONTROLLER.replace(
                    /\${eDevletAuthorizationController}/g,
                    kurumBilgileri!.eDevletAuthorizationController
                  )
                    .replace(/\${clientId}/g, kurumBilgileri!.eDevletClientId)
                    .replace(
                      /\${redirectUri}/g,
                      encodeURIComponent(EDEVLET_LOGIN)
                    )
                    .replace(/\${state}/g, sessionStorage.getItem("rnd_val")!)
                    .replace(/\${codeChallenge}/g, kurumBilgileri!.kurumId)}
                  style={{ textTransform: "none" }}
                >
                  <IonIcon slot="start" src="assets/edevlet.svg" /> e-Devlet ile
                  Giriş
                </IonButton>
              )}
              <IonButton slot="end" type="button" onClick={() => handleLogin()}>
                Giriş
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonModal
            isOpen={showForgotPasswordModal}
            onDidDismiss={() => setShowForgotPasswordModal(false)}
          >
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Şifre Hatırlatma Bilgileri Girişi</IonCardTitle>
                {sifreHatirlatmaHataMessage && (
                  <IonCardSubtitle color="danger">
                    {sifreHatirlatmaHataMessage}
                  </IonCardSubtitle>
                )}
              </IonCardHeader>
              <TabView>
                <TabPanel header="Kişi Bilgileri">
                  <form onSubmit={(e) => sifreHatirlatKisi(e)}>
                    <IonGrid>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>T.C. Kimlik No</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">
                              T.C. Kimlik No
                            </IonLabel>
                            <IonInput
                              name="tcNoInput"
                              value={kisi.tcKimlikNo}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  tcKimlikNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="tcNoInput"
                              value={kisi.tcKimlikNo}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  tcKimlikNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Cüzdan Seri</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Cüzdan Seri</IonLabel>
                            <IonInput
                              name="cuzdanSeriInput"
                              value={kisi.cuzdanSeri}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  cuzdanSeri: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="cuzdanSeriInput"
                              value={kisi.cuzdanSeri}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  cuzdanSeri: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Cüzdan No</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Cüzdan No</IonLabel>
                            <IonInput
                              name="cuzdanNoInput"
                              value={kisi.cuzdanNo}
                              onIonChange={(e) =>
                                setKisi({ ...kisi, cuzdanNo: e.detail.value! })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="cuzdanNoInput"
                              value={kisi.cuzdanNo}
                              onIonChange={(e) =>
                                setKisi({ ...kisi, cuzdanNo: e.detail.value! })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>
                              Cüzdan Seri No (T.C. Kimlik Kartı)
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">
                              Cüzdan Seri No (T.C. Kimlik Kartı)
                            </IonLabel>
                            <IonInput
                              name="cuzdanSeriNoInput"
                              value={kisi.cuzdanSeriNo}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  cuzdanSeriNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="cuzdanSeriNoInput"
                              value={kisi.cuzdanSeriNo}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  cuzdanSeriNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonLabel>Şifrenin Gönderileceği Yer</IonLabel>
                        </IonCol>
                        <IonCol>
                          <IonRadioGroup
                            value={selectedMethod}
                            onIonChange={(e) =>
                              setSelectedMethod(e.detail.value)
                            }
                          >
                            <IonGrid>
                              <IonRow className="ion-nowrap">
                                <IonCol>
                                  <IonItem>
                                    <IonRadio slot="start" value="eposta" />
                                    <IonLabel>E-Posta</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol>
                                  <IonItem>
                                    <IonRadio slot="start" value="cep" />
                                    <IonLabel>Cep Telefonu</IonLabel>
                                  </IonItem>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonRadioGroup>
                        </IonCol>
                      </IonRow>
                      {selectedMethod === "eposta" && (
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>E-Posta</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel position="floating">E-Posta</IonLabel>
                              <IonInput
                                name="epostaInput"
                                value={kisi.eposta}
                                onIonChange={(e) =>
                                  setKisi({ ...kisi, eposta: e.detail.value! })
                                }
                                clearInput
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <IonInput
                                name="epostaInput"
                                value={kisi.eposta}
                                onIonChange={(e) =>
                                  setKisi({ ...kisi, eposta: e.detail.value! })
                                }
                                clearInput
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      )}
                      {selectedMethod === "cep" && (
                        <IonRow>
                          <IonCol className="ion-hide-sm-down">
                            <IonItem className="ion-text-end">
                              <IonLabel>Cep Telefonu</IonLabel>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem className="ion-hide-sm-up">
                              <IonLabel
                                position="stacked"
                                className="ion-padding-bottom"
                              >
                                Cep Telefonu
                              </IonLabel>
                              <InputMask
                                id="phone2"
                                mask="(999)-999 99 99"
                                unmask={true}
                                placeholder="(599)-999 99 99"
                                value={kisi.cepTel}
                                size={15}
                                onChange={(e) =>
                                  setKisi({ ...kisi, cepTel: e.value })
                                }
                              />
                            </IonItem>
                            <IonItem className="ion-hide-sm-down">
                              <InputMask
                                id="phone3"
                                mask="(999)-999 99 99"
                                unmask={true}
                                placeholder="(599)-999 99 99"
                                value={kisi.cepTel}
                                size={15}
                                onChange={(e) =>
                                  setKisi({ ...kisi, cepTel: e.value })
                                }
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      )}
                      <IonRow>
                        <IonCol className="ion-text-end">
                          <IonButton type="submit">Gönder</IonButton>
                        </IonCol>
                        <IonCol className="ion-text-start">
                          <IonButton
                            type="button"
                            fill="outline"
                            onClick={() => setShowForgotPasswordModal(false)}
                          >
                            İptal
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                </TabPanel>
                <TabPanel header="Kurum Bilgileri">
                  <form onSubmit={(e) => sifreHatirlatKurum(e)}>
                    <IonGrid>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>Vergi No</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">Vergi No</IonLabel>
                            <IonInput
                              name="tcNoInput"
                              value={kisi.tcKimlikNo}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  tcKimlikNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="tcNoInput"
                              value={kisi.tcKimlikNo}
                              onIonChange={(e) =>
                                setKisi({
                                  ...kisi,
                                  tcKimlikNo: e.detail.value!,
                                })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-hide-sm-down">
                          <IonItem className="ion-text-end">
                            <IonLabel>E-Posta</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem className="ion-hide-sm-up">
                            <IonLabel position="floating">E-Posta</IonLabel>
                            <IonInput
                              name="epostaInput"
                              value={kisi.eposta}
                              onIonChange={(e) =>
                                setKisi({ ...kisi, eposta: e.detail.value! })
                              }
                              clearInput
                            />
                          </IonItem>
                          <IonItem className="ion-hide-sm-down">
                            <IonInput
                              name="epostaInput"
                              value={kisi.eposta}
                              onIonChange={(e) =>
                                setKisi({ ...kisi, eposta: e.detail.value! })
                              }
                              clearInput
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-end">
                          <IonButton type="submit">Gönder</IonButton>
                        </IonCol>
                        <IonCol className="ion-text-start">
                          <IonButton
                            type="button"
                            fill="outline"
                            onClick={() => setShowForgotPasswordModal(false)}
                          >
                            İptal
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </form>
                </TabPanel>
              </TabView>
            </IonCard>
          </IonModal>
          <IonModal
            isOpen={showInfoModal}
            onDidDismiss={() => setShowInfoModal(false)}
          >
            <IonList>
              <IonListHeader>
                <IonTitle>Sonuç</IonTitle>
              </IonListHeader>
              <IonItem>{infoModalMessage}</IonItem>
              <IonItemDivider />
              <IonItem>
                <IonButton
                  size="default"
                  slot="end"
                  onClick={() => {
                    setShowInfoModal(false);
                    setShowForgotPasswordModal(false);
                  }}
                >
                  Tamam
                </IonButton>
              </IonItem>
            </IonList>
          </IonModal>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
