import { IonContent, IonPage } from "@ionic/react";

import React from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";

const GuvenlikUyarilari: React.FC = () => {
  const { kurumBilgileri, setTitle } = React.useContext(AuthContext);
  React.useEffect(() => {
    setTitle(kurumBilgileri?.kisaTanim!);
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen className="ion-padding">
        <h3>GÜVENLİK UYARILARI</h3>

        <div style={{ textAlign: "justify" }}>
          <span style={{ textAlign: "justify" }}>
            <p>
              &#160;&#160;&#160;&#160; {kurumBilgileri?.kisaTanim}{" "}
              {kurumBilgileri?.kurumKodu?.startsWith("35")
                ? "Online İşlemler"
                : kurumBilgileri?.kurumKodu?.startsWith("48")
                ? "E-Birlik Hizmetleri"
                : "E-Belediye Hizmetleri"}{" "}
              sayfası vasıtasıyla gerçekleştirdiğiniz tüm işlemler şifreli olup
              dünyaca kabul görmüş güvenlik sertifaları kullanılmaktadır. Kredi
              kartı ile yapacağınız ödemelerde bankaların güvenli ödeme
              noktaları kullanılmakta olup, kredi kartı ile ilgili herhangi bir
              bilgi sistemimize kayıt <u>edilmemektedir.</u>
            </p>
            <p>
              &#160;&#160;&#160;&#160; Kişisel bilgilerinizin korunması ve
              gerekli güvenlik önlemlerinin alınması bakımından siz değerli
              mükelleflerimize birkaç önemli hatırlatmada bulunmak istiyoruz.
            </p>
            <ul>
              <li>
                Güvenlik açısından TC Kimlik No, sicil numarası gibi özel
                bilgilerinizle şifrenizi üçüncü şahıslarla paylaşmayınız.{" "}
              </li>
              <li>
                Şifrenizi belirlerken herkesin kolayca tahmin edebileceği isim,
                yaşgünü, telefon numarasi vb. kullanmayınız. Şifrenizi güvenli
                bir yerde saklayınız ve bilgisayarınıza kayıt etmeyiniz.
              </li>
              <li>
                Yönlendirildiğiniz sayfanın https:// ile başladığından emin
                olunuz.{" "}
                {kurumBilgileri?.kurumKodu?.startsWith("35")
                  ? "Online İşlemler"
                  : kurumBilgileri?.kurumKodu?.startsWith("48")
                  ? "E-Birlik Hizmetleri"
                  : "E-Belediye Hizmetleri"}{" "}
                sayfası 128 bit SSL şifreleme kullanmaktadır. Açılan ödeme
                penceresinde kullandığınız tarayıcıya göre sağ alt ya da sağ
                üstte bulunan kilit işaretine tıkladığınızda açılan sertifikanın
                geçerli olup olmadığını kontrol ediniz.
              </li>
              <li>
                İnternet kafe gibi halka açık yerlerden işlem yapıyorsanız
                dikkatli olunuz. ( Zorunlu olmadıkça bu tür yerlerden işlem
                yapmayınız! )
              </li>
              <li>
                Bilgisayarınızda mutlaka orijinal anti-virüs yazılımı
                kullanınız. Sisteminizi düzenli aralıklarla anti-virüs yazılımı
                ile tarayınız ve anti-virüs yazılımınızı düzenli olarak
                güncelleyiniz. İnternetten indirdiğiniz her türlü dosyayı
                mutlaka anti-virüs yazılımıyla kontrol ediniz. Ancak, anti-virüs
                yazılımlarının, sadece bilinen virüsleri tarayabilecegini
                unutmayınız.
              </li>
              {!kurumBilgileri?.kurumKodu?.startsWith("35") && (
                <li>
                  Üye Girişi yaptıktan sonra işlemleriniz bittiğinde "Güvenli
                  Çıkış" butonunu ile oturumunuzu sonlandırıp daha sonra
                  tarayıcınızı kapatınız.
                </li>
              )}
            </ul>
          </span>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(GuvenlikUyarilari);
