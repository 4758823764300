import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonPopover,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import React, { useRef, useState } from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import sorgulamaService from "../../services/SorgulamaService";
import userService from "../../services/UserService";
import IstekSikayet from "../entities/IstekSikayet";
import IstekSikayetDetay from "../entities/IstekSikayetDetay";
import "./Page.css";

const IstekSikayetSorgu: React.FC = () => {
  const { authInfo, setTitle } = React.useContext(AuthContext);
  const [sonuc, setSonuc] = useState<IstekSikayet[]>([]);
  const [expandedRows, setExpandedRows] = useState<IstekSikayetDetay[]>([]);
  const dt = useRef<any>(null);
  const loadingData = [
    {
      basvuruTarihi: "",
      istekSikayetNo: "",
      tcNo: "",
      adiSoyadi: "",
      basvuruNedeni: "",
      konuBasligi: "",
      sonucZamani: "",
    },
    {
      basvuruTarihi: "",
      istekSikayetNo: "",
      tcNo: "",
      adiSoyadi: "",
      basvuruNedeni: "",
      konuBasligi: "",
      sonucZamani: "",
    },
    {
      basvuruTarihi: "",
      istekSikayetNo: "",
      tcNo: "",
      adiSoyadi: "",
      basvuruNedeni: "",
      konuBasligi: "",
      sonucZamani: "",
    },
  ];
  const loadingBodyTemplate = () => {
    return <IonSkeletonText animated />;
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [detailModel, setDetailModel] = useState<IstekSikayetDetay[]>([]);
  const [toastMessage, setToastMessage] = useState<string>();
  const [takipNo, setTakipNo] = useState<string>();
  const [takipNoMessage, setTakipNoMessage] = useState<string>();
  const [eposta, setEposta] = useState<string>();
  const [epostaMessage, setEpostaMessage] = useState<string>();

  React.useEffect(() => {
    setTitle("İstek / Şikayet Sorgulama");
  }, []);

  const getIstekSikayet = (event: any) => {
    event.preventDefault();
    let errorFound = false;
    setTakipNoMessage(undefined);
    setEpostaMessage(undefined);
    if (!authInfo.loggedIn) {
      if (!takipNo) {
        setTakipNoMessage("* Takip Numarası	alanını doldurunuz");
        errorFound = true;
      }
      if (!eposta) {
        setEpostaMessage("* E-Posta Adresi alanını doldurunuz");
        errorFound = true;
      }
      const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!regexp.test(eposta!)) {
        setEpostaMessage("* Geçerli bir E-Posta Adresi giriniz");
        errorFound = true;
      }
      if (errorFound) {
        return;
      }
    }

    setLoading(true);
    let svc = null;
    if (authInfo.loggedIn) {
      svc = userService.getIstekSikayetler(takipNo);
    } else {
      svc = sorgulamaService.getIstekSikayetler(takipNo!, eposta!);
    }
    svc
      .then((data: IstekSikayet[]) => {
        console.log(data.length);
        if (data.length > 0) {
          setSonuc(data);
        } else {
          setToastMessage("Girilen kriterlere göre kayıt bulunamadı.");
        }
      })
      .catch((err) => {
        console.log("error on setSonuc on getIstekSikayetler: ", err);
        setToastMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const collapseAll = () => {
    setExpandedRows([]);
  };

  const basvuruZamaniBodyTemplate = (rowData: IstekSikayet) => {
    return (
      <React.Fragment>
        {new Date(rowData.basvuruTarihi).toLocaleString()}
      </React.Fragment>
    );
  };

  const sonuclanmaZamaniBodyTemplate = (rowData: IstekSikayet) => {
    return (
      <React.Fragment>
        {!rowData.sonucTarihi
          ? "İşleminiz sürüyor."
          : rowData.sonucTarihi + " " + rowData.sonucZamani}
      </React.Fragment>
    );
  };

  const islemZamaniBodyTemplate = (rowData: IstekSikayetDetay) => {
    return (
      <React.Fragment>
        {new Date(rowData.islemTarihi).toLocaleDateString() +
          " " +
          rowData.islemZamani}
      </React.Fragment>
    );
  };

  const rowExpansionTemplate = (data: IstekSikayet) => {
    return (
      <div className="isteksikayet-subtable">
        <DataTable value={data.istekSikayetDetay} sortField="islemZamani">
          <Column
            field="islemZamani"
            header="İşlem Zamanı"
            body={islemZamaniBodyTemplate}
            sortable
          ></Column>
          <Column
            field="gonderilenBirim"
            header="Gönderilen Birim"
            sortable
          ></Column>
          <Column
            field="gonderilenKisi"
            header="Gönderilen Kişi"
            sortable
          ></Column>
          <Column field="aciklama" header="Açıklama" sortable></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonContent>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonToast
            isOpen={toastMessage !== undefined}
            onDidDismiss={() => setToastMessage(undefined)}
            message={toastMessage}
            duration={2000}
            position="top"
          />
          <form
            onSubmit={(e) => {
              getIstekSikayet(e);
            }}
          >
            <IonGrid>
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>Takip Numarası</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">Takip Numarası</IonLabel>
                    <IonInput
                      name="takipNoInput"
                      value={takipNo}
                      onIonChange={(e) => setTakipNo(e.detail.value!)}
                      clearInput
                    />
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonInput
                      name="takipNoInput"
                      value={takipNo}
                      placeholder="Takip Numarası..."
                      onIonChange={(e) => setTakipNo(e.detail.value!)}
                      clearInput
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              {takipNoMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {takipNoMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              {!authInfo.loggedIn && (
                <IonRow>
                  <IonCol
                    size="4"
                    offset="0"
                    sizeSm="3"
                    offsetSm="1"
                    className="ion-hide-sm-down"
                  >
                    <IonItem className="ion-text-end">
                      <IonLabel>E-Posta Adresi</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                    <IonItem className="ion-hide-sm-up">
                      <IonLabel position="floating">E-Posta Adresi</IonLabel>
                      <IonInput
                        name="icerikInput"
                        value={eposta}
                        onIonChange={(e) => setEposta(e.detail.value!)}
                        clearInput
                      />
                    </IonItem>
                    <IonItem className="ion-hide-sm-down">
                      <IonInput
                        name="icerikInput"
                        value={eposta}
                        placeholder="Eposta..."
                        onIonChange={(e) => setEposta(e.detail.value!)}
                        clearInput
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}
              {epostaMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {epostaMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <IonButton type="submit">Sorgula</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="ion-hide-sm-up">
              {loading && (
                <IonCard>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="5" className="p-column-title">
                        Başvuru Tarihi / No
                      </IonCol>
                      <IonCol className="p-column-content">
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="5" className="p-column-title">
                        TC No
                      </IonCol>
                      <IonCol className="p-column-content">
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="5" className="p-column-title">
                        Adı Soyadı
                      </IonCol>
                      <IonCol className="p-column-content">
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="5" className="p-column-title">
                        Başvuru Nedeni
                      </IonCol>
                      <IonCol className="p-column-content">
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="5" className="p-column-title">
                        Konu Baslığı
                      </IonCol>
                      <IonCol className="p-column-content">
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="5" className="p-column-title">
                        Sonuçlanma Zamanı
                      </IonCol>
                      <IonCol className="p-column-content">
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              )}
              {!loading &&
                sonuc.map((rowData, i) => (
                  <IonCard key={i}>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="5" className="p-column-title">
                          Başvuru Tarihi / No
                        </IonCol>
                        <IonCol className="p-column-content">
                          {new Date(rowData.basvuruTarihi).toLocaleString()} -{" "}
                          {rowData.istekSikayetNo}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5" className="p-column-title">
                          TC No
                        </IonCol>
                        <IonCol className="p-column-content">
                          {rowData.tcNo}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5" className="p-column-title">
                          Adı Soyadı
                        </IonCol>
                        <IonCol className="p-column-content">
                          {rowData.adiSoyadi}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5" className="p-column-title">
                          Başvuru Nedeni
                        </IonCol>
                        <IonCol className="p-column-content">
                          {rowData.basvuruNedeni}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5" className="p-column-title">
                          Konu Baslığı
                        </IonCol>
                        <IonCol className="p-column-content">
                          {rowData.konuBasligi}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="5" className="p-column-title">
                          Sonuçlanma Zamanı
                        </IonCol>
                        <IonCol className="p-column-content">
                          {!rowData.sonucTarihi
                            ? "İşleminiz sürüyor."
                            : rowData.sonucTarihi + " " + rowData.sonucZamani}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonButton
                            color="success"
                            size="small"
                            onClick={() =>
                              setDetailModel(rowData.istekSikayetDetay)
                            }
                          >
                            Detaylar
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                ))}
              <IonPopover
                isOpen={detailModel.length > 0}
                onDidDismiss={() => setDetailModel([])}
              >
                <IonToolbar>
                  <IonTitle>Detaylar</IonTitle>
                </IonToolbar>
                {detailModel.map((rowData, i) => (
                  <IonCard key={i}>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="4">İşlem Zamanı</IonCol>
                        <IonCol>
                          {new Date(rowData.islemTarihi).toLocaleDateString() +
                            " " +
                            rowData.islemZamani}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="4">Gönderilen Birim</IonCol>
                        <IonCol>{rowData.gonderilenBirim}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="4">Gönderilen Kişi</IonCol>
                        <IonCol>{rowData.gonderilenKisi}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="4">Açıklama</IonCol>
                        <IonCol>{rowData.aciklama}</IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                ))}
                <IonFooter className="ion-text-center">
                  <IonButton onClick={() => setDetailModel([])}>
                    Tamam
                  </IonButton>
                </IonFooter>
              </IonPopover>
            </div>
            <div className="ion-hide-sm-down">
              <IonCard>
                <IonCardContent className="datatable-responsive">
                  {loading && (
                    <DataTable
                      value={loadingData}
                      paginator
                      rows={10}
                      removableSort
                      stripedRows
                      size="small"
                      responsiveLayout="stack"
                      breakpoint="960px"
                    >
                      <Column
                        field="basvuruTarihi"
                        header="Başvuru Tarihi"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="istekSikayetNo"
                        header="Başvuru No"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="tcNo"
                        header="TC No"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="adiSoyadi"
                        header="Adı Soyadı"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="basvuruNedeni"
                        header="Başvuru Nedeni"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="konuBasligi"
                        header="Konu Baslığı"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="sonucZamani"
                        header="Sonuçlanma Zamanı"
                        body={loadingBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                    </DataTable>
                  )}
                  {!loading && (
                    <DataTable
                      value={sonuc}
                      paginator
                      rows={10}
                      removableSort
                      ref={dt}
                      expandedRows={expandedRows}
                      onRowToggle={(e) =>
                        setExpandedRows(e.data as IstekSikayetDetay[])
                      }
                      rowExpansionTemplate={rowExpansionTemplate}
                      dataKey="id"
                      emptyMessage=""
                      stripedRows
                      size="small"
                      responsiveLayout="stack"
                      breakpoint="960px"
                    >
                      <Column expander style={{ width: "2em" }} />
                      <Column
                        field="basvuruTarihi"
                        header="Başvuru Tarihi"
                        sortable
                        headerClassName="ion-text-center"
                        body={basvuruZamaniBodyTemplate}
                      ></Column>
                      <Column
                        field="istekSikayetNo"
                        header="Başvuru No"
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="tcNo"
                        header="TC No"
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="adiSoyadi"
                        header="Adı Soyadı"
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="basvuruNedeni"
                        header="Başvuru Nedeni"
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="konuBasligi"
                        header="Konu Baslığı"
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                      <Column
                        field="sonucZamani"
                        header="Sonuçlanma Zamanı"
                        body={sonuclanmaZamaniBodyTemplate}
                        sortable
                        headerClassName="ion-text-center"
                      ></Column>
                    </DataTable>
                  )}
                </IonCardContent>
              </IonCard>
            </div>
          </form>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default IstekSikayetSorgu;
