import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonToast,
} from "@ionic/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { downloadOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import Header from "../../components/Header";
import { AuthContext } from "../../providers/authContext";
import raporService from "../../services/RaporService";
import sorgulamaService from "../../services/SorgulamaService";
import BinaAsinmaPaylari from "../entities/BinaAsinmaPaylari";
import BinaInsaatTur from "../entities/BinaInsaatTur";
import "./Page.css";

const BinaAsinmaSorgu: React.FC = () => {
  const { setTitle } = React.useContext(AuthContext);
  const [sonuc, setSonuc] = useState<BinaAsinmaPaylari[]>([]);
  const dt = useRef<any>(null);
  const loadingData = [
    { tur: "", binaYasAraligi: "", oran: 0 },
    { tur: "", binaYasAraligi: "", oran: 0 },
    { tur: "", binaYasAraligi: "", oran: 0 },
    { tur: "", binaYasAraligi: "", oran: 0 },
    { tur: "", binaYasAraligi: "", oran: 0 },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [insaatTurleri, setInsaatTurleri] = useState<BinaInsaatTur[]>();
  const [insaatTuruId, setInsaatTuru] = useState<string>();
  const [insaatTuruMessage, setInsaatTuruMessage] = useState<string>();
  const [toastMessage, setToastMessage] = useState<string>();

  React.useEffect(() => {
    setTitle("Bina Aşınma Oranları Sorgulama");
    setShowLoading(true);
    sorgulamaService
      .getBinaInsaatTurleri()
      .then((data: BinaInsaatTur[]) => setInsaatTurleri(data))
      .catch((err) => {
        console.log("error on setInsaatTurleri on getBinaInsaatTurleri: ", err);
        setToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  }, []);

  const getBinaAsinmaPaylari = (event: any) => {
    event.preventDefault();
    let errorFound = false;
    setInsaatTuruMessage(undefined);
    if (!insaatTuruId) {
      setInsaatTuruMessage("* İnşaat Türü alanını doldurunuz");
      errorFound = true;
    }
    if (errorFound) {
      return;
    }

    setLoading(true);
    sorgulamaService
      .getBinaAsinmaPaylari(insaatTuruId!)
      .then((data: BinaAsinmaPaylari[]) => setSonuc(data))
      .finally(() => setLoading(false));
  };

  const formatCurrency = (value: number) => {
    if (!value) {
      value = 0;
    }
    return value.toFixed(2);
  };

  const loadingBodyTemplate = () => {
    return <IonSkeletonText animated />;
  };
  const turBodyTemplate = (rowData: any) => {
    return <IonText>{rowData.tur}</IonText>;
  };

  const yasAraligiBodyTemplate = (rowData: any) => {
    return <IonText>{rowData.binaYasAraligi}</IonText>;
  };

  const degerBodyTemplate = (rowData: any) => {
    return <IonText>{formatCurrency(rowData.oran)}</IonText>;
  };

  const exportPDF = () => {
    setShowLoading(true);
    raporService
      .binaAsinmaYazdir(insaatTuruId!)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "binaasinma.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        setToastMessage(err);
      })
      .finally(() => setShowLoading(false));
  };

  const footer = (
    <div
      style={{ textAlign: "end", display: sonuc.length > 0 ? "block" : "none" }}
    >
      <IonButton type="button" color="success" onClick={exportPDF}>
        <IonIcon slot="start" icon={downloadOutline} />
        Yazdır
      </IonButton>
    </div>
  );

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonContent>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Lütfen Bekleyin..."}
          />
          <IonToast
            isOpen={toastMessage !== undefined}
            onDidDismiss={() => setToastMessage(undefined)}
            message={toastMessage}
            duration={2000}
            position="top"
          />
          <form
            onSubmit={(e) => {
              getBinaAsinmaPaylari(e);
            }}
          >
            <IonGrid>
              <IonRow>
                <IonCol
                  size="4"
                  offset="0"
                  sizeSm="3"
                  offsetSm="1"
                  className="ion-hide-sm-down"
                >
                  <IonItem className="ion-text-end">
                    <IonLabel>İnşaat Türü</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="10" sizeSm="4" offset="1" offsetSm="0">
                  <IonItem className="ion-hide-sm-up">
                    <IonLabel position="floating">İnşaat Türü</IonLabel>
                    <IonSelect
                      name="insaatTuruIdInput"
                      interface="popover"
                      value={insaatTuruId}
                      onIonChange={(e) => setInsaatTuru(e.detail.value)}
                    >
                      {insaatTurleri?.map((val) => (
                        <IonSelectOption key={val.id} value={val.id}>
                          {val.tanim}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem className="ion-hide-sm-down">
                    <IonSelect
                      name="insaatTuruIdInput"
                      interface="popover"
                      value={insaatTuruId}
                      placeholder="Seçiniz"
                      onIonChange={(e) => setInsaatTuru(e.detail.value)}
                    >
                      {insaatTurleri?.map((val) => (
                        <IonSelectOption key={val.id} value={val.id}>
                          {val.tanim}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              {insaatTuruMessage && (
                <IonRow>
                  <IonCol size="8" offset="4">
                    <IonText color="danger ion-padding-start">
                      {insaatTuruMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol style={{ textAlign: "center" }}>
                  <IonButton type="submit">Sorgula</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonCard>
              <IonCardContent className="datatable-responsive">
                {loading && (
                  <DataTable
                    value={loadingData}
                    paginator
                    rows={10}
                    removableSort
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="tur"
                      header="İnşaat Türü"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="binaYasAraligi"
                      header="Bina Yaş Aralığı"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                    ></Column>
                    <Column
                      field="oran"
                      header="Aşınma Oranı (%)"
                      body={loadingBodyTemplate}
                      sortable
                      headerClassName="ion-text-center"
                      bodyClassName="p-column-content-currency"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                )}
                {!loading && (
                  <DataTable
                    value={sonuc}
                    paginator
                    rows={10}
                    removableSort
                    footer={footer}
                    ref={dt}
                    stripedRows
                    size="small"
                    responsiveLayout="stack"
                    breakpoint="960px"
                  >
                    <Column
                      field="tur"
                      header="İnşaat Türü"
                      sortable
                      headerClassName="ion-text-center"
                      body={turBodyTemplate}
                    ></Column>
                    <Column
                      field="binaYasAraligi"
                      header="Bina Yaş Aralığı"
                      sortable
                      headerClassName="ion-text-center"
                      body={yasAraligiBodyTemplate}
                    ></Column>
                    <Column
                      field="oran"
                      header="Aşınma Oranı (%)"
                      sortable
                      body={degerBodyTemplate}
                      headerClassName="ion-text-center"
                      bodyClassName="p-column-content-currency"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                )}
              </IonCardContent>
            </IonCard>
          </form>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default BinaAsinmaSorgu;
