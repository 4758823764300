import { IonIcon } from "@ionic/react";
import {
  albumsOutline,
  albumsSharp,
  analyticsOutline,
  analyticsSharp,
  businessOutline,
  businessSharp,
  cardOutline,
  cardSharp,
  cashOutline,
  documentLockOutline,
  exitOutline,
  exitSharp,
  homeOutline,
  homeSharp,
  informationCircleOutline,
  informationCircleSharp,
  keyOutline,
  keySharp,
  personAddOutline,
  personAddSharp,
  personOutline,
  personSharp,
  readerOutline,
  readerSharp,
  receiptOutline,
  receiptSharp,
  searchCircleOutline,
  searchCircleSharp,
  squareOutline,
  squareSharp,
  thumbsUpOutline,
  thumbsUpSharp,
  trashOutline,
  trashSharp,
  walletOutline,
  walletSharp,
  waterOutline,
  waterSharp,
} from "ionicons/icons";
import React from "react";

interface IconProps {
  ios: any;
  md: string;
  className: string;
  slot: string;
}
const Icon: React.FC<IconProps> = (props: IconProps) => {
  const ios = props.ios;
  const md = props.md;
  const className = props.className;
  const slot = props.slot;

  return (
    <IonIcon
      className={className}
      slot={slot}
      ios={
        ios === "personOutline"
          ? personOutline
          : ios === "cardOutline"
          ? cardOutline
          : ios === "businessOutline"
          ? businessOutline
          : ios === "waterOutline"
          ? waterOutline
          : ios === "squareOutline"
          ? squareOutline
          : ios === "analyticsOutline"
          ? analyticsOutline
          : ios === "cashOutline"
          ? cashOutline
          : ios === "trashOutline"
          ? trashOutline
          : ios === "albumsSharp"
          ? albumsSharp
          : ios === "documentLockOutline"
          ? documentLockOutline
          : ios === "thumbsUpOutline"
          ? thumbsUpOutline
          : ios === "searchCircleOutline"
          ? searchCircleOutline
          : ios === "personAddOutline"
          ? personAddOutline
          : ios === "homeOutline"
          ? homeOutline
          : ios === "readerOutline"
          ? readerOutline
          : ios === "walletOutline"
          ? walletOutline
          : ios === "receiptOutline"
          ? receiptOutline
          : ios === "informationCircleOutline"
          ? informationCircleOutline
          : ios === "keyOutline"
          ? keyOutline
          : ios === "exitOutline"
          ? exitOutline
          : undefined
      }
      md={
        md === "personSharp"
          ? personSharp
          : md === "cardSharp"
          ? cardSharp
          : md === "businessSharp"
          ? businessSharp
          : md === "waterSharp"
          ? waterSharp
          : md === "squareSharp"
          ? squareSharp
          : md === "analyticsSharp"
          ? analyticsSharp
          : md === "cashOutline"
          ? cashOutline
          : md === "trashSharp"
          ? trashSharp
          : md === "albumsOutline"
          ? albumsOutline
          : md === "documentLockOutline"
          ? documentLockOutline
          : md === "thumbsUpSharp"
          ? thumbsUpSharp
          : md === "searchCircleSharp"
          ? searchCircleSharp
          : md === "personAddSharp"
          ? personAddSharp
          : md === "homeSharp"
          ? homeSharp
          : md === "readerSharp"
          ? readerSharp
          : md === "walletSharp"
          ? walletSharp
          : md === "receiptSharp"
          ? receiptSharp
          : md === "informationCircleSharp"
          ? informationCircleSharp
          : md === "keySharp"
          ? keySharp
          : md === "exitSharp"
          ? exitSharp
          : undefined
      }
    ></IonIcon>
  );
};

export default Icon;
